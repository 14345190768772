import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { MHidden } from "./@material-extend";

function Footer() {
  return (
    <MHidden width="mdDown">
      <Box
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          bottom: "10px",
          right: "5px",
        }}
      >
        <Typography
          variant="body2"
          align="center"
          sx={{
            textAlign: "right",
            color: "common.grey",
            fontWeight: "bold",
            lineHeight: "1px",
          }}
        >
          powered by
        </Typography>
        <Link
          underline="none"
          sx={{
            color: "primary.light",
            fontSize: "2rem",
            padding: "0.5rem",
            letterSpacing: "1px",
            fontWeight: "bold",
          }}
        >
          CHANZO
        </Link>
      </Box>
    </MHidden>
  );
}
export default Footer;
