import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, getUA, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH, logPusher } from "../utils/apis";
import { setSession, setHeader } from "../utils/jwt";
import { fTimeZone } from "../utils/formatTime";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isDomainOkay: false,
  isDemoDomain: false,
  isVerifyEmail: false,
  user: null,
  usertype: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isDomainOkay, user, isDemoDomain } =
      action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isAuthenticated,
      isVerifyEmail: false,
      isInitialized: true,
      isDomainOkay,
      isDemoDomain,
      user,
      usertype,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      user,
      usertype,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      user,
      usertype,
    };
  },
  LOGOUT: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      user,
      usertype,
    };
  },
  SETPASSWORD: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isAuthenticated: false,
      isDomainOkay: true,
      isVerifyEmail: true,
      user,
      usertype,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      isDomainOkay: true,
      user,
      usertype,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      setHeader();
      let isAuthenticated = false;
      let isDomainOkay = false;
      let isDemoDomain = false;
      let user = {};
      try {
        const subdomain = window.location.host.split(".")[1]
          ? window.location.host.split(".")[0]
          : false;
        if (subdomain) {
          const response = await axios.post(API_PATH.profile, {
            get: "",
            domain: subdomain,
          });
          const { status, data } = response.data;
          user = data;
          if (status === "success" && user.school.domain === subdomain) {
            isDomainOkay = true;
            isAuthenticated = Boolean(user.user.uid);
          }
          if (
            status === "userid_expired" ||
            status === "userid_error" ||
            status === "invalid_profile"
          ) {
            isDomainOkay = true;
            isAuthenticated = false;
            setSession(null);
          }
        }
        // check if is demo domain
        isDemoDomain = user.school.domain === "demo";
      } catch (err) {
        // console.error(err);
      }
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated,
          isDomainOkay,
          user,
          isDemoDomain,
        },
      });
    };
    initialize();
  }, []);

  const getProfile = async () => {
    let isAuthenticated = false;
    let isDomainOkay = true;
    let user = {};
    try {
      const response = await axios.post(API_PATH.profile, { get: "" });
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        isDomainOkay = true;
        isAuthenticated = Boolean(user.user.uid);
      }
      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isDomainOkay = true;
        isAuthenticated = false;
        setSession(null);
      }
    } catch (err) {
      // console.error(err);
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isDomainOkay,
        user,
      },
    });
  };

  const logout = async () => {
    const { school } = state.user;
    const user = { school };
    logDevice(state.user.user.uid, 0);
    setSession(null);
    dispatch({ type: "LOGOUT", payload: { user } });
  };
  const logDevice = async (userId, status) => {
    axios.post(API_PATH.login, {
      name: browserName,
      os: osName,
      deviceId: getUA,
      status,
      type: 0,
    });
    logPusher(userId, status);
  };
  const login = async (email, password, type) => {
    const { school } = state.user;
    const response = await axios.post(API_PATH.login, {
      email,
      password,
      type,
      domain: school.domain,
      timezone: fTimeZone(),
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token, data.user.usertype);
      logDevice(data.user.uid, 1);
      dispatch({ type: "LOGIN", payload: { user: data } });
    }
    if (status === "invalid_login") {
      throw new Error(
        "Your email address or password is incorrect! Please confirm and try again."
      );
    }
    if (status === "invalid_school") {
      dispatch({ type: "LOGIN", payload: { isDomainOkay: false } });
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact your school administrator."
      );
    }
  };
  const forgotPassword = async (email, type) => {
    const { school } = state.user;
    const response = await axios.post(API_PATH.forgot, {
      email,
      type,
      domain: school.domain,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("Invalid data supplied! Please confirm and try again.");
    }
  };
  const resetPassword = async (code, password) => {
    const { school } = state.user;
    const response = await axios.post(API_PATH.forgot, {
      code,
      password,
      domain: school.domain,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your password reset code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your password reset code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your password reset code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while resetting your password! Please confirm and try again."
        );
      }
    }
  };
  const signup = async (
    fname,
    lname,
    email,
    phone,
    password,
    school,
    domain
  ) => {
    const response = await axios.post(API_PATH.signup, {
      fname,
      lname,
      email,
      phone,
      password,
      school,
      domain,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token, data.user.usertype);
      dispatch({ type: "REGISTER", payload: { user: data } });
    }
    if (status === "invalid_school") {
      throw new Error(
        "Your school name appears to have some error or has already been used! Please confirm and try again."
      );
    } else if (status === "invalid_domain") {
      throw new Error(
        "Your school domain appears to have some error or has already been used! Please confirm and try again."
      );
    } else if (status === "email_taken") {
      throw new Error(
        "Your email address is already registered with us! Please login or reset your password if you have forgotten it."
      );
    } else {
      throw new Error(
        "An error seems to have occured while signing you up! We apologize. Please try again later."
      );
    }
  };
  const checkEmail = async (email, type) => {
    const { school } = state.user;
    const response = await axios.post(API_PATH.login, {
      check_email: email,
      type,
      domain: school.domain,
    });
    const { status, data } = response.data;
    if (status === "set_password") {
      dispatch({ type: "SETPASSWORD", payload: { user: data } });
    }
    return status;
  };
  const checkDomain = async (domain) => {
    const response = await axios.post(API_PATH.signup, {
      check_school_domain: domain,
    });
    return response.data;
  };
  const setPassword = async (code, password, type) => {
    const { user } = state;
    const uid = type === "1" ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      set_password: uid,
      type,
      password,
      code,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token, data.user.usertype);
      dispatch({ type: "LOGIN", payload: { user: data } });
    } else if (status === "code_invalid" || status === "code_expired") {
      throw new Error("The code you entered is invalid or has expired!");
    } else if (status === "code_used") {
      throw new Error(
        "The code you entered has already been used! Please request another and try again."
      );
    } else {
      throw new Error(
        "Looks like an error occured while setting your password! Please try again later."
      );
    }
  };
  const sendPasswordVerify = async () => {
    const { user } = state;
    const { usertype } = user.user;
    const { domain } = user.school;
    const uid = parseInt(usertype, 10) === 1 ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      generate_set_password: uid,
      type: usertype,
      domain,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot send your set password code at the moment! Please try again later."
      );
    }
  };
  const updateSettings = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      update: "",
      ...values,
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status === "error") {
      throw new Error(
        "Something went wrong while updating the school settings. Please try again later."
      );
    }
    if (status === "invalid_school") {
      logout();
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact your school administrator."
      );
    }
  };
  const updateSchoolGrade = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      updateGrade: JSON.stringify(values),
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status === "error") {
      throw new Error(
        "Something went wrong while updating the school's grades. Please try again later."
      );
    }
    if (status === "invalid_school") {
      logout();
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact your school administrator."
      );
    }
  };
  const getRoles = async () => {
    const response = await axios.post(API_PATH.misc, { roles: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getDepartments = async () => {
    const response = await axios.post(API_PATH.misc, { departments: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getFaculties = async () => {
    const response = await axios.post(API_PATH.misc, { faculties: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getLecturers = async () => {
    const response = await axios.post(API_PATH.misc, { lecturers: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getCourses = async (type, semester) => {
    const response = await axios.post(API_PATH.misc, {
      courses: type || "",
      semester,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getStudents = async (students) => {
    const response = await axios.post(API_PATH.misc, {
      students,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };

  const getPermissions = async () => {
    const response = await axios.post(API_PATH.misc, { permissions: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const addRole = async (title, description, permissions) => {
    const response = await axios.post(API_PATH.roles, {
      title,
      description,
      permissions,
      add: "",
    });
    const { status, rid } = response.data;
    if (status === "success") {
      return rid;
    }
    throw new Error(
      "An error occured while adding the administrative role! Please try again later."
    );
  };
  const addAdmin = async (values) => {
    const response = await axios.post(API_PATH.admins, {
      ...values,
      role: values.role.rid,
      levels: JSON.stringify(values.levels.map((obj) => obj.uid)),
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "email_exists") {
      throw new Error(
        "The email address specified is already in use! Please use another and try again."
      );
    }
    throw new Error(
      "An error occured while adding the administrator! Please try again later."
    );
  };
  const addStudent = async (values) => {
    const response = await axios.post(API_PATH.students, {
      ...values,
      department: values.department.uid,
      programme: values.programme.value,
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "invalid_matric") {
      throw new Error(
        `A student with the matriculation number,${values.matric},has already been added!`
      );
    }
    if (status === "invalid_email") {
      throw new Error(
        `A student with the email address,${values.email},has already been added!`
      );
    }
    if (status === "invalid_phone") {
      throw new Error(
        `A student with the phone number,${values.phone},has already been added!`
      );
    }
    throw new Error(
      `An error occured while adding the student! Please try again later.`
    );
  };
  const addLecturer = async (values) => {
    const response = await axios.post(API_PATH.lecturers, {
      ...values,
      faculty: (values.faculty && values.faculty.uid) || "",
      department: (values.department && values.department.uid) || "",
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    throw new Error(
      `An error occured while adding the lecturer! Please try again later.`
    );
  };
  const addFaculty = async (values) => {
    const response = await axios.post(API_PATH.faculty, {
      ...values,
      dean: values.dean.uid,
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "exists") {
      throw new Error(
        `A faculty with the specified name has already been added!`
      );
    }
    throw new Error(
      `An error occured while adding the faculty! Please try again later.`
    );
  };
  const addDepartment = async (values) => {
    const response = await axios.post(API_PATH.departments, {
      ...values,
      dean: values.dean.uid,
      faculty: values.faculty.uid,
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "exists") {
      throw new Error(
        `A department with the specified name has already been added!`
      );
    }
    throw new Error(
      `An error occured while adding the department! Please try again later.`
    );
  };

  const handleRemoveEnrolledCourse = async (course, semester) => {
    await axios.post(API_PATH.courses, {
      semester,
      removeEnrolled: course,
    });
  };
  const registerCourse = async (values, semester) => {
    const response = await axios.post(API_PATH.courses, {
      ...values,
      semester,
      courses: JSON.stringify(values.courses),
      register: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "exists") {
      throw new Error(
        `A course with the specified name has already been added!`
      );
    }
    throw new Error(
      `An error occured while adding the course! Please try again later.`
    );
  };

  const addCourse = async (values) => {
    const response = await axios.post(API_PATH.courses, {
      ...values,
      department: values.department.uid,
      lecturer: values.lecturer.uid,
      semester: values.semester.value,
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "exists") {
      throw new Error(
        `A course with the specified name has already been added!`
      );
    }
    throw new Error(
      `An error occured while adding the course! Please try again later.`
    );
  };
  const addLecture = async (values) => {
    const response = await axios.post(API_PATH.lectures, {
      ...values,
      course: values.course.uid,
      materials: JSON.stringify(values.materials),
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "exists") {
      throw new Error(
        `A lecture with the specified title has already been added!`
      );
    }
    throw new Error(
      `An error occured while adding the lecture! Please try again later.`
    );
  };
  const updateLectureStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.lectures, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the lecture's status! Please try again later."
      );
    }
  };
  const getDashboard = async () => {
    const response = await axios.post(API_PATH.dashboard, { get: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching dashboard's analytics! Please try again later."
    );
  };
  const setExport = async (type, faculty, department, dateFrom, dateTo) => {
    const response = await axios.post(API_PATH.export, {
      type,
      faculty: faculty.uid || "",
      dateFrom,
      dateTo,
      export: "",
      department: department.uid || "",
    });
    const { status } = response.data;
    if (status === "department_not_found") {
      throw new Error(
        "The department specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "faculty_not_found") {
      throw new Error(
        "The faculty specified does not exist! Please refresh this page and try again."
      );
    }
    if (status !== "success") {
      throw new Error(
        "Something went wrong while attempting export! Please try again later."
      );
    }
  };
  const uploadImport = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.misc, formData, config);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "file_empty") {
      throw new Error(
        "The file you uploaded is empty! Please upload a valid file to proceed."
      );
    }
    throw new Error(
      "Something went wrong while attempting to upload file! Please try again later."
    );
  };
  const setImport = async (file, type, headers, branch, removeFirst) => {
    const response = await axios.post(API_PATH.import, {
      file,
      type,
      headers,
      branch,
      removeFirst,
      import: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "branch_not_found") {
      throw new Error(
        "The branch specified does not exist! Please refresh this page and try again."
      );
    }
    if (status === "staff_not_found") {
      throw new Error(
        "The staff specified does not exist! Please refresh this page and try again."
      );
    }

    throw new Error(
      "Something went wrong while attempting import! Please try again later."
    );
  };
  const getAdmin = async (admin) => {
    if (!admin) {
      throw new Error(
        "Invalid administrator's ID supplied! Please go back to the administrators page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.admins, { admin });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid administrator's ID supplied! Please go back to the administrators page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching administrator's data! Please try again later."
    );
  };
  const updateAdmin = async (values, uid) => {
    const response = await axios.post(API_PATH.admins, {
      ...values,
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the administrator! Please try again later."
      );
    }
    if (uid === state.user.user.uid) {
      getProfile();
    }
  };
  const updateAdminPassword = async (password, uid) => {
    const response = await axios.post(API_PATH.admins, {
      update_password: uid,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the administrator's password! Please try again later."
      );
    }
  };
  const updateAdminStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.admins, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the administrator's status! Please try again later."
      );
    }
  };
  const updateAdminRoleLevel = async (newLevels, newLevel, newRole, uid) => {
    const response = await axios.post(API_PATH.admins, {
      update_role_level: uid,
      newLevel,
      newRole: newRole.rid,
      newLevels: JSON.stringify(newLevels.map((obj) => obj.uid)),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the administrator's access level and role! Please try again later."
      );
    }
  };
  const updateAdminPhoto = async (photo, uid) => {
    const response = await axios.post(API_PATH.admins, {
      update_photo: uid,
      photo,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the administrator's photo! Please try again later."
      );
    }
  };
  const getRole = async (role) => {
    if (!role) {
      throw new Error(
        "Invalid administrative role's ID supplied! Please go back to the roles page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.roles, { role });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid administrative role's ID supplied! Please go back to the roles page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching administrative role's details! Please try again later."
    );
  };
  const updateRole = async (values, rid) => {
    const response = await axios.post(API_PATH.roles, {
      ...values,
      update: rid,
      permissions: values.permissions.join(","),
    });
    const { status, data } = response.data;
    if (status === "success" && data.user) {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the role! Please try again later."
      );
    }
  };
  const updateRoleStatus = async (newStatus, rid) => {
    const response = await axios.post(API_PATH.roles, {
      update_status: rid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the role's status! Please try again later."
      );
    }
  };
  const getStudent = async (student) => {
    if (!student) {
      throw new Error(
        "Invalid student's ID supplied! Please go back to the students page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.students, { student });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid student's ID supplied! Please go back to the students page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching student's data! Please try again later."
    );
  };
  const updateStudentStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.students, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's status! Please try again later."
      );
    }
  };
  const updateStudentPhoto = async (photo, uid) => {
    const response = await axios.post(API_PATH.students, {
      update_photo: uid,
      photo,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's photo! Please try again later."
      );
    }
  };
  const updateStudent = async (values, uid) => {
    const response = await axios.post(API_PATH.students, {
      ...values,
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student! Please try again later."
      );
    }
  };
  const updateStudentClass = async (values, uid) => {
    const response = await axios.post(API_PATH.students, {
      ...values,
      update_class: uid,
      department: values.department.uid,
      programme: values.programme.value,
      institutions: JSON.stringify(
        values.institutions.filter((obj) => obj.name !== "")
      ),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's class! Please try again later."
      );
    }
  };
  const updateStudentParent = async (values, uid) => {
    const response = await axios.post(API_PATH.students, {
      update_parent: uid,
      ...values,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's parent/guardian! Please try again later."
      );
    }
  };
  const updateStudentReferrees = async (data, uid) => {
    const response = await axios.post(API_PATH.students, {
      update_referrees: uid,
      data,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's referrees! Please try again later."
      );
    }
  };
  const updateStudentNOK = async (values, uid) => {
    const response = await axios.post(API_PATH.students, {
      update_nok: uid,
      ...values,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's next of kin! Please try again later."
      );
    }
  };
  const updateStudentAddress = async (values, uid) => {
    const response = await axios.post(API_PATH.students, {
      update_address: uid,
      ...values,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's address! Please try again later."
      );
    }
  };
  const updateStudentPassword = async (password, uid) => {
    const response = await axios.post(API_PATH.students, {
      update_password: uid,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's password! Please try again later."
      );
    }
  };
  const getLecturer = async (lecturer) => {
    if (!lecturer) {
      throw new Error(
        "Invalid student's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.lecturers, { lecturer });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid lecturer's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching lecturer's data! Please try again later."
    );
  };
  const updateLecturerPhoto = async (photo, uid) => {
    const response = await axios.post(API_PATH.lecturers, {
      update_photo: uid,
      photo,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the student's photo! Please try again later."
      );
    }
  };
  const updateLecturerStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.lecturers, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the lecturer's status! Please try again later."
      );
    }
  };

  const updateLecturer = async (values, uid) => {
    const response = await axios.post(API_PATH.lecturers, {
      ...values,
      faculty: (values.faculty && values.faculty.uid) || "",
      department: (values.department && values.department.uid) || "",
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the lecturer! Please try again later."
      );
    }
  };
  const updateLecturerPassword = async (password, uid) => {
    const response = await axios.post(API_PATH.lecturers, {
      update_password: uid,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the lecturer's password! Please try again later."
      );
    }
  };
  const getFaculty = async (faculty) => {
    if (!faculty) {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.faculty, { faculty });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the faculties page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching faculty's data! Please try again later."
    );
  };
  const getFacultyCourses = async (faculty) => {
    if (!faculty) {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.faculty, {
      faculty_courses: faculty,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the faculties page to select the right one."
      );
    }

    if (status === "empty_data") {
      throw new Error("This faculty does not have any course at the moment!");
    }

    throw new Error(
      "Something went wrong while fetching faculty's data! Please try again later."
    );
  };
  const getFacultyStudents = async (faculty) => {
    if (!faculty) {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.faculty, {
      faculty_students: faculty,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the faculties page to select the right one."
      );
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "empty_data") {
      throw new Error("This faculty does not have any student at the moment!");
    }

    throw new Error(
      "Something went wrong while fetching faculty's data! Please try again later."
    );
  };
  const getFacultyLecturers = async (faculty) => {
    if (!faculty) {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.faculty, {
      faculty_lecturers: faculty,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the faculties page to select the right one."
      );
    }

    if (status === "empty_data") {
      throw new Error("This faculty does not have any lecturer at the moment!");
    }

    throw new Error(
      "Something went wrong while fetching faculty's data! Please try again later."
    );
  };
  const getFacultyAdmins = async (faculty) => {
    if (!faculty) {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.faculty, {
      faculty_admin: faculty,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the faculties page to select the right one."
      );
    }

    if (status === "empty_data") {
      throw new Error(
        "This faculty does not have any administrator at the moment!"
      );
    }

    throw new Error(
      "Something went wrong while fetching faculty's data! Please try again later."
    );
  };
  const updateFacultyStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.faculty, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the faculty's status! Please try again later."
      );
    }
  };
  const updateFaculty = async (values, uid) => {
    const response = await axios.post(API_PATH.faculty, {
      ...values,
      update: uid,
      dean: values.dean.uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the faculty! Please try again later."
      );
    }
  };
  const getDepartment = async (department) => {
    if (!department) {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.departments, { department });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the faculties page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching department's data! Please try again later."
    );
  };
  const getDepartmentCourses = async (department) => {
    if (!department) {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.departments, {
      department_courses: department,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }

    if (status === "empty_data") {
      throw new Error(
        "This department does not have any course at the moment!"
      );
    }

    throw new Error(
      "Something went wrong while fetching department's data! Please try again later."
    );
  };
  const getDepartmentStudents = async (department) => {
    if (!department) {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.departments, {
      department_students: department,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the departments page to select the right one."
      );
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "empty_data") {
      throw new Error(
        "This department does not have any student at the moment!"
      );
    }

    throw new Error(
      "Something went wrong while fetching department's data! Please try again later."
    );
  };
  const getDepartmentLecturers = async (department) => {
    if (!department) {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.departments, {
      department_lecturers: department,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid faculty's ID supplied! Please go back to the departments page to select the right one."
      );
    }

    if (status === "empty_data") {
      throw new Error(
        "This department does not have any lecturer at the moment!"
      );
    }

    throw new Error(
      "Something went wrong while fetching department's data! Please try again later."
    );
  };
  const getDepartmentAdmins = async (department) => {
    if (!department) {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.departments, {
      department_admin: department,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }

    if (status === "empty_data") {
      throw new Error(
        "This department does not have any administrator at the moment!"
      );
    }

    throw new Error(
      "Something went wrong while fetching department's data! Please try again later."
    );
  };
  const updateDepartmentStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.departments, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the department's status! Please try again later."
      );
    }
  };
  const updateDepartment = async (values, uid) => {
    const response = await axios.post(API_PATH.departments, {
      ...values,
      update: uid,
      dean: values.dean.uid,
      faculty: values.faculty.uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the department! Please try again later."
      );
    }
  };
  const getCourse = async (course) => {
    if (!course) {
      throw new Error(
        "Invalid course's ID supplied! Please go back to the lecturers page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.courses, { course });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid course's ID supplied! Please go back to the faculties page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching course's data! Please try again later."
    );
  };
  const getCourseStudents = async (course) => {
    if (!course) {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.courses, {
      course_students: course,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid course ID supplied! Please go back to the courses page to select the right one."
      );
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "empty_data") {
      throw new Error(
        "This department does not have any student at the moment!"
      );
    }

    throw new Error(
      "Something went wrong while fetching course's data! Please try again later."
    );
  };
  const getCourseTests = async (course) => {
    if (!course) {
      throw new Error(
        "Invalid department's ID supplied! Please go back to the departments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.courses, {
      course_tests: course,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid course ID supplied! Please go back to the courses page to select the right one."
      );
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "empty_data") {
      throw new Error(
        "This department does not have any tests or exams at the moment!"
      );
    }

    throw new Error(
      "Something went wrong while fetching course's tests! Please try again later."
    );
  };
  const getLecture = async (lecture) => {
    if (!lecture) {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.lectures, { lecture });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching lecture's data! Please try again later."
    );
  };
  const startLecture = async (start) => {
    if (!start) {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.lectures, { start });
    const { status } = response.data;
    if (status === "success") {
      return true;
    }
    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching lecture's data! Please try again later."
    );
  };
  const stopLecture = async (stop) => {
    if (!stop) {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.lectures, { stop });
    const { status } = response.data;
    if (status === "success") {
      return true;
    }
    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching lecture's data! Please try again later."
    );
  };
  const fetchZegoToken = async (lecture) => {
    if (!lecture) {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.lectures, { token: lecture });
    const { status, token } = response.data;
    if (status === "success") {
      return token;
    }
    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid lecture's ID supplied! Please go back to the lectures page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching lecture's data! Please try again later."
    );
  };
  const updateCourseStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.courses, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the course's status! Please try again later."
      );
    }
  };
  const updateCourse = async (values, uid) => {
    const response = await axios.post(API_PATH.courses, {
      ...values,
      update: uid,
      department: values.department.uid,
      lecturer: values.lecturer.uid,
      semester: values.semester.value,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the department! Please try again later."
      );
    }
  };
  const updateLecture = async (values, uid) => {
    const response = await axios.post(API_PATH.lectures, {
      ...values,
      update: uid,
      course: values.course.uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the lecture! Please try again later."
      );
    }
  };
  const updateLectureMaterials = async (values, uid) => {
    const response = await axios.post(API_PATH.lectures, {
      materials: JSON.stringify(values.materials),
      update_material: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating materials! Please try again later."
      );
    }
  };
  const getTopupRef = async (getRef, isWallet) => {
    const response = await axios.post(API_PATH.payments, { getRef, isWallet });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "amount_too_low") {
      throw new Error(
        "The amount specified is below the minimum topup amount! Please enter a valid amount and confirm."
      );
    }
    if (status === "amount_too_high") {
      throw new Error(
        "The amount specified is above the maximum topup amount! Please enter a valid amount and confirm."
      );
    }
    throw new Error(
      "An error occured while initializing wallet topup! Please try again later."
    );
  };
  const addPayment = async (values) => {
    const response = await axios.post(API_PATH.payments, {
      ...values,
      data: JSON.stringify(values.data.map((obj) => obj.uid)),
      programme: values.programme.value || "",
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "email_exists") {
      throw new Error(
        "The email address specified is already in use! Please use another and try again."
      );
    }
    throw new Error(
      "An error occured while adding the administrator! Please try again later."
    );
  };
  const getPayment = async (payment) => {
    if (!payment) {
      throw new Error(
        "Invalid payment's ID supplied! Please go back to the payments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.payments, { payment });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid payment's ID supplied! Please go back to the payments page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching payment's data! Please try again later."
    );
  };
  const updatePayment = async (values, uid) => {
    const response = await axios.post(API_PATH.payments, {
      ...values,
      update: uid,
      programme: values.programme.value || "",
      data: JSON.stringify(values.data.map((obj) => obj.uid)),
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the payment! Please try again later."
      );
    }
  };
  const updatePaymentStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.payments, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the payment's status! Please try again later."
      );
    }
  };
  const uploadAttachment = async (files) => {
    const response = await axios({
      method: "post",
      url: API_PATH.mailbox,
      data: files,
      headers: { "Content-Type": "multipart/form-data" },
    });
    const { status, data } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while uploading attachments! Please try again later."
      );
    }
    return data;
  };
  const sendMail = async (to, subject, message, attachments) => {
    const response = await axios.post(API_PATH.mailbox, {
      to,
      subject,
      message,
      attachments,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while sending your message! Please try again later."
      );
    }
  };
  const uploadMaterial = async (files) => {
    const response = await axios({
      method: "post",
      url: API_PATH.lectures,
      data: files,
      headers: { "Content-Type": "multipart/form-data" },
    });
    const { status, file } = response.data;
    if (status !== "success") {
      throw new Error("Upload failed! Please try again.");
    }
    return file;
  };
  const uploadLectureNote = async (files) => {
    const response = await axios({
      method: "post",
      url: API_PATH.lectures,
      data: files,
      headers: { "Content-Type": "multipart/form-data" },
    });
    const { status, file } = response.data;
    if (status !== "success") {
      throw new Error("Upload failed! Please try again.");
    }
    return file;
  };
  const addTest = async (values) => {
    const response = await axios.post(API_PATH.tests, {
      ...values,
      course: values.course.uid,
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "email_exists") {
      throw new Error(
        "The email address specified is already in use! Please use another and try again."
      );
    }
    throw new Error(
      "An error occured while adding the administrator! Please try again later."
    );
  };
  const addAssignment = async (values) => {
    const response = await axios.post(API_PATH.assignments, {
      ...values,
      course: values.course.uid,
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "no_course") {
      throw new Error(
        "The course specified does not exist! Please use another and try again."
      );
    }
    throw new Error(
      "An error occured while adding the assignment! Please try again later."
    );
  };
  const getTest = async (test) => {
    if (!test) {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests and exams page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.tests, { test });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests and exams page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching test's data! Please try again later."
    );
  };
  const getGrade = async (grade) => {
    if (!grade) {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests and exams page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.grades, { grade });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid grade's ID supplied! Please go back to the grades page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching grade's data! Please try again later."
    );
  };
  const getAssignment = async (assignment) => {
    if (!assignment) {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.assignments, { assignment });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments and exams page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching assignment's data! Please try again later."
    );
  };
  const saveTestAnswers = async (test, answers, isFinal) => {
    if (!test) {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests and exams page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.tests, {
      save_answer: test,
      isFinal,
      answers: JSON.stringify(answers),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests and exams page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching test's data! Please try again later."
    );
  };
  const saveAssignmentAnswers = async (assignment, answers, isFinal) => {
    if (!assignment) {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.assignments, {
      save_answer: assignment,
      isFinal,
      answers: JSON.stringify(answers),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching assignment's data! Please try again later."
    );
  };
  const saveGradeAssignment = async (
    assignmentId,
    scores,
    totalScore,
    possibleScore,
    grade,
    isDone
  ) => {
    if (!assignmentId) {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.assignments, {
      save_grade: assignmentId,
      isDone,
      scores: JSON.stringify(scores),
      totalScore,
      possibleScore,
      grade: grade.value || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching assignment's data! Please try again later."
    );
  };
  const saveAssignmentFeedback = async (assignmentId, feedback) => {
    if (!assignmentId) {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.assignments, {
      save_feedback: assignmentId,
      feedback,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to perform this action! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid assignment's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching assignment's data! Please try again later."
    );
  };
  const saveTestFeedback = async (testId, feedback) => {
    if (!testId) {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the assignments page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.grades, {
      save_feedback: testId,
      feedback,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to perform this action! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests/exams page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching test's data! Please try again later."
    );
  };
  const saveGrade = async (
    testId,
    scores,
    totalScore,
    possibleScore,
    grade,
    isDone
  ) => {
    if (!testId) {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.grades, {
      save_grade: testId,
      isDone,
      scores: JSON.stringify(scores),
      totalScore,
      possibleScore,
      grade: grade.value || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid test's ID supplied! Please go back to the tests page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching test's data! Please try again later."
    );
  };
  const addGrade = async (values) => {
    const response = await axios.post(API_PATH.grades, {
      add: "",
      ...values,
      student: (values.student && values.student.uid) || "",
      course: (values.course && values.course.uid) || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "access_denied") {
      throw new Error(
        "You do not have permission to view this page! Please contact the administrator for more details."
      );
    }

    if (status === "student_not_found") {
      throw new Error(
        "Invalid student supplied! Please go back and a valid student to proceed."
      );
    }
    if (status === "course_not_found") {
      throw new Error(
        "Invalid course supplied! Please go back and a valid course to proceed."
      );
    }
    throw new Error(
      "Something went wrong while adding grade! Please try again later."
    );
  };
  const updateTestStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.tests, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the test's status! Please try again later."
      );
    }
  };
  const updateAssignmentStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.assignments, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the assignment's status! Please try again later."
      );
    }
  };
  const updateTest = async (values, uid) => {
    const response = await axios.post(API_PATH.tests, {
      ...values,
      update: uid,
      course: values.course.uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the test! Please try again later."
      );
    }
  };
  const updateAssignment = async (values, uid) => {
    const response = await axios.post(API_PATH.assignments, {
      ...values,
      update: uid,
      course: values.course.uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the assignment! Please try again later."
      );
    }
  };
  const updateTestSection = async (values, uid) => {
    const response = await axios.post(API_PATH.tests, {
      questions: values,
      update_questions: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the test's questions! Please try again later."
      );
    }
  };
  const updateAssignmentSection = async (values, uid) => {
    const response = await axios.post(API_PATH.assignments, {
      questions: values,
      update_questions: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while updating the assignment's questions! Please try again later."
      );
    }
  };
  const processPayment = async (payments, amount, ref) => {
    const response = await axios.post(API_PATH.payments, {
      verify: ref,
      amount,
      payments: JSON.stringify(payments),
    });
    const { status } = response.data;
    if (status === "insufficient_balance") {
      throw new Error(
        "You do not have sufficient balance in your wallet to process this amount!"
      );
    }
    if (status === "error") {
      throw new Error(
        "There was an error with the payment made! Please try again later or contact us if you were debited."
      );
    }
  };
  const getPaymentAnalytics = async (values) => {
    const response = await axios.post(API_PATH.paymentAnalytics, values);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "There was an error with the payment analytics! Please try again later."
    );
  };
  const getNotifications = async () => {
    const response = await axios.post(API_PATH.noticeBoard, { recent: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "There was an error fetching your notifications! Please try again later."
    );
  };
  const addNotice = async (values) => {
    const response = await axios.post(API_PATH.noticeBoard, {
      ...values,
      data: JSON.stringify(values.data.map((obj) => obj.uid)),
      add: "",
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "email_exists") {
      throw new Error(
        "The email address specified is already in use! Please use another and try again."
      );
    }
    throw new Error(
      "An error occured while adding the administrator! Please try again later."
    );
  };
  const updateNotice = async (nid, values) => {
    const response = await axios.post(API_PATH.noticeBoard, {
      ...values,
      data: JSON.stringify(values.data.map((obj) => obj.uid)),
      update: nid,
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "email_exists") {
      throw new Error(
        "The email address specified is already in use! Please use another and try again."
      );
    }
    throw new Error(
      "An error occured while adding the administrator! Please try again later."
    );
  };
  const updateGrade = async (values, gid) => {
    const response = await axios.post(API_PATH.grades, {
      ...values,
      student: (values.student && values.student.uid) || "",
      course: (values.course && values.course.uid) || "",
      update: gid,
    });
    const { status, uid } = response.data;
    if (status === "success") {
      return uid;
    }
    if (status === "student_not_found") {
      throw new Error(
        "Invalid student supplied! Please go back and a valid student to proceed."
      );
    }
    if (status === "course_not_found") {
      throw new Error(
        "Invalid course supplied! Please go back and a valid course to proceed."
      );
    }
    throw new Error(
      "Something went wrong while updating grade! Please try again later."
    );
  };
  const getNotice = async (message) => {
    const response = await axios.post(API_PATH.noticeBoard, {
      message,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "An error occured while fetching the notice board's message details! Please try again later."
    );
  };
  const markNotification = async () => {
    const response = await axios.post(API_PATH.noticeBoard, {
      mark: "",
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("An error occured ! Please try again later.");
    }
  };
  const addNoticeView = async (notice) => {
    axios.post(API_PATH.noticeBoard, { viewed: notice });
  };
  const deleteAdmin = async (uid) => {
    const response = await axios.post(API_PATH.admins, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting the administrator's account! Please try again later."
      );
    }
  };
  const deleteStudent = async (uid) => {
    const response = await axios.post(API_PATH.students, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting the student's account! Please try again later."
      );
    }
  };
  const deleteLecturer = async (uid) => {
    const response = await axios.post(API_PATH.lecturers, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting the lecturer's account! Please try again later."
      );
    }
  };
  const deleteFaculty = async (uid) => {
    const response = await axios.post(API_PATH.faculty, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting the faculty! Please try again later."
      );
    }
  };
  const deleteDepartment = async (uid) => {
    const response = await axios.post(API_PATH.departments, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting the department! Please try again later."
      );
    }
  };
  const deleteCourse = async (uid) => {
    const response = await axios.post(API_PATH.courses, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting the course! Please try again later."
      );
    }
  };
  const deleteLecture = async (uid) => {
    const response = await axios.post(API_PATH.lectures, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting the lecture! Please try again later."
      );
    }
  };
  const deleteTestExams = async (uid) => {
    const response = await axios.post(API_PATH.tests, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting this item! Please try again later."
      );
    }
  };
  const deleteAssignment = async (uid) => {
    const response = await axios.post(API_PATH.assignments, {
      remove: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occured while deleting this assignment! Please try again later."
      );
    }
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        method: "jwt",
        deleteAssignment,
        deleteTestExams,
        deleteLecture,
        deleteCourse,
        deleteDepartment,
        deleteFaculty,
        deleteLecturer,
        deleteStudent,
        deleteAdmin,
        signup,
        checkDomain,
        login,
        logout,
        checkEmail,
        setPassword,
        updateSettings,
        updateSchoolGrade,
        sendPasswordVerify,
        getRoles,
        getDepartments,
        getFaculties,
        getPermissions,
        addRole,
        addAdmin,
        addStudent,
        addLecturer,
        getLecturers,
        addFaculty,
        addDepartment,
        addCourse,
        addLecture,
        updateLectureStatus,
        updateAdmin,
        getAdmin,
        updateAdminPassword,
        updateAdminStatus,
        updateAdminPhoto,
        getRole,
        updateRole,
        updateAdminRoleLevel,
        updateRoleStatus,
        getStudent,
        updateStudentStatus,
        updateStudentPhoto,
        updateStudent,
        updateStudentClass,
        updateStudentParent,
        updateStudentNOK,
        updateStudentReferrees,
        updateStudentAddress,
        updateStudentPassword,
        getLecturer,
        updateLecturerPhoto,
        updateLecturerStatus,
        updateLecturer,
        updateLecturerPassword,
        getFaculty,
        getFacultyAdmins,
        getFacultyLecturers,
        getFacultyStudents,
        getFacultyCourses,
        updateFacultyStatus,
        updateFaculty,
        getProfile,
        getDepartment,
        getLecture,
        startLecture,
        stopLecture,
        fetchZegoToken,
        getDepartmentCourses,
        getDepartmentLecturers,
        getDepartmentStudents,
        getDepartmentAdmins,
        updateDepartmentStatus,
        updateDepartment,
        getCourse,
        getCourseStudents,
        getCourseTests,
        updateCourseStatus,
        updateCourse,
        getTopupRef,
        addPayment,
        getPayment,
        updatePayment,
        updatePaymentStatus,
        uploadAttachment,
        updateLecture,
        updateLectureMaterials,
        sendMail,
        getCourses,
        getStudents,
        uploadMaterial,
        uploadLectureNote,
        addTest,
        addAssignment,
        getTest,
        getAssignment,
        saveTestAnswers,
        saveAssignmentAnswers,
        updateTestStatus,
        updateAssignmentStatus,
        updateTest,
        updateAssignment,
        updateTestSection,
        updateAssignmentSection,
        processPayment,
        registerCourse,
        handleRemoveEnrolledCourse,
        getDashboard,
        setExport,
        getPaymentAnalytics,
        addNotice,
        getNotice,
        addNoticeView,
        updateNotice,
        updateGrade,
        getNotifications,
        markNotification,
        saveGradeAssignment,
        saveAssignmentFeedback,
        saveTestFeedback,
        saveGrade,
        addGrade,
        forgotPassword,
        resetPassword,
        getGrade,
        uploadImport,
        setImport,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
