import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Link as RouterLink } from "react-router-dom";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Link } from "@mui/material";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import { MHidden } from "../../components/@material-extend";
import Searchbar from "./Searchbar";
import NotificationsPopover from "./NotificationsPopover";
import ToggleDark from "./ToggleDark";
import { PATH_DASHBOARD, PATH_PAGE } from "../../routes/paths";
import useAuth from "../../hooks/useAuth";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const { usertype } = useAuth();
  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <ToggleDark />
          <IconButton
            component={RouterLink}
            to={PATH_DASHBOARD.general.calendar}
          >
            {" "}
            <CalendarTodayIcon width={20} height={20} />
          </IconButton>
          {usertype === 0 && <NotificationsPopover />}

          <Link href={PATH_PAGE.support} target="_blank">
            <HelpOutlineOutlinedIcon width={20} height={20} />
          </Link>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
