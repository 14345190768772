import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Grid, Card, Button, Typography } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { isNumeric, fNumber } from "../../../utils/formatNumber";
import { ShowError } from "../../../utils/loader";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

SingleCard.propTypes = {
  lecture: PropTypes.object,
};

function SingleCard({ lecture }) {
  const { title, numMaterials, uid, type } = lecture;
  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 3 }}>
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {parseInt(type, 10) === 1
              ? "LIVE"
              : `${
                  isNumeric(numMaterials) ? fNumber(numMaterials) : "0"
                } material(s)`}
          </Typography>
        </Box>
      </Box>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        component={RouterLink}
        to={`${PATH_DASHBOARD.subpages.lecture}/${uid}/${paramCase(title)}`}
      >
        View
      </Button>
    </Card>
  );
}

CourseLectures.propTypes = {
  lectures: PropTypes.array.isRequired,
};

export default function CourseLectures({ lectures }) {
  const { usertype } = useAuth();
  return (
    <Box sx={{ mt: usertype === 0 ? 3 : 5 }}>
      <Typography variant={usertype === 0 ? "h6" : "h4"} sx={{ mb: 3 }}>
        Lectures
      </Typography>
      {lectures.length > 0 ? (
        <Grid container spacing={3}>
          {lectures.map((lecture) => (
            <Grid key={lecture.uid} item xs={12} md={usertype === 0 ? 12 : 4}>
              <SingleCard lecture={lecture} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          isNotShowImage
          message={
            new Error("This course does not have any lecture at the moment!")
          }
        />
      )}
    </Box>
  );
}
