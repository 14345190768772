import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_PATH } from '../../utils/apis';

const initialState = {
    isLoading: false,
    error: false,
    faculties: [],
    totalFaculties: 0,
    sortBy: null,
    filters: {
        status: '',
        search: ''
    },
};

const slice = createSlice({
    name: 'faculties',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET USERS
        getSuccess(state, action) {
            state.isLoading = false;
            state.faculties = action.payload.data;
            state.totalFaculties = action.payload.total;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

export function getFaculties(search, stat, sortBy, page, perpage) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_PATH.faculty, { search, status: stat, page, perpage, sortBy });
            const { status, data, total } = response.data;
            if (status === "success") {
                dispatch(slice.actions.getSuccess({ data, total }));
            } else if (status === "access_denied") {
                throw (new Error("You do not have permission to view this page! Please contact the administrator for more details."));
            } else {
                throw (new Error("Something went wrong while fetching faculties! Please try again later."));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}