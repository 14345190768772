import PropTypes from "prop-types";
import { forwardRef, useState, useEffect, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
// material
import {
  AppBar,
  Toolbar,
  Card,
  IconButton,
  Slide,
  Typography,
  Stack,
  Dialog,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  DialogTitle,
  Radio,
  DialogContent,
  DialogActions,
  Box,
  InputAdornment,
  Alert,
  AlertTitle,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import Label from "../../Label";
import { fNumber } from "../../../utils/formatNumber";
import useAuth from "../../../hooks/useAuth";
import { GRADES_COLOR } from "../../../utils/apis";

GradeAssignment.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  answers: PropTypes.object,
  questions: PropTypes.array,
  title: PropTypes.string,
};
const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

CommentModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleFeedbackChanged: PropTypes.func,
  handleFeedback: PropTypes.func,
  student: PropTypes.string,
  feedback: PropTypes.string,
};
function CommentModal({
  open,
  loading,
  student,
  handleClose,
  handleFeedback,
  handleFeedbackChanged,
  feedback,
}) {
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "info.main" }}>
        Write a Feedback to {student}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          name="feedback"
          value={feedback}
          sx={{
            "& .MuiFormHelperText-root": {
              fontSize: "1rem",
            },
            mt: 2,
          }}
          onChange={handleFeedbackChanged}
          multiline
          rows={7}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleFeedback}
          color="info"
          variant="contained"
          loading={loading}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default function GradeAssignment({
  open,
  questions,
  answers,
  handleClose,
  title,
}) {
  const { saveGradeAssignment, user, usertype, saveAssignmentFeedback } =
    useAuth();
  const { grades } = user.school;
  const [questionIndex, setQuestionIndex] = useState(0);
  const [subQuestionIndex, setSubQuestionIndex] = useState(0);
  const [results, setResults] = useState({});
  const [plag, setPlag] = useState({});
  const [grade, setGrade] = useState({});
  const [scores, setScores] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const [possibleScore, setPossibleScore] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const [GRADES, setGRADES] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const setScore = useCallback(() => {
    try {
      const total = Object.values(scores).reduce(
        (prev, value) => parseFloat(prev) + parseFloat(value),
        0
      );
      setTotalScore(total);
    } catch (err) {
      console.log(err);
    }
  }, [scores]);
  const getScore = () => {
    let score = 0;
    if (scores[`${questionIndex}-${subQuestionIndex}`]) {
      score = scores[`${questionIndex}-${subQuestionIndex}`];
    } else {
      try {
        const question = questions[questionIndex].questions[subQuestionIndex];
        const answer = results[`${questionIndex}-${subQuestionIndex}`];
        if (question.type === "single") {
          const correctAnswer = question.answers.filter(
            (ans) => parseInt(ans.correct, 10) === 1
          );
          if (correctAnswer.length > 0 && correctAnswer[0] === answer) {
            score = parseFloat(question.score);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    return parseFloat(score) || 0;
  };
  const setGradeValue = useCallback(() => {
    try {
      if (possibleScore > 0) {
        const percent = Math.round((totalScore / possibleScore) * 100);
        const g = GRADES.filter(
          (grade) => percent >= grade.min && percent <= grade.max
        );
        if (g.length > 0) {
          setGrade(g[0]);
        } else {
          setGrade({});
        }
      } else {
        setGrade({});
      }
    } catch (err) {
      console.log(err);
    }
  }, [totalScore, GRADES, possibleScore]);

  const handleSave = useCallback(() => {
    const save = async () => {
      try {
        console.log(scores, answers.id);
        if (Object.keys(scores).length > 0) {
          await saveGradeAssignment(
            answers.id,
            scores,
            totalScore,
            possibleScore,
            grade,
            isDone
          );
          if (isDone) {
            enqueueSnackbar(`Assignment graded successfully!`, {
              variant: "success",
            });
            handleClose();
          }
        }
      } catch (err) {
        if (isDone) {
          enqueueSnackbar(
            err.message || "An error has occured! Please try again later.",
            {
              variant: "error",
            }
          );
        }
      }
    };
    save();
  }, [
    scores,
    totalScore,
    possibleScore,
    grade,
    isDone,
    answers.id,
    enqueueSnackbar,
    handleClose,
    saveGradeAssignment,
  ]);

  const handleFeedbackChanged = (e) => {
    const { value } = e.target;
    setFeedback(value);
  };
  const handleFeedback = async () => {
    try {
      if (feedback) {
        setIsFeedbackLoading(true);
        await saveAssignmentFeedback(answers.id, feedback);
        enqueueSnackbar(`Feedback saved successfully!`, {
          variant: "success",
        });
        setOpenModal(false);
      } else {
        throw new Error("Enter a valid feedback to proceed.");
      }
    } catch (err) {
      enqueueSnackbar(
        err.message || "An error has occured! Please try again later.",
        {
          variant: "error",
        }
      );
    }
    setIsFeedbackLoading(false);
  };

  useEffect(() => {
    if (open) {
      setGRADES(grades ? JSON.parse(grades) : []);
    }
  }, [grades, open]);

  useEffect(() => {
    const getTotalScore = () => {
      let total = 0;
      try {
        for (let i = 0; i < questions.length; i += 1) {
          const question = questions[i].questions;
          for (let j = 0; j < question.length; j += 1) {
            total += parseFloat(question[j].score);
          }
        }
      } catch (err) {
        console.log(err);
      }
      return total;
    };
    if (open) {
      setResults(answers.answers ? JSON.parse(answers.answers) : {});
      setPlag(answers.plagResult ? JSON.parse(answers.plagResult) : {});
      setScores(answers.scores ? JSON.parse(answers.scores) : {});
      const g = GRADES.filter((grade) => (answers.grade || "") === grade.value);
      if (g.length > 0) {
        setGrade(g[0]);
      } else {
        setGrade({});
      }
      setTotalScore(answers.totalScore || 0);
      setPossibleScore(parseFloat(answers.possibleScore) || getTotalScore());
      setFeedback(answers.comment || "");
    }
  }, [answers, GRADES, questions, open]);
  useEffect(() => {
    if (open) {
      setScore();
    }
  }, [setScore, open]);

  useEffect(() => {
    if (open) {
      setGradeValue();
    }
  }, [setGradeValue, open]);
  useEffect(() => {
    if (open && usertype !== 0) {
      handleSave();
    }
  }, [handleSave, open, usertype]);
  return (
    <>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <DialogTitle>
          <AppBar position="relative" sx={{ bgcolor: "primary.main" }}>
            <Toolbar>
              <IconButton color="inherit" edge="start" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
                {title} by {answers.studentName}.
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ width: 1 }}>
            <Card
              variant="outlined"
              sx={{
                width: 1,
                px: { md: 10, xs: 1.5 },
                py: 4,
                backgroundColor: "background.default",
                boxShadow: "none",
              }}
            >
              <Stack sx={{ width: 1 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ pb: 5 }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Typography variant="h2">{totalScore}</Typography>
                    <Typography variant="h4" sx={{ color: "text.disabled" }}>
                      /
                    </Typography>
                    <Typography variant="h2">{possibleScore}</Typography>
                  </Stack>
                  {grade.value && (
                    <Typography
                      variant="h2"
                      sx={{
                        color: `${
                          grade.value
                            ? GRADES_COLOR.filter(
                                (g) => g.value === grade.value
                              )[0].color
                            : "info"
                        }.main`,
                        backgroundColor: `${
                          grade.value
                            ? GRADES_COLOR.filter(
                                (g) => g.value === grade.value
                              )[0].color
                            : "info"
                        }.lighter`,
                        px: 1.5,
                        py: 0,
                      }}
                    >
                      {grade.value}
                    </Typography>
                  )}
                </Stack>
                {feedback && (
                  <Box sx={{ py: 2 }}>
                    <Alert severity="info">
                      <AlertTitle>Feedback:</AlertTitle>
                      {feedback}
                    </Alert>
                  </Box>
                )}
                <Typography variant="h3" paragraph>
                  {questions[questionIndex].title}
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 5 }}>
                  {questions[questionIndex].description}
                </Typography>
                <Stack direction="column" spacing={4}>
                  <Stack direction="column" spacing={2}>
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography variant="body1">
                        Question {subQuestionIndex + 1}.
                      </Typography>
                      <Box sx={{ alignSelf: "flex-end" }}>
                        {questions[questionIndex].questions[subQuestionIndex]
                          .score && (
                          <Label>
                            {
                              questions[questionIndex].questions[
                                subQuestionIndex
                              ].score
                            }{" "}
                            {parseInt(
                              questions[questionIndex].questions[
                                subQuestionIndex
                              ].score,
                              10
                            ) > 1
                              ? "marks"
                              : "mark"}
                          </Label>
                        )}
                      </Box>
                    </Stack>
                    <Typography variant="body1">
                      {
                        questions[questionIndex].questions[subQuestionIndex]
                          .question
                      }
                    </Typography>
                    {questions[questionIndex].questions[subQuestionIndex]
                      .type === "essay" && (
                      <Stack direction="column" spacing={2}>
                        <TextField
                          disabled
                          name={`answer-${questionIndex}`}
                          value={
                            results[`${questionIndex}-${subQuestionIndex}`]
                              ? results[
                                  `${questionIndex}-${subQuestionIndex}`
                                ].replaceAll("\\n", "\n")
                              : ""
                          }
                          sx={{
                            "& .MuiFormHelperText-root": {
                              fontSize: "1rem",
                            },
                          }}
                          multiline
                          rows={7}
                        />
                        <Stack direction="row" spacing={2}>
                          <Typography variant="subtitle2">
                            Plagiarism Checker Status:
                          </Typography>
                          <Label
                            color={
                              plag[`${questionIndex}-${subQuestionIndex}`]
                                ? "success"
                                : "warning"
                            }
                          >
                            {plag[`${questionIndex}-${subQuestionIndex}`]
                              ? "Completed"
                              : "Pending"}
                          </Label>
                        </Stack>
                        {plag[`${questionIndex}-${subQuestionIndex}`] && (
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle2">
                              Plagiarism Score:
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color:
                                  parseInt(
                                    plag[`${questionIndex}-${subQuestionIndex}`]
                                      .aggregatedScore,
                                    10
                                  ) >= 50
                                    ? "success.main"
                                    : "error.main",
                              }}
                            >
                              {
                                plag[`${questionIndex}-${subQuestionIndex}`]
                                  .aggregatedScore
                              }
                              %
                            </Typography>
                          </Stack>
                        )}
                        {plag[`${questionIndex}-${subQuestionIndex}`] && (
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle2">
                              Number of Identical Words:
                            </Typography>
                            <Typography variant="subtitle2">
                              {fNumber(
                                plag[`${questionIndex}-${subQuestionIndex}`]
                                  .identicalWords
                              )}
                            </Typography>
                          </Stack>
                        )}
                        {plag[`${questionIndex}-${subQuestionIndex}`] && (
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle2">
                              Number of Minor Words Changes:
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "text.secondary" }}
                            >
                              {fNumber(
                                plag[`${questionIndex}-${subQuestionIndex}`]
                                  .minorChangedWords
                              )}
                            </Typography>
                          </Stack>
                        )}
                        {plag[`${questionIndex}-${subQuestionIndex}`] && (
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle2">
                              Number of Words With Related Meanings:
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "text.secondary" }}
                            >
                              {fNumber(
                                plag[`${questionIndex}-${subQuestionIndex}`]
                                  .relatedMeaningWords
                              )}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    )}
                    {questions[questionIndex].questions[subQuestionIndex]
                      .type === "single" && (
                      <Stack direction="column" spacing={1.5}>
                        {questions[questionIndex].questions[
                          subQuestionIndex
                        ].answers.map((answer, index) => (
                          <Stack
                            key={index}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  size="small"
                                  color="error"
                                  disabled
                                  checked={
                                    results[
                                      [`${questionIndex}-${subQuestionIndex}`]
                                    ] === answer.answer
                                  }
                                />
                              }
                              label={answer.answer}
                              sx={
                                parseInt(answer.correct, 10) === 1
                                  ? {
                                      ml: 0,
                                      width: 1,
                                      border: "1px solid",
                                      borderColor: "background.neutral",
                                      flexDirection: "row-reverse",
                                      justifyContent: "space-between",
                                      pr: 1,
                                      pl: 4,
                                      borderLeft: "5px solid",
                                      borderLeftColor: "primary.main",
                                    }
                                  : {
                                      ml: 0,
                                      width: 1,
                                      border: "1px solid",
                                      borderColor: "background.neutral",
                                      flexDirection: "row-reverse",
                                      justifyContent: "space-between",
                                      pr: 1,
                                      pl: 4,
                                    }
                              }
                            />
                            {results[
                              [`${questionIndex}-${subQuestionIndex}`]
                            ] === answer.answer &&
                              (parseInt(answer.correct, 10) === 1 ? (
                                <DoneAllIcon color="success" />
                              ) : (
                                <ClearIcon color="error" />
                              ))}
                          </Stack>
                        ))}
                      </Stack>
                    )}
                    {questions[questionIndex].questions[subQuestionIndex]
                      .type === "multiple" && (
                      <Stack direction="column" spacing={1}>
                        {questions[questionIndex].questions[
                          subQuestionIndex
                        ].answers.map((answer, index) => (
                          <Stack
                            key={index}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  size="small"
                                  color="error"
                                  disabled
                                  checked={(
                                    results[
                                      [`${questionIndex}-${subQuestionIndex}`]
                                    ] || ""
                                  )
                                    .split(",")
                                    .includes(answer.answer)}
                                />
                              }
                              label={answer.answer}
                              sx={
                                parseInt(answer.correct, 10) === 1
                                  ? {
                                      ml: 0,
                                      width: 1,
                                      border: "1px solid",
                                      borderColor: "background.neutral",
                                      flexDirection: "row-reverse",
                                      justifyContent: "space-between",
                                      pr: 1,
                                      pl: 4,
                                      borderLeft: "5px solid",
                                      borderLeftColor: "primary.main",
                                    }
                                  : {
                                      ml: 0,
                                      width: 1,
                                      border: "1px solid",
                                      borderColor: "background.neutral",
                                      flexDirection: "row-reverse",
                                      justifyContent: "space-between",
                                      pr: 1,
                                      pl: 4,
                                    }
                              }
                            />
                            {(
                              results[
                                [`${questionIndex}-${subQuestionIndex}`]
                              ] || ""
                            )
                              .split(",")
                              .includes(answer.answer) &&
                              (parseInt(answer.correct, 10) === 1 ? (
                                <DoneAllIcon color="success" />
                              ) : (
                                <ClearIcon color="error" />
                              ))}
                          </Stack>
                        ))}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Stack
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              name="score"
              value={getScore()}
              type="number"
              placeholder="Score"
              size="small"
              disabled={usertype === 0}
              label="Score"
              onChange={(e) => {
                const { value } = e.target;
                if (value && usertype !== 0) {
                  const maxValue = parseFloat(
                    questions[questionIndex].questions[subQuestionIndex].score
                  );
                  if (value <= maxValue) {
                    setScores((prev) => ({
                      ...prev,
                      [`${questionIndex}-${subQuestionIndex}`]:
                        parseFloat(value),
                    }));
                  } else {
                    enqueueSnackbar(
                      `The score cannot be greater than ${maxValue}`,
                      { variant: "error" }
                    );
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "text.secondary" }}
                    >
                      {questions[questionIndex].questions[subQuestionIndex]
                        .score
                        ? `/${questions[questionIndex].questions[subQuestionIndex].score}`
                        : ""}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent={
                questionIndex > 0 || subQuestionIndex > 0
                  ? "space-between"
                  : "end"
              }
              spacing={4}
              sx={{ mt: 5 }}
            >
              {(questionIndex > 0 || subQuestionIndex > 0) && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    subQuestionIndex > 0
                      ? setSubQuestionIndex((prevState) => prevState - 1)
                      : setQuestionIndex((prevState) => prevState - 1)
                  }
                  color="secondary"
                >
                  Back
                </Button>
              )}
              <LoadingButton
                onClick={() => {
                  const numQuestions =
                    questions[questionIndex].questions.length;
                  if (subQuestionIndex + 1 < numQuestions) {
                    setSubQuestionIndex((prevState) => prevState + 1);
                  } else if (questionIndex + 1 < questions.length) {
                    setSubQuestionIndex(0);
                    setQuestionIndex((prevState) => prevState + 1);
                  } else if (usertype !== 0) {
                    setIsDone(true);
                  } else {
                    handleClose();
                  }
                }}
                size="large"
                color="primary"
                variant="contained"
              >
                {questionIndex + 1 < questions.length
                  ? `${usertype !== 0 ? "Save and Continue" : "Next"}`
                  : `${usertype !== 0 ? "Submit" : "Close"}`}
              </LoadingButton>
              {usertype !== 0 && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setOpenModal(true)}
                  color="info"
                >
                  Add Comment
                </Button>
              )}
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
      {usertype !== 0 && (
        <CommentModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          handleFeedbackChanged={handleFeedbackChanged}
          handleFeedback={handleFeedback}
          student={answers.studentName || ""}
          feedback={feedback}
          loading={isFeedbackLoading}
        />
      )}
    </>
  );
}
