import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// routes
import { PATH_DASHBOARD } from "../../routes/paths";

export const studentSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "menu",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.root,
        icon: <AppsOutlinedIcon />,
      },
      {
        title: "My Profile",
        path: PATH_DASHBOARD.general.profile,
        icon: <PersonPinIcon />,
        permission: "",
      },
      {
        title: "Courses",
        path: PATH_DASHBOARD.general.courses,
        icon: <LibraryBooksIcon />,
      },
      {
        title: "Lectures",
        path: PATH_DASHBOARD.general.lectures,
        icon: <MenuBookOutlinedIcon />,
      },
      {
        title: "Tests / Exams",
        path: PATH_DASHBOARD.general.testExams,
        icon: <MenuBookOutlinedIcon />,
      },
      {
        title: "Grades",
        path: PATH_DASHBOARD.general.grades,
        icon: <SignalCellularAltOutlinedIcon />,
        permission: "view_grades",
      },
      {
        title: "Assignments",
        path: PATH_DASHBOARD.general.assignments,
        icon: <AssignmentIcon />,
        permission: "view_assignments",
      },
      {
        title: "Payments & Wallet",
        path: PATH_DASHBOARD.general.payments,
        icon: <CreditCardOutlinedIcon />,
      },
      {
        title: "Payments History",
        path: PATH_DASHBOARD.general.paymentHistory,
        icon: <CreditCardOutlinedIcon />,
      },
      {
        title: "Calendar",
        path: PATH_DASHBOARD.general.calendar,
        icon: <CalendarTodayOutlinedIcon />,
      },
      {
        title: "Notice Board",
        path: PATH_DASHBOARD.general.noticeBoard,
        icon: <CircleNotificationsIcon />,
        permission: "",
      },
    ],
  },
];

export const adminSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "administrative",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.root,
        icon: <AppsOutlinedIcon />,
        permission: "view_dashboard_analytics",
      },
      {
        title: "My Profile",
        path: PATH_DASHBOARD.general.profile,
        icon: <PersonPinIcon />,
        permission: "",
      },
      {
        title: "Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsOutlinedIcon />,
        permission: "view_and_update_system_settings",
      },
      {
        title: "Administrators",
        path: PATH_DASHBOARD.general.admins,
        icon: <AccountCircleOutlinedIcon />,
        permission: "view_administrators",
      },
      {
        title: "Administrative Roles",
        path: PATH_DASHBOARD.general.roles,
        icon: <GroupsOutlinedIcon />,
        permission: "view_administrative_roles",
      },
      {
        title: "Inbox",
        path: PATH_DASHBOARD.general.inbox,
        icon: <ForwardToInboxOutlinedIcon />,
        permission: "view_and_send_messages",
      },
      {
        title: "Mailbox",
        path: PATH_DASHBOARD.general.mailbox,
        icon: <MailOutlineIcon />,
        permission: "view_and_send_messages",
      },
    ],
  },
  {
    subheader: "academic",
    items: [
      {
        title: "Students",
        path: PATH_DASHBOARD.general.students,
        icon: <PeopleOutlineOutlinedIcon />,
        permission: "view_students",
      },
      {
        title: "Lecturers",
        path: PATH_DASHBOARD.general.lecturers,
        icon: <PeopleAltOutlinedIcon />,
        permission: "view_lecturers",
      },
      {
        title: "Faculties",
        path: PATH_DASHBOARD.general.faculties,
        icon: <AccountBalanceOutlinedIcon />,
        permission: "view_faculties",
      },
      {
        title: "Departments",
        path: PATH_DASHBOARD.general.departments,
        icon: <WorkOutlineOutlinedIcon />,
        permission: "view_departments",
      },
      {
        title: "Courses",
        path: PATH_DASHBOARD.general.courses,
        icon: <LibraryBooksIcon />,
        permission: "view_courses",
      },
      {
        title: "Lectures",
        path: PATH_DASHBOARD.general.lectures,
        icon: <MenuBookOutlinedIcon />,
        permission: "view_lectures",
      },
      {
        title: "Tests / Exams",
        path: PATH_DASHBOARD.general.testExams,
        icon: <MenuBookOutlinedIcon />,
        permission: "view_tests_and_exams",
      },
      {
        title: "Grades",
        path: PATH_DASHBOARD.general.grades,
        icon: <SignalCellularAltOutlinedIcon />,
        permission: "view_grades",
      },
      {
        title: "Assignments",
        path: PATH_DASHBOARD.general.assignments,
        icon: <AssignmentIcon />,
        permission: "view_assignments",
      },
      {
        title: "Calendar",
        path: PATH_DASHBOARD.general.calendar,
        icon: <CalendarTodayIcon />,
        permission: "view_calendar",
      },
    ],
  },
  {
    subheader: "accounting",
    items: [
      {
        title: "Payments",
        path: PATH_DASHBOARD.general.payments,
        icon: <CreditCardOutlinedIcon />,
        permission: "view_payments",
      },
      {
        title: "Payments History",
        path: PATH_DASHBOARD.general.paymentHistory,
        icon: <ReceiptIcon />,
        permission: "view_payments_history",
      },
      {
        title: "Payment Analytics",
        path: PATH_DASHBOARD.general.paymentAnalytics,
        icon: <AnalyticsOutlinedIcon />,
        permission: "view_payment_analytics",
      },
    ],
  },
  {
    subheader: "miscellanous",
    items: [
      {
        title: "Notice Board",
        path: PATH_DASHBOARD.general.noticeBoard,
        icon: <CircleNotificationsIcon />,
        permission: "",
      },
      {
        title: "Data Import",
        path: PATH_DASHBOARD.general.import,
        icon: <FileUploadIcon />,
        permission: "",
      },
      {
        title: "Data Export",
        path: PATH_DASHBOARD.general.export,
        icon: <FileDownloadIcon />,
        permission: "",
      },
    ],
  },
];

export const facultySidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "administrative",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.root,
        icon: <AppsOutlinedIcon />,
        permission: "",
      },
      {
        title: "My Profile",
        path: PATH_DASHBOARD.general.profile,
        icon: <PersonPinIcon />,
        permission: "",
      },
      {
        title: "Inbox",
        path: PATH_DASHBOARD.general.inbox,
        icon: <ForwardToInboxOutlinedIcon />,
        permission: "",
      },
    ],
  },
  {
    subheader: "academic",
    items: [
      {
        title: "Students",
        path: PATH_DASHBOARD.general.students,
        icon: <PeopleOutlineOutlinedIcon />,
        permission: "",
      },
      {
        title: "Courses",
        path: PATH_DASHBOARD.general.courses,
        icon: <LibraryBooksIcon />,
        permission: "",
      },
      {
        title: "Lectures",
        path: PATH_DASHBOARD.general.lectures,
        icon: <MenuBookOutlinedIcon />,
        permission: "view_lectures",
      },
      {
        title: "Tests / Exams",
        path: PATH_DASHBOARD.general.testExams,
        icon: <MenuBookOutlinedIcon />,
        permission: "view_tests_and_exams",
      },
      {
        title: "Grades",
        path: PATH_DASHBOARD.general.grades,
        icon: <SignalCellularAltOutlinedIcon />,
        permission: "view_grades",
      },
      {
        title: "Assignments",
        path: PATH_DASHBOARD.general.assignments,
        icon: <AssignmentIcon />,
        permission: "view_assignments",
      },
    ],
  },
  {
    subheader: "others",
    items: [
      {
        title: "Payment History",
        path: PATH_DASHBOARD.general.paymentHistory,
        icon: <CreditCardOutlinedIcon />,
        permission: "view_payments",
      },
      {
        title: "Calendar & Events",
        path: PATH_DASHBOARD.general.calendar,
        icon: <CalendarTodayOutlinedIcon />,
        permission: "",
      },
      {
        title: "Notice Board",
        path: PATH_DASHBOARD.general.noticeBoard,
        icon: <CircleNotificationsIcon />,
        permission: "",
      },
    ],
  },
];
