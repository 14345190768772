import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useState } from "react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Stack,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import {
  ACCESS_LEVELS,
  PAYMENT_TYPES,
  PAYMENT_METHODS,
} from "../../../utils/apis";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const SelectStyle = styled(TextField)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
  selected: PropTypes.number,
};
function DeleteModal({ open, handleClose, handleDelete, loading, selected }) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "error.main" }}>
        Delete {selected} {selected > 1 ? "items" : "item"}{" "}
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {selected}{" "}
          {selected > 1 ? "items" : "item"}? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            onClick={handleDelete}
            variant="contained"
            loading={loading}
            color="error"
          >
            Yes, Proceed
          </LoadingButton>
          <Button fullWidth onClick={handleClose} color="inherit">
            No, Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

ListToolbar.propTypes = {
  status: PropTypes.string,
  setStatus: PropTypes.func,
  numSelected: PropTypes.number,
  type: PropTypes.string,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleDelete: PropTypes.func,
  setLevel: PropTypes.func,
  level: PropTypes.string,
  typePayment: PropTypes.string,
  setType: PropTypes.func,
  method: PropTypes.string,
  setMethod: PropTypes.func,
  onPaymentSelected: PropTypes.func,
  userType: PropTypes.string,
  setUserType: PropTypes.func,
};
const STATUSES = [
  {
    type: "role",
    statuses: [
      { label: "Active", value: "1" },
      { label: "Suspended", value: "0" },
    ],
  },
  {
    type: "payment",
    statuses: [
      { label: "Published", value: "1" },
      { label: "Draft", value: "0" },
      { label: "Cancelled", value: "2" },
    ],
  },
  {
    type: "notice",
    statuses: [
      { label: "Published", value: "1" },
      { label: "Draft", value: "0" },
    ],
  },
  {
    type: "payment_history",
    statuses: [
      { label: "Paid", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    type: "export",
    statuses: [
      { label: "Exported", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
  {
    type: "grade",
    statuses: [
      { label: "Graded", value: "1" },
      { label: "Pending", value: "0" },
    ],
  },
];
export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  setStatus,
  status,
  handleDelete,
  type,
  userType,
  setUserType,
  setLevel,
  level,
  typePayment,
  setType,
  method,
  setMethod,
  onPaymentSelected,
}) {
  const { usertype, user } = useAuth();
  const theme = useTheme();
  let LEVELS = [];
  if (type === "notice" && usertype !== 0) {
    const { programmes } = user.school;
    LEVELS = [
      { label: "All", value: "all" },
      { label: "100", value: "100" },
      { label: "200", value: "200" },
      { label: "300", value: "300" },
      { label: "400", value: "400" },
      { label: "500", value: "500" },
      { label: "600", value: "600" },
    ];
    if (programmes.split(",").includes("masters")) {
      LEVELS.push({ label: "Masters", value: "masters" });
    }
    if (programmes.split(",").includes("doctoral")) {
      LEVELS.push({ label: "Doctoral", value: "doctoral" });
    }
  }
  const isLight = theme.palette.mode === "light";
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleLevelChange = (event) => setLevel(event.target.value);
  const STATUS_OPTIONS =
    usertype === 0 && type === "payment"
      ? STATUSES.filter((status) => status.type === "payment_history")[0]
          .statuses
      : STATUSES.filter((status) => status.type === type)[0].statuses;

  const [isSubmitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const onDelete = () => {
    setSubmitting(true);
    handleDelete();
    setSubmitting(false);
    handleClose();
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? "primary.main" : "text.primary",
          bgcolor: isLight ? "primary.lighter" : "primary.dark",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        type !== "export" && (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
        )
      )}

      {numSelected > 0 ? (
        (usertype === 0 && type === "payment" && (
          <Tooltip title="Pay Now">
            <IconButton
              onClick={() => {
                onPaymentSelected();
              }}
            >
              <CreditScoreIcon width={24} height={24} />
            </IconButton>
          </Tooltip>
        )) || (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <Icon icon={trash2Fill} />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <Box sx={{ display: "flex" }}>
          {type === "payment_history" && numSelected === 0 && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SelectStyle
                select
                label="Method"
                value={method}
                onChange={(event) => {
                  setMethod(event.target.value);
                }}
              >
                <MenuItem key="all" value="">
                  All
                </MenuItem>
                {PAYMENT_METHODS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectStyle>
            </Box>
          )}
          {type === "payment_history" && numSelected === 0 && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SelectStyle
                sx={{ ml: 2 }}
                select
                label="Payment Type"
                value={typePayment}
                onChange={(event) => {
                  setType(event.target.value);
                }}
              >
                <MenuItem key="all" value="">
                  All
                </MenuItem>
                {usertype !== 0 &&
                  PAYMENT_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                {usertype === 0 &&
                  PAYMENT_TYPES.filter((type) => type.value !== "2").map(
                    (option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
              </SelectStyle>
            </Box>
          )}
          {type === "payment" && usertype === 2 && numSelected === 0 && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SelectStyle
                select
                label="Level Assigned"
                value={level}
                onChange={handleLevelChange}
              >
                <MenuItem key="all" value="">
                  All
                </MenuItem>
                {ACCESS_LEVELS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectStyle>
            </Box>
          )}
          {type === "notice" && usertype === 2 && numSelected === 0 && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SelectStyle
                select
                label="Recipient"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <MenuItem key="all" value="">
                  All
                </MenuItem>
                {ACCESS_LEVELS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectStyle>
            </Box>
          )}
          {type === "notice" && usertype !== 0 && numSelected === 0 && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SelectStyle
                select
                sx={{ ml: 2 }}
                label="Level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              >
                {LEVELS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectStyle>
            </Box>
          )}
          {STATUS_OPTIONS.length > 0 && usertype !== 0 && (
            <SelectStyle
              sx={{ ml: 2 }}
              select
              label="Status"
              value={status}
              onChange={handleStatusChange}
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {STATUS_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectStyle>
          )}
        </Box>
      )}
      <DeleteModal
        open={open}
        handleClose={handleClose}
        handleDelete={onDelete}
        loading={isSubmitting}
        selected={numSelected}
      />
    </RootStyle>
  );
}
