import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Typography, Paper, Link } from "@mui/material";
// utils
import { fData } from "../../utils/formatNumber";
import { ASSETS_PATH } from "../../utils/apis";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  height: "40px",
  margin: "auto",
  borderRadius: 5,
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "0px",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadInput.propTypes = {
  error: PropTypes.bool,
  isUploading: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  caption: PropTypes.node,
  assetLink: PropTypes.string,
  sx: PropTypes.object,
};

ShowRejectionItems.propTypes = {
  fileRejections: PropTypes.any,
};

function ShowRejectionItems({ fileRejections }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        my: 2,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}

export default function UploadInput({
  error,
  isUploading,
  file,
  caption,
  sx,
  assetLink,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <RootStyle sx={sx}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(isUploading && {
            color: "warning.main",
            borderColor: "warning.light",
            bgcolor: "warning.lighter",
          }),
          ...(!(isDragReject || error) &&
            file && {
              color: "success.main",
              borderColor: "success.light",
              bgcolor: "success.lighter",
            }),
        }}
      >
        <input {...getInputProps()} />
        <PlaceholderStyle className="placeholder">
          <FileUploadIcon sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography variant="caption">
            {file
              ? `${file} uploaded!`
              : (isUploading && "File uploading...") || "Click to upload file"}
          </Typography>
        </PlaceholderStyle>
      </DropZoneStyle>
      {(file && (
        <Link
          target="_blank"
          rel="noopener"
          sx={{
            p: 2,
            display: "block",
            textAlign: "center",
            color: "error.main",
          }}
          href={`${assetLink || ASSETS_PATH.materials}${file}`}
          variant="caption"
        >
          View file
        </Link>
      )) || (
        <Typography
          sx={{
            p: 2,
            display: "block",
            textAlign: "center",
            color: "error.main",
          }}
          variant="caption"
        >
          {caption}
        </Typography>
      )}
      {fileRejections.length > 0 && (
        <ShowRejectionItems fileRejections={fileRejections} />
      )}
    </RootStyle>
  );
}
