import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeConfig>
        <ThemePrimaryColor>
          <NotistackProvider>
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemePrimaryColor>
      </ThemeConfig>
    </LocalizationProvider>
  );
}
