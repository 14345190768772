import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

// ----------------------------------------------------------------------
DepartmentUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function DepartmentUpdate({ myProfile, uid, fetch }) {
  const { updateDepartment, getLecturers, getFaculties, user, usertype } =
    useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_department");
  const isMountedRef = useIsMountedRef();
  const [FACULTIES, setFACULTIES] = useState([]);
  const [LECTURERS, setLECTURERS] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function get() {
      setLECTURERS(await getLecturers());
      setFACULTIES(await getFaculties());
    }
    get();
  }, [getLecturers, getFaculties]);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    faculty: Yup.object().required("Faculty is required"),
    email: Yup.string().email("Email address must be valid."),
  });

  const formik = useFormik({
    initialValues: {
      name: myProfile.name,
      email: myProfile.email,
      location: myProfile.location,
      dean: myProfile.deanData,
      faculty: myProfile.facultyData,
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateDepartment(values, uid);
        enqueueSnackbar("Department's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Account Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}

              <TextField
                fullWidth
                autoComplete="username"
                label="Name"
                disabled={!hasPermission}
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="email"
                  disabled={!hasPermission}
                  label="Contact Email Address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  label="Location"
                  disabled={!hasPermission}
                  {...getFieldProps("location")}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  options={FACULTIES}
                  {...getFieldProps("faculty")}
                  name="faculty"
                  disabled={!hasPermission}
                  value={values.faculty}
                  onChange={(event, newValue) => {
                    setFieldValue("faculty", newValue);
                  }}
                  getOptionLabel={(option) => (option && option.name) || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Faculty"
                      margin="none"
                      error={Boolean(touched.faculty && errors.faculty)}
                      helperText={touched.faculty && errors.faculty}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={LECTURERS}
                  disabled={!hasPermission}
                  {...getFieldProps("dean")}
                  name="dean"
                  value={values.dean}
                  onChange={(event, newValue) => {
                    setFieldValue("dean", newValue);
                  }}
                  getOptionLabel={(option) =>
                    (option.fname &&
                      `${option.title} ${option.fname} ${option.lname}`) ||
                    ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="HOD of Department"
                      margin="none"
                      error={Boolean(touched.dean && errors.dean)}
                      helperText={touched.dean && errors.dean}
                    />
                  )}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
