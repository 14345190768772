import PropTypes from "prop-types";
import { DocumentViewer } from "react-documents";
// material
import { Box, Card, Button, Typography } from "@mui/material";
import { ASSETS_PATH, MATERIAL_TYPES } from "../../../utils/apis";
import { ShowError } from "../../../utils/loader";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

SingleCard.propTypes = {
  material: PropTypes.object,
};

function SingleCard({ material }) {
  const { type, value } = material;
  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 3 }}>
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {value}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {MATERIAL_TYPES.filter((typ) => typ.value === type)[0].label}
          </Typography>
        </Box>
      </Box>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        target="_blank"
        href={type === "link" ? value : `${ASSETS_PATH.materials}${value}`}
      >
        View
      </Button>
    </Card>
  );
}

LectureNote.propTypes = {
  url: PropTypes.string.isRequired,
};

export default function LectureNote({ url }) {
  const { usertype } = useAuth();
  return (
    <Box sx={{ mt: usertype === 0 ? 3 : 5 }}>
      <Typography variant={usertype === 0 ? "h6" : "h4"} sx={{ mb: 3 }}>
        Note:
      </Typography>
      {url ? (
        <Box
          sx={{
            border: "1px solid",
            minHeight: 500,
            borderColor: "primary.main",
          }}
        >
          <DocumentViewer
            style={{ minHeight: "500px", width: "100%" }}
            url={`${ASSETS_PATH.notes}${url}`}
          />
        </Box>
      ) : (
        <ShowError
          isNotShowImage
          message={
            new Error("This lecture does not have any material at the moment!")
          }
        />
      )}
    </Box>
  );
}
