import PropTypes from "prop-types";
import * as Yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { STATES, LGAS } from "../../../utils/apis";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

// ----------------------------------------------------------------------
StudentAddressUpdate.propTypes = {
  address: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function StudentAddressUpdate({ address, uid, fetch }) {
  const { updateStudentAddress, user, usertype } = useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_student");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [CURRENT_LGAS, setCurrentLGAS] = useState(
    address.state
      ? LGAS.filter((obj) => obj.state === address.state)[0].lgas
      : []
  );

  const StudentSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
    state: Yup.string().required("State is required"),
    lga: Yup.string().required("Local government area is required"),
  });

  const formik = useFormik({
    initialValues: {
      address: address.address || "",
      state: address.state || "",
      lga: address.lga || "",
    },
    validationSchema: StudentSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateStudentAddress(values, uid);
        enqueueSnackbar("Student's address has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  const setFieldLGA = (state) => {
    const lgas = LGAS.filter((obj) => obj.state === state);
    if (lgas.length > 0 && lgas[0].lgas.length > 0) {
      setCurrentLGAS(lgas[0].lgas);
    }
    setFieldValue("lga", "");
  };

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Address Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}

              <Autocomplete
                fullWidth
                options={STATES}
                {...getFieldProps("state")}
                name="state"
                value={values.state}
                disabled={!hasPermission}
                onChange={(event, newValue) => {
                  setFieldValue("state", newValue);
                  setFieldLGA(newValue);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    margin="none"
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                options={CURRENT_LGAS}
                disabled={!hasPermission}
                value={values.lga}
                name="lga"
                onChange={(event, newValue) => {
                  setFieldValue("lga", newValue);
                }}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Local Government Area"
                    margin="none"
                    error={Boolean(touched.lga && errors.lga)}
                    helperText={touched.lga && errors.lga}
                  />
                )}
              />
              <TextField
                fullWidth
                autoComplete="username"
                disabled={!hasPermission}
                label="Address"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
