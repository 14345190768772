import Slider from "react-slick";
import PropTypes from "prop-types";
import { useState, useRef } from "react";
// material
import { useTheme } from "@mui/material/styles";
import { Box, Typography, CardContent } from "@mui/material";
import Image from "./Image";

const CAROUSELS = [
  {
    title: "unique content",
    description: "Plagiarism Checker",
    image: "/static/plagiarism_checker.svg",
    isCenter: true,
  },
  {
    title: "premium security",
    description: "Cloud-based Storage",
    image: "/static/cloud_services.svg",
    isCenter: true,
  },
  {
    title: "All-in-One App",
    description: "for effective learning",
    image: "/logo/logo-slider.png",
    isCenter: true,
  },
];

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object,
};

function CarouselItem({ item }) {
  const { image, title, description, isCenter } = item;
  return (
    <>
      {image !== "" ? (
        <Image
          src={image}
          alt={title}
          sx={{
            width: image === "/logo/logo-slider.png" ? "auto" : 341,
            margin: "0 auto",
          }}
          width={image === "/logo/logo-slider.png" ? "auto" : 341}
          height={180}
          isCenter={isCenter}
        />
      ) : (
        <Image
          src="/static/transparent.svg"
          alt={title}
          width="auto"
          height="180"
          isCenter={isCenter}
        />
      )}
      <CardContent sx={{ textAlign: "center", backgroundColor: "transparent" }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            color: "sliderText",
            marginBottom: "0",
            textAlign: "left",
            marginLeft: "4rem",
            lineHeight: "0.4",
          }}
        >
          {title}
        </Typography>
        <Typography variant="h3" sx={{ color: "common.white" }}>
          {description}
        </Typography>
      </CardContent>
    </>
  );
}

export default function AuthCarousel() {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(2);

  const settings = {
    dots: true,
    arrows: false,
    dotsClass: "slick-dots",
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    fade: Boolean(theme.direction !== "rtl"),
    rtl: Boolean(theme.direction === "rtl"),
    beforeChange: (current, next) => setCurrentIndex(next),
  };
  return (
    <Box sx={{ backgroundColor: "transparent" }}>
      <Slider ref={carouselRef} {...settings}>
        {CAROUSELS.map((item) => (
          <CarouselItem key={item.title} item={item} />
        ))}
      </Slider>
    </Box>
  );
}
