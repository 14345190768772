import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_PATH } from '../../utils/apis';

const initialState = {
    isLoading: false,
    isWithdrawSuccess: false,
    isTopupSuccess: false,
    isTopupAmount: 0,
    error: false,
    payments: [],
    totalTypes: 0,
    wallet: {},
    filters: {
        type: '',
        status: '',
        search: ''
    },
};

const slice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getPaymentSuccess(state, action) {
            state.isLoading = false;
            state.payments = action.payload.data;
            state.total = action.payload.total;
        },
        getWithdrawSuccess(state) {
            state.isLoading = false;
            state.isWithdrawSuccess = true;
        },
        //  SORT & FILTER PRODUCTS
        sortByWallet(state, action) {
            state.sortBy = action.payload;
        },

        filterWallet(state, action) {
            state.filters.category = action.payload.category;
        },
    }
});

// Reducer
export default slice.reducer;

export const {
    filterWallet,
    sortByWallet,
} = slice.actions;

export function getPayments(history, type, paymentType, method, stat, sortBy, page, perpage) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_PATH.payments, { history, type, paymentType, method, status: stat, sortBy, page, perpage });
            const { status, data, total } = response.data;
            if (status === "success") {
                dispatch(slice.actions.getPaymentSuccess({ data, total }));
            } else {
                dispatch(slice.actions.hasError(new Error("We cannot fetch your payment history at the moment! Please check back later.")));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addWithdrawal(amount, type) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const accessToken = window.localStorage.getItem('accessToken');
            const response = await axios.post(API_PATH.payments, { withdraw: accessToken, amount, type });
            const { status, data } = response.data;
            if (status === "success") {
                dispatch(slice.actions.getWithdrawSuccess());
            } else if (status === "insufficient_balance") {
                dispatch(slice.actions.hasError(new Error("You do not have enough balance in your account to procees this withdrawal!")));
            } else if (status === "wallet_unvailable") {
                dispatch(slice.actions.hasError(new Error("You cannot request withdrawal at the moment! Please try again later.")));
            } else if (status === "withdrawal_unvailable") {
                dispatch(slice.actions.hasError(new Error(`You cannot request withdrawal at the moment! Withdrawal is only available between ${data.start_time} and ${data.end_time} daily.`)));
            } else {
                dispatch(slice.actions.hasError(new Error("We cannot process your withdrawal at the moment! Please try again later")));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
