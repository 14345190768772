import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import { adminPages, facultyPages, studentPages } from "./paths";

// ----------------------------------------------------------------------
const Loadable = (Component) =>
  // eslint-disable-next-line func-names
  function (props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const userType = parseInt(window.localStorage.getItem("userType"), 10);
    let dashboardPages = [];
    switch (userType) {
      case 0:
        // student
        dashboardPages = studentPages;
        break;
      case 1:
        // faculty
        dashboardPages = facultyPages;
        break;
      case 2:
        // admin
        dashboardPages = adminPages;
        break;
      default:
    }
    const isDashboard = dashboardPages.includes(pathname);

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isDashboard && {
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: "fixed",
              }),
            }}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

export default function Router() {
  const userType = parseInt(window.localStorage.getItem("userType"), 10);
  let dashboard = (
    <AuthGuard>
      {" "}
      <DashboardLayout />{" "}
    </AuthGuard>
  );
  switch (userType) {
    case 0:
      // student
      dashboard = <StudentDashboardLayout />;
      break;
    case 1:
      // faculty
      dashboard = <FacultyDashboardLayout />;
      break;
    case 2:
      // admin
      dashboard = (
        <RoleBasedGuard>
          <AdminDashboardLayout />
        </RoleBasedGuard>
      );
      break;
    default:
  }
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />{" "}
        </AuthGuard>
      ),
      children: [
        { path: "", element: dashboard },
        { path: "profile", element: <Profile /> },
        {
          path: "settings",
          element: (
            <RoleBasedGuard>
              <Settings />
            </RoleBasedGuard>
          ),
        },
        {
          path: "calendar",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Calendar />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddEvent />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "notice-board",
          children: [
            {
              path: "",
              element: <NoticeBoard />,
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddNoticeBoard />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":nid",
              element: (
                <RoleBasedGuard>
                  <AddNoticeBoard />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "export",
          element: (
            <RoleBasedGuard>
              <Export />
            </RoleBasedGuard>
          ),
        },
        {
          path: "import",
          element: (
            <RoleBasedGuard>
              <Import />
            </RoleBasedGuard>
          ),
        },
        {
          path: "payment-history",
          element: (
            <RoleBasedGuard>
              <PaymentHistory />
            </RoleBasedGuard>
          ),
        },
        {
          path: "payment-analytics",
          element: (
            <RoleBasedGuard>
              <PaymentAnalytics />
            </RoleBasedGuard>
          ),
        },
        {
          path: "grades",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Grades />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddGrade />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "administrators",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Admins />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddAdmin />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "students",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Students />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddStudent />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "lecturers",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Lecturers />
                </RoleBasedGuard>
              ),
            },

            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddLecturer />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "faculties",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Faculties />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddFaculty />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "departments",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Departments />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddDepartment />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "courses",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Courses />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddCourse />
                </RoleBasedGuard>
              ),
            },
            {
              path: "register",
              element: (
                <RoleBasedGuard>
                  <CourseRegistration />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "lectures",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Lectures />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":course",
              element: (
                <RoleBasedGuard>
                  <Lectures />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":course/:courseName",
              element: (
                <RoleBasedGuard>
                  <Lectures />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddLecture />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "test-exams",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <TestExams />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddTestExams />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "assignments",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Assignments />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddAssignment />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "roles",
          element: (
            <RoleBasedGuard>
              <Roles />
            </RoleBasedGuard>
          ),
        },
        {
          path: "inbox",
          element: (
            <RoleBasedGuard>
              <Inbox />{" "}
            </RoleBasedGuard>
          ),
        },
        {
          path: "mailbox",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Mailbox />{" "}
                </RoleBasedGuard>
              ),
            },
            {
              path: "send/:email",
              element: (
                <RoleBasedGuard>
                  <Mailbox />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":current",
              element: (
                <RoleBasedGuard>
                  <Mailbox />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":current/:id/:name",
              element: (
                <RoleBasedGuard>
                  <Mailbox />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "payments",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Payments />
                </RoleBasedGuard>
              ),
            },
            {
              path: "add",
              element: (
                <RoleBasedGuard>
                  <AddPayment />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "administrator",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Admin />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Admin />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "role",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Role />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":rid/:name",
              element: (
                <RoleBasedGuard>
                  <Role />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "student",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Student />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Student />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "lecturer",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Lecturer />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Lecturer />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "faculty",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Faculty />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Faculty />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "department",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Department />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Department />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "course",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Course />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Course />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "lecture",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Lecture />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Lecture />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "test",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Test />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:title",
              element: (
                <RoleBasedGuard>
                  <Test />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "assignment",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Assignment />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:title",
              element: (
                <RoleBasedGuard>
                  <Assignment />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "grade",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Grade />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:title",
              element: (
                <RoleBasedGuard>
                  <Grade />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:title/grade",
              element: (
                <RoleBasedGuard>
                  <EditGrade />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:title/test",
              element: (
                <RoleBasedGuard>
                  <Grade />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "payment",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard>
                  <Payment />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":uid/:name",
              element: (
                <RoleBasedGuard>
                  <Payment />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "500", element: <ErrorOccured /> },
        { path: "domain-error", element: <DomainError /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "admin-login",
          element: (
            <AuthGuard>
              <AdminLogin />
            </AuthGuard>
          ),
        },
        {
          path: "take-test",
          children: [
            {
              path: "",
              element: (
                <AuthGuard>
                  <TakeTest />
                </AuthGuard>
              ),
            },
            {
              path: ":uid/:title",
              element: (
                <AuthGuard>
                  <TakeTest />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "take-assignment",
          children: [
            {
              path: "",
              element: (
                <AuthGuard>
                  <TakeAssignment />
                </AuthGuard>
              ),
            },
            {
              path: ":uid/:title",
              element: (
                <AuthGuard>
                  <TakeAssignment />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "live",
          children: [
            {
              path: "",
              element: (
                <AuthGuard>
                  <TakeLecture />
                </AuthGuard>
              ),
            },
            {
              path: ":uid/:title",
              element: (
                <AuthGuard>
                  <TakeLecture />
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: "student-login",
          element: (
            <AuthGuard>
              <StudentLogin />
            </AuthGuard>
          ),
        },
        {
          path: "faculty-login",
          element: (
            <AuthGuard>
              <FacultyLogin />
            </AuthGuard>
          ),
        },
        {
          path: "set-password",
          element: (
            <AuthGuard>
              <SetPassword />
            </AuthGuard>
          ),
        },
        {
          path: "signup",
          element: (
            <GuestGuard>
              <Signup />
            </GuestGuard>
          ),
        },
        {
          path: "welcome",
          element: (
            <AuthGuard>
              <Welcome />
            </AuthGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        {
          path: "forgot-password",
          children: [
            {
              path: "",
              element: <ForgotPassword />,
            },
            {
              path: ":type",
              element: <ForgotPassword />,
            },
          ],
        },
        {
          path: "verify",
          element: (
            <AuthGuard>
              <VerifyCode />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// admin dashboard
const StudentDashboardLayout = Loadable(
  lazy(() => import("../pages/dashboard/StudentDashboard"))
);
const FacultyDashboardLayout = Loadable(
  lazy(() => import("../pages/dashboard/FacultyDashboard"))
);
const AdminDashboardLayout = Loadable(
  lazy(() => import("../pages/dashboard/AdminDashboard"))
);

// admin pages
const Profile = Loadable(lazy(() => import("../pages/dashboard/Profile")));
const Inbox = Loadable(lazy(() => import("../pages/dashboard/Inbox")));
const Mailbox = Loadable(lazy(() => import("../pages/dashboard/Mailbox")));
const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));
const Students = Loadable(lazy(() => import("../pages/dashboard/Students")));
const Admins = Loadable(lazy(() => import("../pages/dashboard/Admins")));
const Lecturers = Loadable(lazy(() => import("../pages/dashboard/Lecturers")));
const Faculties = Loadable(lazy(() => import("../pages/dashboard/Faculties")));
const Departments = Loadable(
  lazy(() => import("../pages/dashboard/Departments"))
);
const Courses = Loadable(lazy(() => import("../pages/dashboard/Courses")));
const Grades = Loadable(lazy(() => import("../pages/dashboard/Grades")));
const AddGrade = Loadable(lazy(() => import("../pages/dashboard/AddGrade")));
const EditGrade = Loadable(lazy(() => import("../pages/dashboard/EditGrade")));
const Assignments = Loadable(
  lazy(() => import("../pages/dashboard/Assignments"))
);
const Lectures = Loadable(lazy(() => import("../pages/dashboard/Lectures")));
const Roles = Loadable(lazy(() => import("../pages/dashboard/Roles")));
const Payments = Loadable(lazy(() => import("../pages/dashboard/Payments")));
const PaymentHistory = Loadable(
  lazy(() => import("../pages/dashboard/PaymentHistory"))
);
const PaymentAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/PaymentAnalytics"))
);
const NoticeBoard = Loadable(
  lazy(() => import("../pages/dashboard/NoticeBoard"))
);
const AddNoticeBoard = Loadable(
  lazy(() => import("../pages/dashboard/AddNoticeBoard"))
);
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Export = Loadable(lazy(() => import("../pages/dashboard/Export")));
const Import = Loadable(lazy(() => import("../pages/dashboard/Import")));
const TestExams = Loadable(lazy(() => import("../pages/dashboard/TestExams")));
const Grade = Loadable(lazy(() => import("../pages/dashboard/Grade")));

const AddAdmin = Loadable(lazy(() => import("../pages/dashboard/AddAdmin")));
const AddEvent = Loadable(lazy(() => import("../pages/dashboard/AddEvent")));
const AddStudent = Loadable(
  lazy(() => import("../pages/dashboard/AddStudent"))
);
const AddLecturer = Loadable(
  lazy(() => import("../pages/dashboard/AddLecturer"))
);
const AddFaculty = Loadable(
  lazy(() => import("../pages/dashboard/AddFaculty"))
);
const AddDepartment = Loadable(
  lazy(() => import("../pages/dashboard/AddDepartment"))
);
const AddCourse = Loadable(lazy(() => import("../pages/dashboard/AddCourse")));
const CourseRegistration = Loadable(
  lazy(() => import("../pages/dashboard/RegisterCourse"))
);
const AddPayment = Loadable(
  lazy(() => import("../pages/dashboard/AddPayment"))
);
const AddLecture = Loadable(
  lazy(() => import("../pages/dashboard/AddLecture"))
);
const AddTestExams = Loadable(
  lazy(() => import("../pages/dashboard/AddTestExams"))
);
const AddAssignment = Loadable(
  lazy(() => import("../pages/dashboard/AddAssignment"))
);
const TakeTest = Loadable(lazy(() => import("../pages/dashboard/TakeTest")));
const TakeAssignment = Loadable(
  lazy(() => import("../pages/dashboard/TakeAssignment"))
);
const TakeLecture = Loadable(
  lazy(() => import("../pages/dashboard/TakeLecture"))
);

// admin sub pages
const Admin = Loadable(lazy(() => import("../pages/dashboard/Admin")));
const Role = Loadable(lazy(() => import("../pages/dashboard/Role")));
const Student = Loadable(lazy(() => import("../pages/dashboard/Student")));
const Lecturer = Loadable(lazy(() => import("../pages/dashboard/Lecturer")));
const Faculty = Loadable(lazy(() => import("../pages/dashboard/Faculty")));
const Department = Loadable(
  lazy(() => import("../pages/dashboard/Department"))
);
const Course = Loadable(lazy(() => import("../pages/dashboard/Course")));
const Payment = Loadable(lazy(() => import("../pages/dashboard/Payment")));
const Lecture = Loadable(lazy(() => import("../pages/dashboard/Lecture")));
const Test = Loadable(lazy(() => import("../pages/dashboard/Test")));
const Assignment = Loadable(
  lazy(() => import("../pages/dashboard/Assignment"))
);

// error
const DomainError = Loadable(lazy(() => import("../pages/errors/DomainError")));
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));

// auth
const Welcome = Loadable(lazy(() => import("../pages/auth/Welcome")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const Signup = Loadable(lazy(() => import("../pages/auth/Signup")));
const FacultyLogin = Loadable(lazy(() => import("../pages/auth/FacultyLogin")));
const StudentLogin = Loadable(lazy(() => import("../pages/auth/StudentLogin")));
const SetPassword = Loadable(
  lazy(() => import("../pages/auth/SetNewPassword"))
);
const AdminLogin = Loadable(lazy(() => import("../pages/auth/AdminLogin")));
