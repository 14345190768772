// material
import { Box } from '@mui/material';
import Image from '../components/Image';

// ----------------------------------------------------------------------

export default function PageNotFoundIllustration({ ...other }) {
  return (
    <Box {...other}>
      <Image src="/static/errors/404.svg" alt="Page Not Found" isCenter height='300' />
    </Box>
  );
}
