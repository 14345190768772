import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { paramCase } from "change-case";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PreviewIcon from "@mui/icons-material/Preview";
import CancelIcon from "@mui/icons-material/Cancel";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
  name: PropTypes.string,
};
function DeleteModal({ open, handleClose, handleDelete, loading, name }) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "text.primary", m: 0, p: 2 }}>
        Delete {name}{" "}
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {name}? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            onClick={handleDelete}
            variant="contained"
            loading={loading}
            color="error"
          >
            Yes, Proceed
          </LoadingButton>
          <Button fullWidth onClick={handleClose} color="inherit">
            No, Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

MoreMenu.propTypes = {
  type: PropTypes.string,
  onDelete: PropTypes.func,
  onPaymentSelected: PropTypes.func,
  onOpenNotice: PropTypes.func,
  Name: PropTypes.string,
  Mid: PropTypes.string,
};

const SUBPAGES = {
  role: PATH_DASHBOARD.subpages.role,
  payment: PATH_DASHBOARD.subpages.payment,
  notice: PATH_DASHBOARD.general.noticeBoard,
};

export default function MoreMenu({
  onDelete,
  Name,
  Mid,
  type,
  onPaymentSelected,
  onOpenNotice,
}) {
  const { usertype } = useAuth();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [isSubmitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    setSubmitting(true);
    onDelete();
    setSubmitting(false);
    handleClose();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {usertype === 0 && type === "payment" && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={() => onPaymentSelected(Mid)}
          >
            <ListItemIcon>
              <CreditScoreIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Pay Now"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {!(usertype === 0 && type === "payment") && (
          <MenuItem
            component={RouterLink}
            to={
              type === "notice"
                ? `${SUBPAGES[type]}/${Mid}`
                : `${SUBPAGES[type]}/${Mid}/${paramCase(Name)}`
            }
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={type === "notice" ? "Edit" : "View/Edit"}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {usertype !== 0 && type === "notice" && (
          <MenuItem onClick={onOpenNotice} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <PreviewIcon sx={{ width: 24, height: 24 }} />
            </ListItemIcon>
            <ListItemText
              primary="View"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {!(usertype === 0 && type === "payment") && (
          <MenuItem
            onClick={() => {
              setOpen(true);
            }}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
      <DeleteModal
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        loading={isSubmitting}
        name={Name}
      />
    </>
  );
}
