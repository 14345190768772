import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Card, Alert, TextField, Stack, CardHeader, Box, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------
const GENDER = [
  { label: 'Male', value: "M" },
  { label: 'Female', value: "F" },
];
AdminUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
  isProfile: PropTypes.bool,
};
export default function AdminUpdate({ myProfile, uid, fetch, isProfile }) {
  const { updateAdmin, user } = useAuth();
  const permissions = user.user && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
  const hasPermission = permissions.includes("update_administrators");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const AdminSchema = Yup.object().shape({
    fname: Yup.string().required('First name is required'),
    lname: Yup.string().required('Last name is required'),
    gender: Yup.string().required('Gender is required'),
  });

  const formik = useFormik({
    initialValues: {
      fname: myProfile.fname,
      lname: myProfile.lname,
      phone: myProfile.phone,
      employed: myProfile.employDate,
      gender: myProfile.gender,
      salary: myProfile.salary,
      empid: myProfile.empid,
    },
    validationSchema: AdminSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateAdmin(values, uid);
        enqueueSnackbar('Admin\'s profile has been updated!', { variant: 'success' });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Account Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

              <TextField
                fullWidth
                autoComplete="username"
                label="Employee/Staff ID"
                {...getFieldProps('empid')}
                error={Boolean(touched.empid && errors.empid)}
                helperText={touched.empid && errors.empid}
                disabled={!hasPermission || isProfile}
              />
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  label="First Name"
                  {...getFieldProps('fname')}
                  error={Boolean(touched.fname && errors.fname)}
                  helperText={touched.fname && errors.fname}
                  disabled={!hasPermission}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  label="Last Name"
                  {...getFieldProps('lname')}
                  error={Boolean(touched.lname && errors.lname)}
                  helperText={touched.lname && errors.lname}
                  disabled={!hasPermission}
                />
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  type='tel'
                  label="Phone number"
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                  disabled={!hasPermission}
                />
                <TextField
                  select
                  sx={{ width: { xs: '100%', md: 500 } }}
                  label="Gender"
                  className="fix-mui-padding"
                  value={values.gender}
                  onChange={(event) => {
                    setFieldValue("gender", event.target.value);
                  }}
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                  disabled={!hasPermission}
                >
                  {GENDER.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              {!isProfile && (<Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type='date' fullWidth label="Date Employed" {...getFieldProps('employed')}
                  error={Boolean(touched.employed && errors.employed)}
                  helperText={touched.employed && errors.employed}
                  disabled={!hasPermission} />
                <TextField fullWidth label="Salary (optional)" {...getFieldProps('salary')}
                  error={Boolean(touched.salary && errors.salary)}
                  helperText={touched.salary && errors.salary}
                  disabled={!hasPermission} />
              </Stack>)}
            </Stack>
            {hasPermission && (<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
              Update
            </LoadingButton>)}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
