import useAuth from '../../hooks/useAuth';
import { ProfileAdmin, ProfileFaculty, ProfileStudent } from '../../components/_dashboard/profiles';


export default function Profile() {
    const { usertype } = useAuth();
    return (
        <>
            {usertype === 2 && <ProfileAdmin />}
            {usertype === 1 && <ProfileFaculty />}
            {usertype === 0 && <ProfileStudent />}
        </>
    );
}
