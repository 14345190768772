import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import { useEffect, useState } from "react";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

// ----------------------------------------------------------------------
const GENDER = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
LecturerUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function LecturerUpdate({ myProfile, uid, fetch }) {
  const { updateLecturer, user, getFaculties, getDepartments } = useAuth();
  const permissions =
    user.user &&
    parseInt(user.user.usertype, 10) === 2 &&
    user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_lecturer");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [FACULTIES, setFACULTIES] = useState([]);
  const [DEPARTMENTS, setDEPARTMENTS] = useState([]);

  const Schema = Yup.object().shape({
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    gender: Yup.string().required("Gender is required"),
    faculty: Yup.object().required("Faculty is required!"),
    department: Yup.object().required("Department is required!"),
  });

  const formik = useFormik({
    initialValues: {
      fname: myProfile.fname,
      lname: myProfile.lname,
      phone: myProfile.phone,
      employed: myProfile.employDate,
      gender: myProfile.gender,
      empid: myProfile.empid,
      salary: myProfile.salary,
      faculty: myProfile.faculty
        ? { name: myProfile.facultyName, uid: myProfile.faculty }
        : {},
      department: myProfile.department
        ? { name: myProfile.departmentName, uid: myProfile.department }
        : {},
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateLecturer(values, uid);
        enqueueSnackbar("Lecturer's profile has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });
  useEffect(() => {
    async function get() {
      setFACULTIES(await getFaculties());
      setDEPARTMENTS(await getDepartments());
    }
    get();
  }, [getFaculties, getDepartments]);

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Account Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}

              <TextField
                fullWidth
                autoComplete="username"
                label="Employee ID"
                disabled={!hasPermission}
                {...getFieldProps("empid")}
                error={Boolean(touched.empid && errors.empid)}
                helperText={touched.empid && errors.empid}
              />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  disabled={!hasPermission}
                  autoComplete="username"
                  label="First Name"
                  {...getFieldProps("fname")}
                  error={Boolean(touched.fname && errors.fname)}
                  helperText={touched.fname && errors.fname}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  label="Last Name"
                  disabled={!hasPermission}
                  {...getFieldProps("lname")}
                  error={Boolean(touched.lname && errors.lname)}
                  helperText={touched.lname && errors.lname}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="tel"
                  label="Phone number"
                  disabled={!hasPermission}
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
                <TextField
                  select
                  sx={{ width: { xs: "100%", md: 500 } }}
                  label="Gender"
                  className="fix-mui-padding"
                  disabled={!hasPermission}
                  value={values.gender}
                  onChange={(event) => {
                    setFieldValue("gender", event.target.value);
                  }}
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                >
                  {GENDER.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  options={FACULTIES}
                  {...getFieldProps("faculty")}
                  name="faculty"
                  value={values.faculty}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("faculty", newValue);
                      setFieldValue("department", {});
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Faculty"
                      margin="none"
                      error={Boolean(touched.faculty && errors.faculty)}
                      helperText={touched.faculty && errors.faculty}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={
                    values.faculty && values.faculty.uid
                      ? DEPARTMENTS.filter(
                          (department) =>
                            parseInt(department.faculty, 10) ===
                            parseInt(values.faculty.uid, 10)
                        )
                      : []
                  }
                  {...getFieldProps("department")}
                  name="department"
                  value={values.department}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("department", newValue);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      margin="none"
                      error={Boolean(touched.department && errors.department)}
                      helperText={touched.department && errors.department}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Date Employed"
                  {...getFieldProps("employed")}
                  error={Boolean(touched.employed && errors.employed)}
                  helperText={touched.employed && errors.employed}
                />
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Salary (optional)"
                  {...getFieldProps("salary")}
                  error={Boolean(touched.salary && errors.salary)}
                  helperText={touched.salary && errors.salary}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
