import PropTypes from "prop-types";
// material
import { Grid, Stack } from "@mui/material";
//
import AdminAbout from "./AdminAbout";
import StudentAbout from "./StudentAbout";
import AdminUpdate from "./AdminUpdate";
import AdminPassword from "./AdminPassword";

import StudentUpdate from "./StudentUpdate";
import StudentClassUpdate from "./StudentClassUpdate";
import StudentPassword from "./StudentPassword";

import LecturerAbout from "./LecturerAbout";
import LecturerUpdate from "./LecturerUpdate";
import LecturerPassword from "./LecturerPassword";

import PaymentAbout from "./PaymentAbout";
import PaymentUpdate from "./PaymentUpdate";

import useAuth from "../../../hooks/useAuth";
import DeleteData from "./DeleteData";
// ----------------------------------------------------------------------

Profile.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  type: PropTypes.string,
  fetch: PropTypes.func,
  isProfile: PropTypes.bool,
};

export default function Profile({ myProfile, uid, fetch, type, isProfile }) {
  const { usertype } = useAuth();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {type === "admin" && (
            <AdminAbout
              profile={myProfile}
              uid={uid}
              fetch={fetch}
              isProfile={isProfile}
            />
          )}
          {type === "student" && (
            <StudentAbout profile={myProfile} uid={uid} fetch={fetch} />
          )}
          {type === "lecturer" && (
            <LecturerAbout profile={myProfile} uid={uid} fetch={fetch} />
          )}
          {type === "payment" && (
            <PaymentAbout profile={myProfile} uid={uid} fetch={fetch} />
          )}
          {usertype !== 0 && <DeleteData uid={uid} type={type} />}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {type === "admin" && (
            <AdminUpdate
              isProfile
              myProfile={myProfile}
              uid={uid}
              fetch={fetch}
            />
          )}
          {type === "admin" && <AdminPassword uid={uid} fetch={fetch} />}

          {type === "student" && (
            <StudentUpdate myProfile={myProfile} uid={uid} fetch={fetch} />
          )}
          {type === "student" && (
            <StudentClassUpdate myProfile={myProfile} uid={uid} fetch={fetch} />
          )}
          {type === "student" && usertype !== 1 && (
            <StudentPassword myProfile={myProfile} uid={uid} />
          )}

          {type === "lecturer" && usertype !== 0 && (
            <LecturerUpdate myProfile={myProfile} uid={uid} fetch={fetch} />
          )}
          {type === "lecturer" && usertype !== 0 && (
            <LecturerPassword myProfile={myProfile} uid={uid} fetch={fetch} />
          )}

          {type === "payment" && (
            <PaymentUpdate myProfile={myProfile} uid={uid} fetch={fetch} />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
