import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { UploadInput } from "../../upload";
import { ASSETS_PATH } from "../../../utils/apis";

// ----------------------------------------------------------------------
LectureUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function LectureUpdate({ myProfile, uid, fetch }) {
  const { updateLecture, user, getCourses, usertype, uploadLectureNote } =
    useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission =
    permissions.includes("update_lecture") ||
    (usertype === 1 && user.user.uid === myProfile.lecturerData.uid);
  const isMountedRef = useIsMountedRef();
  const [COURSES, setCOURSES] = useState([]);
  const [disabledSubmit, setDisableSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    course: Yup.object().required("Course is required"),
    duration: Yup.number().required("Duration is required"),
    date: Yup.string().required("Date is required!"),
    time: Yup.string().required("Time is required!"),
  });

  const formik = useFormik({
    initialValues: {
      title: myProfile.title,
      course: myProfile.course,
      type: parseInt(myProfile.type, 10),
      note: myProfile.note,
      duration: myProfile.duration,
      date: myProfile.date,
      time: myProfile.time,
      description: myProfile.description,
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateLecture(values, uid);
        enqueueSnackbar("Lecture's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = formik;

  useEffect(() => {
    async function get() {
      const courses = await getCourses();
      setCOURSES(courses);
      setFieldValue(
        "course",
        courses.filter((course) => course.uid === myProfile.course)[0]
      );
    }
    get();
  }, [getCourses, myProfile.course, setFieldValue]);

  const handleNoteDrop = useCallback(
    (acceptedFiles) => {
      const upload = async (file) => {
        try {
          // upload to server
          setFieldTouched(`note`, true);
          setFieldValue(`note`, "");
          const formData = new FormData();
          formData.append("notes", file);
          setDisableSubmit(true);
          const newFile = await uploadLectureNote(formData);
          setFieldValue(`note`, newFile);
          setFieldTouched(`note`, false);
        } catch (err) {
          setFieldError(`note`, err.message);
        }
        setDisableSubmit(false);
      };

      const file = acceptedFiles[0];
      if (file) {
        upload(file);
      }
    },
    [setFieldValue, setFieldError, uploadLectureNote, setFieldTouched]
  );

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Lecture Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}

              <TextField
                fullWidth
                label="Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                disabled={!hasPermission}
                helperText={touched.title && errors.title}
              />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  options={COURSES}
                  disabled={!hasPermission}
                  {...getFieldProps("course")}
                  name="course"
                  value={values.course}
                  onChange={(event, newValue) => {
                    setFieldValue("course", newValue);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course"
                      margin="none"
                      error={Boolean(touched.course && errors.course)}
                      helperText={touched.course && errors.course}
                    />
                  )}
                />
                <TextField
                  type="number"
                  fullWidth
                  disabled={!hasPermission}
                  label="Duration (in minutes)"
                  {...getFieldProps("duration")}
                  error={Boolean(touched.duration && errors.duration)}
                  helperText={touched.duration && errors.duration}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Date"
                  {...getFieldProps("date")}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="time"
                  fullWidth
                  label="Time"
                  {...getFieldProps("time")}
                  error={Boolean(touched.time && errors.time)}
                  helperText={touched.time && errors.time}
                />
              </Stack>
              <TextField
                multiline
                rows={4}
                disabled={!hasPermission}
                fullWidth
                label="Lecture Description (optional)"
                {...getFieldProps("description")}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
              <FormControlLabel
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                control={
                  <UploadInput
                    error={Boolean(errors.note)}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                    caption={errors.note}
                    file={values.note}
                    assetLink={ASSETS_PATH.notes}
                    onDrop={(file) => handleNoteDrop(file)}
                    isUploading={Boolean(touched.note)}
                  />
                }
                sx={{ alignItems: "start" }}
                labelPlacement="top"
                label={
                  <Typography
                    variant="h6"
                    sx={{ color: "text.disabled", mb: 3 }}
                  >
                    Lecture Note:
                  </Typography>
                }
              />
              {hasPermission && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!hasPermission}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      color="primary"
                      checked={values.type === 1}
                      onChange={(event) => {
                        const type = event.target.checked ? 1 : 0;
                        setFieldValue("type", type);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Set lecture as  Live"
                />
              )}
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                disabled={disabledSubmit}
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
