import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { CircularProgress, Typography, Button, Box } from "@mui/material";
import { SeverErrorIllustration } from "../assets";
import { MotionContainer, varBounceIn } from "../components/animate";

export function CircularIndeterminate() {
  return (
    <Box
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ display: "flex", height: "100vh" }}
    >
      {" "}
      <CircularProgress sx={{ mx: "auto" }} />
    </Box>
  );
}

export function ShowError({ message, tryAgain, isNotShowImage }) {
  if (!message) {
    return <Box />;
  }
  return (
    <MotionContainer initial="initial" open>
      <Box
        sx={{
          maxWidth: 480,
          height: 1,
          margin: "auto",
          textAlign: "center",
          my: isNotShowImage ? 20 : 5,
        }}
      >
        <Typography sx={{ color: "error.main", mb: 4 }}>
          {message.message || (typeof message === "string" ? message : "")}
        </Typography>
        {!isNotShowImage && (
          <motion.div variants={varBounceIn}>
            <SeverErrorIllustration
              sx={{ height: 260, my: { xs: 5, sm: 10 } }}
            />
          </motion.div>
        )}
        {tryAgain && (
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try Again
          </Button>
        )}
      </Box>
    </MotionContainer>
  );
}
ShowError.propTypes = {
  message: PropTypes.any,
  tryAgain: PropTypes.bool,
  isNotShowImage: PropTypes.bool,
};
export const iff = (condition, then, otherwise) =>
  condition ? then : otherwise;
