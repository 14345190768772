import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
// material
import { Box, Grid, Card, Button, Typography, Stack, DialogActions, Dialog, DialogContent, TextField, DialogContentText, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { ShowError } from '../../../utils/loader';
import useAuth from '../../../hooks/useAuth';
import { fNumber } from '../../../utils/formatNumber';
import TestQuestions from './TestQuestions';

// ----------------------------------------------------------------------

SingleCard.propTypes = {
    question: PropTypes.object,
    index: PropTypes.number,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func
};

function SingleCard({ question, index, handleDelete, handleEdit }) {
    const { title, description, questions } = question;
    return (
        <Card sx={{ alignItems: 'center', p: 3 }}>
            <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
                <Typography variant="subtitle1" noWrap>
                    {title}
                </Typography>
                <Typography variant="body1" noWrap sx={{ color: 'text.disabled' }}>
                    {description || <span>&nbsp;</span>}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {fNumber(questions.length)} {questions.length > 1 ? "questions" : "question"}
                    </Typography>
                </Box>
            </Box>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 1 }}>
                <Button
                    size="small"
                    variant='outlined'
                    color='primary'
                    sx={{ mr: 1 }}
                    onClick={() => { handleEdit(index) }}
                >
                    View / Edit
                </Button>
                <Button
                    size="small"
                    variant='outlined'
                    color='error'
                    onClick={() => { handleDelete(index, title) }}
                >
                    Remove
                </Button>
            </Stack>

        </Card >
    );
}

DeleteModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleDelete: PropTypes.func,
    loading: PropTypes.bool,
    title: PropTypes.string
};
function DeleteModal({ open, title, handleClose, handleDelete, loading }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ color: 'error.main' }}>Please confirm you want to remove this section, {title}?</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    Please note that all the questions attached to this section will be removed as well.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit">
                    Cancel
                </Button>
                <LoadingButton onClick={handleDelete} variant="contained" loading={loading} color="error">
                    Delete
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

AddNew.propTypes = {
    handleAdd: PropTypes.func,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    handleChange: PropTypes.func,
    onClose: PropTypes.func,
    values: PropTypes.object,
};
function AddNew({ open, loading, handleAdd, handleChange, values, onClose }) {
    return (
        <Dialog open={open} sx={{ textAlign: 'center' }}>
            <DialogTitle>Add New Section</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    Please enter the title and description to proceed.
                </DialogContentText>
                <TextField sx={{ mb: 2 }} name="title" fullWidth label="Title" value={values.title} helperText="Eg. Essay, Objectives etc." onChange={(e) => handleChange(e)}
                />
                <TextField
                    multiline
                    rows={4}
                    name="description"
                    fullWidth label="Section Description / Instruction (optional)"
                    value={values.description}
                    onChange={(e) => handleChange(e)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outline">
                    Cancel
                </Button>
                <LoadingButton loading={loading} variant="contained" onClick={handleAdd}>
                    Add
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

TestSections.propTypes = {
    questions: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    uid: PropTypes.string.isRequired,
};
export default function TestSections({ questions, fetch, uid }) {
    const { updateTestSection } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({ title: '', description: '', questions: [] });

    const [openDelete, setOpenDelete] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteIndex, setDeleteIndex] = useState(-1);

    const [openQuestion, setOpenQuestion] = useState(false);
    const [sectionIndex, setSectionIndex] = useState(-1);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleAdd = async () => {
        if (values.title) {
            try {
                setLoading(true);
                questions.push(values);
                await updateTestSection(JSON.stringify(questions), uid);
                setOpen(false);
                setValues({ title: '', description: '' });
                enqueueSnackbar('Section has been added!', { variant: 'success' });
                fetch();
            } catch (err) {
                enqueueSnackbar(err.message, { variant: 'error' });
            }
            setLoading(false);
        } else {
            enqueueSnackbar(`Please enter a title to proceed!`, { variant: 'error' });
        }
    }
    const proceedSectionDelete = async () => {
        if (deleteIndex > -1) {
            try {
                setLoadingDelete(true);
                questions.splice(deleteIndex, 1);
                await updateTestSection(JSON.stringify(questions), uid);
                setOpen(false);
                setDeleteIndex(-1);
                setDeleteTitle("");
                enqueueSnackbar('Section has been removed!', { variant: 'success' });
                fetch();
            } catch (err) {
                enqueueSnackbar(err.message, { variant: 'error' });
            }
            setLoadingDelete(false);
        } else {
            enqueueSnackbar(`Please select a valid section to proceed!`, { variant: 'error' });
        }
    }
    const handleSectionDelete = (index, title) => {
        setDeleteTitle(title);
        setDeleteIndex(index);
        setOpenDelete(true);
    }
    const handleSectionEdit = (index) => {
        setSectionIndex(index);
        setOpenQuestion(true);
    }

    return (
        <Box sx={{ mt: 5 }}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Sections
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => { setOpen(true) }}
                >
                    New Section
                </Button>
            </Stack>
            {questions.length > 0 ?
                (<Grid container spacing={3}>
                    {questions.map((question, index) => (
                        <Grid key={index} item xs={12} md={6}>
                            <SingleCard question={question} index={index} handleDelete={handleSectionDelete} handleEdit={handleSectionEdit} />
                        </Grid>
                    ))}
                </Grid>)
                : <ShowError isNotShowImage message={new Error('This test does not have any question at the moment!')} />
            }
            <TestQuestions
                open={openQuestion}
                sections={questions}
                index={sectionIndex}
                fetch={fetch}
                uid={uid}
                handleClose={() => { setOpenQuestion(false) }}
            />
            <DeleteModal
                open={openDelete}
                handleClose={() => { setOpenDelete(false) }}
                handleDelete={proceedSectionDelete}
                loading={loadingDelete}
                title={deleteTitle}
            />
            <AddNew
                handleAdd={handleAdd}
                open={open}
                loading={loading}
                handleChange={handleChange}
                onClose={() => { setOpen(false) }}
                values={values}
            />
        </Box>
    );
}
