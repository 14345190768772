import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

CheckDelete.propTypes = {
  handleProceed: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
function CheckDelete({ open, onClose, handleProceed }) {
  return (
    <Dialog maxWidth="xs" fullWidth open={open} sx={{ textAlign: "center" }}>
      <DialogTitle sx={{ color: "error.main" }}>Confirm deletion?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please confirm you want to proceed!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={handleProceed}>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const PARENTS = {
  admin: PATH_DASHBOARD.general.admins,
  student: PATH_DASHBOARD.general.students,
  lecturer: PATH_DASHBOARD.general.lecturers,
  faculty: PATH_DASHBOARD.general.faculties,
  department: PATH_DASHBOARD.general.departments,
  course: PATH_DASHBOARD.general.courses,
  lecture: PATH_DASHBOARD.general.lectures,
  test: PATH_DASHBOARD.general.testExams,
  assignment: PATH_DASHBOARD.general.assignments,
};
const PERMISSIONS = {
  admin: "remove_administrators",
  student: "remove_students",
  lecturer: "remove_lecturers",
  faculty: "remove_faculties",
  department: "remove_departments",
  course: "remove_courses",
  lecture: "remove_lectures",
  test: "remove_tests_or_exams",
  assignment: "remove_assignments",
};

DeleteData.propTypes = {
  uid: PropTypes.string,
  type: PropTypes.string,
};
export default function DeleteData({ uid, type }) {
  const {
    deleteAdmin,
    deleteStudent,
    deleteLecturer,
    deleteFaculty,
    deleteDepartment,
    deleteCourse,
    deleteLecture,
    deleteTestExams,
    deleteAssignment,
    user,
    usertype,
  } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const permissions =
    usertype === 2 &&
    user.user &&
    user.user.role &&
    user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const navigate = useNavigate();

  const remove = async () => {
    try {
      setOpen(false);
      setLoading(true);
      switch (type) {
        case "admin":
          await deleteAdmin(uid);
          break;
        case "student":
          await deleteStudent(uid);
          break;
        case "lecturer":
          await deleteLecturer(uid);
          break;
        case "faculty":
          await deleteFaculty(uid);
          break;
        case "department":
          await deleteDepartment(uid);
          break;
        case "course":
          await deleteCourse(uid);
          break;
        case "lecture":
          await deleteLecture(uid);
          break;
        case "test":
          await deleteTestExams(uid);
          break;
        case "assignment":
          await deleteAssignment(uid);
          break;
        default:
          console.log("nothing deleted");
      }
      enqueueSnackbar("Deleted successfully!", { variant: "success" });
      navigate(PARENTS[type] || PATH_DASHBOARD.general.root);
    } catch (err) {
      enqueueSnackbar(
        (err && err.message) || "An error occurred! Please try again later",
        { variant: "error" }
      );
    }
    setLoading(false);
  };
  return (
    (((PERMISSIONS[type] && permissions.includes(PERMISSIONS[type])) ||
      (usertype === 1 && type === "test") ||
      (usertype === 1 && type === "assignment") ||
      (usertype === 1 && type === "lecture")) && (
      <>
        <LoadingButton
          color="error"
          variant="contained"
          size="large"
          onClick={() => setOpen(true)}
          loading={isLoading}
        >
          Delete
        </LoadingButton>
        <CheckDelete
          handleProceed={remove}
          open={open}
          onClose={() => setOpen(false)}
        />
      </>
    )) || <span />
  );
}
