import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LanguageIcon from "@mui/icons-material/Language";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { UploadInput } from "../../upload";
import { MATERIAL_TYPES } from "../../../utils/apis";

// ----------------------------------------------------------------------
LectureMaterialsUpload.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function LectureMaterialsUpload({ myProfile, uid, fetch }) {
  const { updateLectureMaterials, user, uploadMaterial, usertype } = useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission =
    permissions.includes("update_lecture") ||
    (usertype === 1 && user.user.uid === myProfile.lecturerData.uid);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const Schema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      materials: JSON.parse(myProfile.materials),
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const emptyMaterials = values.materials.filter(
          (mat) => mat.value === ""
        );
        if (emptyMaterials.length === 0) {
          await updateLectureMaterials(values, uid);
          enqueueSnackbar("Resources updated!", { variant: "success" });
          if (isMountedRef.current) {
            setSubmitting(false);
          }
          fetch();
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const handleAdd = () => {
    const { materials } = values;
    materials.push({
      type: "doc",
      description: "",
      value: "",
    });
    setFieldValue("materials", materials);
  };

  const handleRemove = (index) => {
    const { materials } = values;
    if (index > -1) {
      materials.splice(index, 1);
      setFieldValue("materials", materials);
    }
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldError,
    values,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const handleDrop = useCallback(
    (acceptedFiles, index) => {
      const upload = async (file) => {
        try {
          // upload to server
          setFieldTouched(`materials[${index}].value`, true);
          setFieldValue(`materials[${index}].value`, "");
          const formData = new FormData();
          formData.append("material_upload", file);
          setDisableSubmit(true);
          const newFile = await uploadMaterial(formData);
          setFieldValue(`materials[${index}].value`, newFile);
          setFieldTouched(`materials[${index}].value`, false);
        } catch (err) {
          setFieldError(`materials[${index}].value`, err.message);
        }
        setDisableSubmit(false);
      };

      const file = acceptedFiles[0];
      if (file) {
        upload(file);
      }
    },
    [setFieldValue, setFieldError, uploadMaterial, setFieldTouched]
  );

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Resources Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Stack
                divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
                spacing={3}
                sx={{ mt: 2 }}
              >
                {values.materials.map((item, index) => (
                  <Stack key={index} alignItems="flex-end" spacing={2}>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      spacing={2}
                      sx={{ width: 1, pb: 2 }}
                    >
                      <TextField
                        size="small"
                        sx={{ maxWidth: { md: 300 }, width: 300 }}
                        select
                        disabled={!hasPermission}
                        name={`materials${index}.type`}
                        value={values.materials[index].type}
                        label="Type"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          setFieldValue(
                            `materials[${index}].type`,
                            event.target.value
                          );
                          setFieldValue(`materials[${index}].value`, "");
                        }}
                        SelectProps={{
                          native: false,
                          sx: { textTransform: "capitalize" },
                        }}
                      >
                        {MATERIAL_TYPES.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      {values.materials[index].type === "link" ? (
                        <TextField
                          size="small"
                          fullWidth
                          disabled={!hasPermission}
                          name={`material.${index}.amount`}
                          value={values.materials[index].value}
                          label="Link"
                          sx={{ height: 5 }}
                          onChange={(event) =>
                            setFieldValue(
                              `materials[${index}].value`,
                              event.target.value
                            )
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LanguageIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <UploadInput
                          error={Boolean(
                            errors.materials &&
                              errors.materials[index] &&
                              errors.materials[index].value
                          )}
                          accept={
                            MATERIAL_TYPES.filter(
                              (type) =>
                                type.value === values.materials[index].type
                            )[0].accept
                          }
                          caption={
                            errors.materials &&
                            errors.materials[index] &&
                            errors.materials[index].value
                          }
                          file={values.materials[index].value}
                          onDrop={(file) => handleDrop(file, index)}
                          isUploading={Boolean(
                            touched.materials &&
                              touched.materials[index] &&
                              touched.materials[index].value
                          )}
                        />
                      )}
                    </Stack>
                    <TextField
                      size="small"
                      fullWidth
                      disabled={!hasPermission}
                      name={`material.${index}.description`}
                      value={values.materials[index].description || ""}
                      label="Description (optional)"
                      onChange={(event) =>
                        setFieldValue(
                          `materials[${index}].description`,
                          event.target.value
                        )
                      }
                    />
                    {hasPermission && (
                      <Button
                        size="small"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleRemove(index)}
                      >
                        Remove
                      </Button>
                    )}
                  </Stack>
                ))}
                <Divider sx={{ my: 3, borderStyle: "dashed" }} />
                {hasPermission && (
                  <Stack
                    spacing={2}
                    direction={{ xs: "column-reverse", md: "row" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                  >
                    <Button
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleAdd}
                      sx={{ flexShrink: 0 }}
                    >
                      Add material
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                disabled={disableSubmit}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
