import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Card, Alert, TextField, Stack, CardHeader, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

StudentNOKUpdate.propTypes = {
    nok: PropTypes.object,
    uid: PropTypes.string,
    fetch: PropTypes.func,
};
export default function StudentNOKUpdate({ nok, uid, fetch }) {
    const { updateStudentNOK, user, usertype } = useAuth();
    const permissions = user.user && usertype === 2 && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
    const hasPermission = permissions.includes('update_student');
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();

    const StudentSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        phone: Yup.string().required('Phone number is required'),
        relationship: Yup.string().required('Relationship is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: nok.name || '',
            phone: nok.phone || '',
            email: nok.email || '',
            relationship: nok.relationship || '',
            address: nok.address || ''
        },
        validationSchema: StudentSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                await updateStudentNOK(values, uid);
                enqueueSnackbar('Next of kin details has been updated!', { variant: 'success' });
                if (isMountedRef.current) {
                    setSubmitting(false);
                }
                fetch();
            } catch (error) {
                if (isMountedRef.current) {
                    setErrors({ afterSubmit: error.message });
                    setSubmitting(false);
                }
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Card sx={{ px: 3, pb: 3 }}>
            <CardHeader title="Next of Kin Information" />
            <Box sx={{ p: 3 }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth
                                    autoComplete="username"
                                    label="Name"
                                    disabled={!hasPermission}
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Stack>
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth
                                    type='tel'
                                    disabled={!hasPermission}
                                    label="Phone number"
                                    {...getFieldProps('phone')}
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                                <TextField
                                    fullWidth
                                    type='email'
                                    disabled={!hasPermission}
                                    label="Email address"
                                    {...getFieldProps('email')}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Stack>
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                <TextField
                                    disabled={!hasPermission}
                                    fullWidth label="Address"
                                    {...getFieldProps('address')}
                                    error={Boolean(touched.address && errors.address)}
                                    helperText={touched.address && errors.address} />
                            </Stack>
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                <TextField
                                    disabled={!hasPermission}
                                    fullWidth label="Relationship"
                                    {...getFieldProps('relationship')}
                                    error={Boolean(touched.relationship && errors.relationship)}
                                    helperText={touched.relationship && errors.relationship} />
                            </Stack>
                        </Stack>
                        {hasPermission && (<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                            Update
                        </LoadingButton>)}
                    </Form>
                </FormikProvider>
            </Box>
        </Card>
    );
}
