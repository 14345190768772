// material
import { Box, Typography, Container } from '@mui/material';
import { SeverErrorIllustration } from '../../assets';

export default function PermDenied() {
    return (
        <Container>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                <Typography variant="h3" paragraph sx={{ color: 'error.main' }}>
                    Permission Denied
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page.</Typography>
                <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </Box>
        </Container>
    );
}
