import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Card, Alert, TextField, Stack, CardHeader, Box, IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

LecturerPassword.propTypes = {
    uid: PropTypes.string
};
export default function LecturerPassword({ uid }) {
    const { updateLecturerPassword, user } = useAuth();
    const permissions = user.user && parseInt(user.user.usertype,10) === 2 && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
    const hasPermission = permissions.includes('update_lecturer') || (user.user.uid === uid && user.user.usertype === "1" );
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);

    const Schema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        cpassword: Yup.string().required('Please confirm your password')
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            cpassword: ''
        },
        validationSchema: Schema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                if (values.password === values.cpassword) {
                    await updateLecturerPassword(values.password, uid);
                    enqueueSnackbar('Lecturer\'s password has been updated!', { variant: 'success' });
                    resetForm();
                } else {
                    enqueueSnackbar('Please ensure your passwords matches to proceed.', { variant: 'error' });
                }
                if (isMountedRef.current) {
                    setSubmitting(false);
                }
            } catch (error) {
                if (isMountedRef.current) {
                    setErrors({ afterSubmit: error.message });
                    setSubmitting(false);
                }
            }
        }
    });
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Card sx={{ px: 3, pb: 3 }}>
            <CardHeader title="Security Information" />
            <Box sx={{ p: 3 }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                            <TextField
                                fullWidth
                                autoComplete="current-password"
                                type={showPassword ? 'text' : 'password'}
                                label="New Password"
                                disabled={!hasPermission}
                                {...getFieldProps('password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword} edge="end">
                                                {(showPassword && <VisibilityIcon />) || <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />
                            <TextField
                                fullWidth
                                autoComplete="current-password"
                                type={showPassword ? 'text' : 'password'}
                                label="Confirm Password"
                                disabled={!hasPermission}
                                {...getFieldProps('cpassword')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword} edge="end">
                                                {(showPassword && <VisibilityIcon />) || <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(touched.cpassword && errors.cpassword)}
                                helperText={touched.cpassword && errors.cpassword}
                            />
                        </Stack>
                        {hasPermission && (<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                            Update
                        </LoadingButton>)}
                    </Form>
                </FormikProvider>
            </Box>
        </Card>
    );
}
