import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: "",
  events: [],
  isOpenModal: false,
  isOpenViewModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.error = "";
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = filter(state.events, (user) => user.id !== eventId);
      state.isLoading = false;
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenViewModal = true;
      state.selectedEventId = eventId;
    },
    editEvent(state, action) {
      const eventId = action.payload;
      state.isOpenViewModal = false;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },
    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },
    openViewModal(state) {
      state.isOpenViewModal = true;
    },
    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    closeViewModal(state) {
      state.isOpenViewModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openModal,
  closeModal,
  selectEvent,
  editEvent,
  openViewModal,
  closeViewModal,
} = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(startDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.calendar, {
        search: "",
        startDate,
        endDate,
      });
      const { status, events } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getEventsSuccess(events));
        return;
      }
      throw new Error(
        "An error occured while trying to fetch calendar events! Please try again later."
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.calendar, {
        ...newEvent,
        data: JSON.stringify(newEvent.data.map((obj) => obj.uid)),
        status: newEvent.status === "draft" ? 0 : 1,
        add: "",
      });
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.calendar, {
        ...updateEvent,
        data: JSON.stringify(updateEvent.data.map((obj) => obj.uid)),
        status: updateEvent.status === "draft" ? 0 : 1,
        update: eventId,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(remove) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(API_PATH.calendar, { remove });
      dispatch(slice.actions.deleteEventSuccess({ eventId: remove }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}
