import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  grades: [],
  answers: [],
  totalAnswers: 0,
  totalGrades: 0,
  sortBy: null,
  filters: {
    department: "",
    status: "",
    search: "",
    semester: "",
    level: "",
  },
};

const slice = createSlice({
  name: "grade",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET USERS
    getSuccess(state, action) {
      state.isLoading = false;
      state.grades = action.payload.data;
      state.totalGrades = action.payload.total;
    },
    getAnswersSuccess(state, action) {
      state.isLoading = false;
      state.answers = action.payload.data;
      state.totalAnswers = action.payload.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

export function getGrades(
  search,
  department,
  faculty,
  semester,
  level,
  stat,
  sortBy,
  page,
  perpage
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.grades, {
        search,
        department,
        faculty,
        semester,
        level,
        status: stat,
        page,
        perpage,
        sortBy,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total }));
      } else {
        throw new Error(
          "Something went wrong while fetching grades! Please try again later."
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAnswers(testId, search, stat, sortBy, page, perpage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.grades, {
        search_answers: search,
        testId,
        status: stat,
        page,
        perpage,
        sortBy,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getAnswersSuccess({ data, total }));
      } else {
        throw new Error(
          "Something went wrong while fetching grades! Please try again later."
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
