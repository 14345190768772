import PropTypes from "prop-types";
// material
import { Box, Grid, Card, Button, Typography } from "@mui/material";
import { ASSETS_PATH, MATERIAL_TYPES } from "../../../utils/apis";
import { ShowError } from "../../../utils/loader";
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

SingleCard.propTypes = {
  material: PropTypes.object,
};

function SingleCard({ material }) {
  const { type, value, description } = material;
  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 3 }}>
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {value}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.disabled" }} noWrap>
            {MATERIAL_TYPES.filter((typ) => typ.value === type)[0].label}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
          {description || ""}
        </Typography>
      </Box>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        target="_blank"
        href={type === "link" ? value : `${ASSETS_PATH.materials}${value}`}
      >
        View
      </Button>
    </Card>
  );
}

LectureMaterials.propTypes = {
  materials: PropTypes.array.isRequired,
};

export default function LectureMaterials({ materials }) {
  const { usertype } = useAuth();
  return (
    <Box sx={{ mt: usertype === 0 ? 3 : 5 }}>
      <Typography variant={usertype === 0 ? "h6" : "h4"} sx={{ mb: 3 }}>
        Resources
      </Typography>
      {materials.length > 0 ? (
        <Grid container spacing={3}>
          {materials.map((material, index) => (
            <Grid key={index} item xs={12} md={usertype === 0 ? 12 : 4}>
              <SingleCard material={material} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          isNotShowImage
          message={
            new Error("This lecture does not have any material at the moment!")
          }
        />
      )}
    </Box>
  );
}
