import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Card, Typography, CardHeader, Stack } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import { fDate } from '../../../utils/formatTime';
import { PATH_DASHBOARD } from '../../../routes/paths';

StudentParentAbout.propTypes = {
    profile: PropTypes.object,
    nok: PropTypes.object,
    location: PropTypes.object
};

export default function StudentParentAbout({ profile, nok, location }) {
    const { name, email, phone, address, dateAdded, dateUpdated } = profile;
    const { lga, state } = location;
    const { relationship } = nok;

    return (
        <Stack direction='column' spacing={2}>
            <Card>
                <CardHeader title="Parent Details" />
                <Stack spacing={2} sx={{ p: 3 }}>
                    <Stack direction="row">
                        <PersonIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Name: &nbsp; <strong>{name && sentenceCase(name)}</strong>
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <PhoneAndroidIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            {phone && (<Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.student}`}>
                                {phone}
                            </Link>)}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <EmailIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            {email && (<Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.general.mailbox}/send/${email}`}>
                                {email}
                            </Link>)}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Address: &nbsp; {address && <strong>{address}</strong>}
                        </Typography>
                    </Stack>

                    {dateAdded && (<Stack direction="row">
                        <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Added on <strong>{dateAdded !== '0000-00-00' ? fDate(dateAdded) : 'Not Specified'}</strong>
                        </Typography>
                    </Stack>)}
                    {dateUpdated && (<Stack direction="row">
                        <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Last updated on <strong>{dateUpdated !== '0000-00-00' ? fDate(dateUpdated) : 'Not Specified'}</strong>
                        </Typography>
                    </Stack>)}
                </Stack>
            </Card>
            <Card>
                <CardHeader title="Address Details" />
                <Stack spacing={2} sx={{ p: 3 }}>
                    <Stack direction="row">
                        <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Address: &nbsp; <strong>{location.address && location.address}</strong>
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Local Government Area: &nbsp; <strong>{lga}</strong>
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            State: &nbsp; <strong>{state}</strong>
                        </Typography>
                    </Stack>

                    {location.dateAdded && (<Stack direction="row">
                        <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Added on <strong>{dateAdded !== '0000-00-00' ? fDate(location.dateAdded) : 'Not Specified'}</strong>
                        </Typography>
                    </Stack>)}
                    {location.dateUpdated && (<Stack direction="row">
                        <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Last updated on <strong>{dateUpdated !== '0000-00-00' ? fDate(location.dateUpdated) : 'Not Specified'}</strong>
                        </Typography>
                    </Stack>)}
                </Stack>
            </Card>
            <Card>
                <CardHeader title="Next of Kin Details" />
                <Stack spacing={2} sx={{ p: 3 }}>
                    <Stack direction="row">
                        <PersonIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Name: &nbsp; <strong>{nok.name && sentenceCase(nok.name)}</strong>
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <PhoneAndroidIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            {nok.phone && (<Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.student}`}>
                                {nok.phone}
                            </Link>)}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <EmailIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            {nok.email && (<Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.student}`}>
                                {nok.email}
                            </Link>)}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Address: &nbsp; {nok.address && <strong>{nok.address}</strong>}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Relationship: &nbsp; {relationship && <strong>{relationship}</strong>}
                        </Typography>
                    </Stack>

                    {nok.dateAdded && (<Stack direction="row">
                        <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Added on <strong>{nok.dateAdded !== '0000-00-00' ? fDate(nok.dateAdded) : 'Not Specified'}</strong>
                        </Typography>
                    </Stack>)}
                    {nok.dateUpdated && (<Stack direction="row">
                        <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Last updated on <strong>{nok.dateUpdated !== '0000-00-00' ? fDate(nok.dateUpdated) : 'Not Specified'}</strong>
                        </Typography>
                    </Stack>)}
                </Stack>
            </Card>
        </Stack>
    );
}
