import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Grid, Card, Button, Avatar, Typography } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { ASSETS_PATH } from '../../../utils/apis';
import { ShowError } from '../../../utils/loader';

// ----------------------------------------------------------------------

AdminCard.propTypes = {
  admin: PropTypes.object
};

function AdminCard({ admin }) {
  const { fname, lname, email, empid, photo, uid } = admin;
  const cover = photo ? `${ASSETS_PATH.admins}${photo}` : '/static/overlay.svg';

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Avatar alt={fname} src={cover} sx={{ width: 48, height: 48 }} />
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {fname} {lname}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {empid || email}
          </Typography>
        </Box>
      </Box>
      <Button
        size="small"
        variant='outlined'
        color='primary'
        component={RouterLink} 
        to={`${PATH_DASHBOARD.subpages.admin}/${uid}/${paramCase(fname)}`}
      >
        View
      </Button>
    </Card>
  );
}

RoleAdmins.propTypes = {
  admins: PropTypes.array.isRequired
};

export default function RoleAdmins({ admins }) {
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Administrators
      </Typography>

      {admins.length > 0 ?
      (<Grid container spacing={3}>
        {admins.map((admin) => (
          <Grid key={admin.uid} item xs={12} md={4}>
            <AdminCard admin={admin} />
          </Grid>
        ))}
      </Grid>)
      : <ShowError isNotShowImage message={new Error('This role is not assigned to any administrator at the moment!')} />
      }
    </Box>
  );
}
