import PropTypes from "prop-types";
import { useState } from "react";
import { paramCase } from "change-case";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import BookIcon from "@mui/icons-material/Book";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Label from "../../Label";
import { fNumber } from "../../../utils/formatNumber";
import { TEST_STATUS } from "../../../utils/apis";
import { fDateTime, fDate } from "../../../utils/formatTime";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

AssignmentAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog open={open} sx={{ textAlign: "center" }}>
      <DialogTitle>Change Assignment's Status</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 500 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {TEST_STATUS.map((test, index) => (
            <MenuItem key={index} value={test.value}>
              {test.label}
            </MenuItem>
          ))}
        </TextField>
        <Typography
          sx={{ display: "block", color: "error.main", mt: 2 }}
          variant="caption"
        >
          * Please note that the status of this assignment will apply to all the
          students assigned to it.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleStatusUpdate}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default function AssignmentAbout({ profile, uid, fetch }) {
  const { user, usertype, updateAssignmentStatus } = useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission =
    permissions.includes("update_assignments") || usertype === 1;
  const {
    date,
    time,
    lecturerData,
    level,
    unit,
    questions,
    status,
    dateAdded,
    dateUpdated,
    lecturerName,
    courseName,
    departmentData,
    departmentName,
    description,
    endDate,
    course,
  } = profile;
  const statusText = TEST_STATUS[parseInt(status, 10)];
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateAssignmentStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Assignment's status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };
  return (
    <Card>
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body2">{description}</Typography>
        <Stack direction="row">
          <BadgeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Label color={statusText.color}>{statusText.label}</Label>
            {hasPermission && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        <Stack direction="row">
          <BookIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Level / Class: <strong>{level}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <BookIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Course Unit: <strong>{unit}</strong>
          </Typography>
        </Stack>
        {lecturerName !== "" && (
          <Stack direction="row">
            <SchoolIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Taught by{" "}
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.lecturer}/${
                  lecturerData.uid
                }/${paramCase(lecturerName)}`}
              >
                {lecturerName}
              </Link>
            </Typography>
          </Stack>
        )}
        {courseName !== "" && (
          <Stack direction="row">
            <LocalLibraryIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.course}/${course}/${paramCase(
                  courseName
                )}`}
              >
                {courseName}
              </Link>
            </Typography>
          </Stack>
        )}
        {departmentName !== "" && (
          <Stack direction="row">
            <LocalLibraryIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.department}/${
                  departmentData.uid
                }/${paramCase(departmentName)}`}
              >
                {departmentName}
              </Link>
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Start Date/Time:{" "}
            {date !== "0000-00-00"
              ? fDateTime(`${date} ${time}`)
              : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Submission Date/Time:{" "}
            {endDate ? fDateTime(endDate) : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <QuestionAnswerIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            No. of Sections:{" "}
            <strong>
              {(questions && fNumber(JSON.parse(questions).length)) ||
                "Not Set Yet"}
            </strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        {dateUpdated && (
          <Stack direction="row">
            <CalendarTodayIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Last updated on{" "}
              {dateUpdated !== "0000-00-00"
                ? fDate(dateUpdated)
                : "Not Specified"}
            </Typography>
          </Stack>
        )}
      </Stack>
      <ChangeStatus
        open={open}
        loading={loading}
        handleStatus={handleStatus}
        status={newStatus}
        handleStatusUpdate={handleStatusUpdate}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Card>
  );
}
