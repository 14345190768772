import PropTypes from "prop-types";
import { differenceInCalendarDays, differenceInSeconds } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Countdown from "react-countdown";
// material
import { Card, Alert, Stack, Box, Button, Typography } from "@mui/material";
import { fTimestamp } from "../../../utils/formatTime";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------
LectureTakeProceed.propTypes = {
  myProfile: PropTypes.object,
};
export default function LectureTakeProceed({ myProfile }) {
  const { date, time, description, title, duration, uid } = myProfile;
  const [elapsed, setElapsed] = useState(0);
  const [elapsedDate, setElapsedDate] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    try {
      const differenceDate =
        (date &&
          time &&
          differenceInCalendarDays(
            fTimestamp(`${date} ${time}`),
            new Date().getTime()
          )) ||
        0;
      if (differenceDate > 0) {
        setElapsed(differenceDate);
        setElapsedDate(differenceDate > 1 ? "days" : "day");
      } else {
        const differenceTime =
          (date &&
            time &&
            differenceInSeconds(
              fTimestamp(`${date} ${time}`),
              new Date().getTime()
            )) ||
          0;
        const hours = parseInt(differenceTime / 3600, 10);
        const minutes = parseInt(differenceTime / 60, 10);
        const timeElapsed =
          fTimestamp(`${date} ${time}`) + parseInt(duration, 10) * 60 * 1000;
        if (!timeElapsed || timeElapsed > new Date().getTime()) {
          if (hours > 0) {
            setElapsed(hours);
            setElapsedDate(hours > 1 ? "hours" : "hour");
          } else if (minutes > 0) {
            setElapsed(minutes);
            setElapsedDate(minutes > 1 ? "minutes" : "minute");
          } else {
            setElapsed(0);
            setElapsedDate("soon");
          }
        } else {
          setElapsed(-1);
          setElapsedDate("");
        }
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "An error occured while trying to start your test! Please try again later.",
        {
          variant: "error",
        }
      );
    }
  }, [date, time, duration, enqueueSnackbar]);

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <Box sx={{ p: 3 }}>
        <Stack spacing={3}>
          {elapsed !== 0 && (
            <Alert severity={elapsed > 0 ? "warning" : "error"}>
              {elapsed > 0
                ? (elapsedDate === "soon" && "The lecture will start soon") ||
                  `The lecture will be available in ${elapsed} ${elapsedDate}.`
                : "The lecture is no longer available."}
            </Alert>
          )}
          <Typography variant="h2">{title}</Typography>
          <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
            {description}
          </Typography>
          {elapsed > 0 && (
            <Countdown
              date={`${date} ${time}`}
              renderer={({ days, hours, minutes, seconds, completed }) => {
                if (completed) {
                  // Render a completed state
                  return (
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      component={RouterLink}
                      to={`${PATH_DASHBOARD.general.takeLecture}${uid}/${title}`}
                      sx={{ mt: 10 }}
                    >
                      Join Lecture
                    </Button>
                  );
                }
                // Render a countdown
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ py: 5 }}
                    divider={
                      <Typography
                        variant="body1"
                        sx={{ color: "text.neutral" }}
                      >
                        &nbsp;&nbsp;:&nbsp;&nbsp;
                      </Typography>
                    }
                  >
                    <Typography variant="h1" sx={{ color: "text.secondary" }}>
                      {days}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {days > 1 ? "days" : "day"}
                      </Typography>
                    </Typography>
                    <Typography variant="h1" sx={{ color: "text.secondary" }}>
                      {hours}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {hours > 1 ? "hours" : "hour"}
                      </Typography>
                    </Typography>
                    <Typography variant="h1" sx={{ color: "text.secondary" }}>
                      {minutes}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {minutes > 1 ? "minutes" : "minute"}
                      </Typography>
                    </Typography>
                    <Typography variant="h1" sx={{ color: "text.disabled" }}>
                      {seconds}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {seconds > 1 ? "seconds" : "second"}
                      </Typography>
                    </Typography>
                  </Stack>
                );
              }}
            />
          )}
          {elapsed === 0 && (
            <Stack spacing={1}>
              <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
                Instructions:
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                Please prepare to join the lecture room. When prompted, please
                allow permissions for accessing microphone and camera to
                proceed.
              </Typography>
            </Stack>
          )}
        </Stack>
        {elapsed === 0 && (
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            component={RouterLink}
            to={`${PATH_DASHBOARD.general.takeLecture}${uid}/${title}`}
            sx={{ mt: 10 }}
          >
            Join Lecture
          </Button>
        )}
      </Box>
    </Card>
  );
}
