import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Card, Alert, TextField, Stack, CardHeader, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------
StudentReferreesUpdate.propTypes = {
    referrees: PropTypes.object,
    uid: PropTypes.string,
    fetch: PropTypes.func,
};
export default function StudentReferreesUpdate({ referrees, uid, fetch }) {
    const { updateStudentReferrees, user, usertype } = useAuth();
    const permissions = user.user && usertype === 2 && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
    const hasPermission = permissions.includes('update_student');
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();

    const StudentSchema = Yup.object().shape({

    });

    const formik = useFormik({
        initialValues: {
            data: referrees.data ? JSON.parse(referrees.data) : [{ name: '', address: '', phone: '', email: '' }],
        },
        validationSchema: StudentSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                const referrees = values.data.filter((obj) => obj.name !== '');
                if (referrees.length > 0) {
                    await updateStudentReferrees(JSON.stringify(referrees), uid);
                    enqueueSnackbar('Student\'s referrees has been updated!', { variant: 'success' });
                    if (isMountedRef.current) {
                        setSubmitting(false);
                    }
                    fetch();
                } else {
                    enqueueSnackbar('Please add atleast one referree to proceed.', { variant: 'error' });
                }
            } catch (error) {
                if (isMountedRef.current) {
                    setErrors({ afterSubmit: error.message });
                    setSubmitting(false);
                }
            }
        }
    });

    const { errors, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

    const handleAddData = () => {
        const { data } = values;
        data.push({ name: '', address: '', email: '', phone: '' });
        setFieldValue('data', data);
    }

    const handleRemove = (index) => {
        const { data } = values;
        if (index > -1) {
            data.splice(index, 1);
            setFieldValue("data", data);
        }
    };

    return (
        <Card sx={{ px: 3, pb: 3 }}>
            <CardHeader title="Referrees Information" />
            <Box sx={{ p: 3 }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                        <Box sx={{ p: 2 }}>
                            {values.data.map((ref, index) => (
                                <Stack key={index} alignItems="flex-end" spacing={1.5}>
                                    <Stack direction='column' spacing={2} sx={{ width: 1 }}>
                                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                            <TextField
                                                disabled={!hasPermission}
                                                fullWidth label="Name"
                                                {...getFieldProps(`data.${index}.name`)} />
                                            <TextField
                                                disabled={!hasPermission}
                                                type="tel"
                                                fullWidth label="Phone number"
                                                {...getFieldProps(`data.${index}.phone`)} />
                                        </Stack>
                                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                            <TextField
                                                disabled={!hasPermission}
                                                type="email"
                                                fullWidth label="Email address"
                                                {...getFieldProps(`data.${index}.email`)} />
                                            <TextField
                                                disabled={!hasPermission}
                                                type="address"
                                                fullWidth label="Address"
                                                {...getFieldProps(`data.${index}.address`)} />
                                        </Stack>
                                    </Stack>
                                    {hasPermission &&
                                        (
                                            <Button
                                                size="small"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => handleRemove(index)}
                                            >
                                                Remove
                                            </Button>
                                        )
                                    }
                                </Stack>
                            ))}
                            {hasPermission && (<Button sx={{ m: 2 }} variant="contained" onClick={handleAddData}>Add referree</Button>)}
                        </Box>
                        {hasPermission && (<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                            Update
                        </LoadingButton>)}
                    </Form>
                </FormikProvider>
            </Box>
        </Card>
    );
}
