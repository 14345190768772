import PropTypes from 'prop-types';
import { useState } from 'react';
import { paramCase, capitalCase } from 'change-case';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Card, Typography, CardHeader, Stack, DialogActions, Dialog, DialogContent, TextField, DialogContentText, DialogTitle, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import WcIcon from '@mui/icons-material/Wc';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Label from '../../Label';
import { STUDENT_STATUS, PROGRAMMES } from '../../../utils/apis';
import { isNumeric } from '../../../utils/formatNumber';
import { fDate } from '../../../utils/formatTime';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';

StudentAbout.propTypes = {
    profile: PropTypes.object,
    uid: PropTypes.string,
    fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
    handleStatusUpdate: PropTypes.func,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    handleStatus: PropTypes.func,
    onClose: PropTypes.func,
    status: PropTypes.string,
};
function ChangeStatus({ open, loading, handleStatusUpdate, handleStatus, status, onClose }) {
    return (
        <Dialog open={open} sx={{ textAlign: 'center' }}>
            <DialogTitle>Change Student's Account Status</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    Please set the new status below
                </DialogContentText>
                <TextField
                    select
                    sx={{ width: { xs: '100%', md: 500 } }}
                    label="New Status"
                    value={status}
                    onChange={handleStatus}
                >
                    <MenuItem value='0'>
                        Suspend
                    </MenuItem>
                    <MenuItem value='1'>
                        Activate
                    </MenuItem>
                    <MenuItem value='2'>
                        Rusticated
                    </MenuItem>
                    <MenuItem value='3'>
                        Withdrawal for Academic Failure(WAF)
                    </MenuItem>
                    <MenuItem value='4'>
                        Graduated
                    </MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outline">
                    Cancel
                </Button>
                <LoadingButton loading={loading} variant="contained" onClick={handleStatusUpdate}>
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
export default function StudentAbout({ profile, uid, fetch }) {
    const { user, usertype } = useAuth();
    const permissions = user.user && usertype === 2 && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
    const hasPermission = permissions.includes('update_student');
    const isAdmin = usertype === 2;
    const { level, gender, email, departmentName, department, dateRegistered, phone, dateAdded, dateUpdated, status, programme, admissionNo, facultyName, faculty, dob, age } = profile;
    const programText = PROGRAMMES.filter((obj) => obj.value === programme);
    const statusText = STUDENT_STATUS[parseInt(status, 10)];
    const { updateStudentStatus } = useAuth();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const handleStatus = (event) => {
        setNewStatus(event.target.value);
    }
    const handleStatusUpdate = async () => {
        if (newStatus) {
            try {
                setLoading(true);
                await updateStudentStatus(newStatus, uid);
                setOpen(false);
                setNewStatus('');
                enqueueSnackbar('Student\'s status has been updated!', { variant: 'success' });
                fetch();
            } catch (err) {
                enqueueSnackbar(err.message, { variant: 'error' });
            }
            setLoading(false);
        } else {
            enqueueSnackbar(`Please select a new status to proceed!`, { variant: 'error' });
        }
    }


    return (
        <Card>
            <CardHeader title="Details" />
            <Stack spacing={2} sx={{ p: 3 }}>
                <Stack direction="row">
                    <BadgeIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        <Label color={statusText.value} >
                            {statusText.label}
                        </Label>
                        {hasPermission && (<span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;
                            <Link variant="subtitle2" color="error.main" component='button' onClick={() => { setOpen(true); }}>
                                Change
                            </Link></span>)}
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <PhoneAndroidIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        {(isAdmin && (<Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.student}`}>
                            {phone}
                        </Link>)) || (<strong>{phone}</strong>)}
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <EmailIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                    {(isAdmin && (<Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.general.mailbox}/send/${email}`}>
                            {email}
                        </Link>)) || (<strong>{email}</strong>)}
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Admission No: &nbsp; <strong>{admissionNo}</strong>
                    </Typography>
                </Stack>

                <Stack direction="row">
                    <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Department: &nbsp; <strong>
                            <Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.department}/${department}/${paramCase(departmentName)}`}>
                                {departmentName}
                            </Link></strong>
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CorporateFareIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Faculty: &nbsp; <strong>
                            <Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.faculty}/${faculty}/${paramCase(facultyName)}`}>
                                {facultyName}
                            </Link></strong>
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <WcIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        {gender ? (gender === "M" && "Male" || "Female") : 'Not Specified'}
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <SchoolIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        <strong>{programme ? (`${programText.length > 0 && capitalCase(`${programText[0].category} ${programText[0].label}`)} ${(isNumeric(level) ? `(${level}L)` : level)}`) : 'Not Specified'}</strong>
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Date of birth: <strong>{dob ? `${fDate(dob)} (${age})` : 'Not Specified'}</strong>
                    </Typography>
                </Stack>
                {dateRegistered !== '0000-00-00' &&
                    (<Stack direction="row">
                        <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                        <Typography variant="body2">
                            Admitted on <strong>{fDate(dateRegistered)}</strong>
                        </Typography>
                    </Stack>)
                }
                <Stack direction="row">
                    <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Added on <strong>{dateAdded !== '0000-00-00' ? fDate(dateAdded) : 'Not Specified'}</strong>
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Last updated on <strong>{dateUpdated !== '0000-00-00' ? fDate(dateUpdated) : 'Not Specified'}</strong>
                    </Typography>
                </Stack>
            </Stack>
            <ChangeStatus
                open={open}
                loading={loading}
                handleStatus={handleStatus}
                status={newStatus}
                handleStatusUpdate={handleStatusUpdate}
                onClose={() => { setOpen(false) }}
            />
        </Card>
    );
}
