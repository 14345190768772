import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import adminsReducer from "./slices/admins";
import studentsReducer from "./slices/students";
import lecturersReducer from "./slices/lecturers";
import facultiesReducer from "./slices/faculties";
import departmentsReducer from "./slices/departments";
import coursesReducer from "./slices/courses";
import rolesReducer from "./slices/roles";
import chatReducer from "./slices/chat";
import mailReducer from "./slices/mail";
import paymentReducer from "./slices/payment";
import lectureReducer from "./slices/lectures";
import paymentsReducer from "./slices/payments";
import calendarReducer from "./slices/calendar";
import testsReducer from "./slices/tests";
import assignmentReducer from "./slices/assignment";
import exportReducer from "./slices/export";
import noticeBoardReducer from "./slices/noticeBoard";
import gradeReducer from "./slices/grade";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  user: userReducer,
  admins: adminsReducer,
  students: studentsReducer,
  lecturers: lecturersReducer,
  faculties: facultiesReducer,
  departments: departmentsReducer,
  courses: coursesReducer,
  roles: rolesReducer,
  payment: paymentReducer,
  lectures: lectureReducer,
  payments: paymentsReducer,
  calendar: calendarReducer,
  tests: testsReducer,
  export: exportReducer,
  notice_board: noticeBoardReducer,
  grade: gradeReducer,
  assignment: assignmentReducer,
});

export { rootPersistConfig, rootReducer };
