import { PropTypes } from "prop-types";
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
// components
import { ImportHead } from "../tables";
import { IMPORT_EXTRA_TYPES } from "../../../utils/apis";

ImportPreview.propTypes = {
  headers: PropTypes.array,
  setHeaders: PropTypes.func,
  data: PropTypes.array,
  type: PropTypes.string,
  isRemoveFirst: PropTypes.number,
  handleIsFirstChange: PropTypes.func,
  onDone: PropTypes.func,
  goBack: PropTypes.func,
};
export default function ImportPreview({
  headers,
  setHeaders,
  data,
  type,
  isRemoveFirst,
  handleIsFirstChange,
  onDone,
  goBack,
}) {
  const typeData = IMPORT_EXTRA_TYPES.filter((typ) => typ.value === type)[0];
  const handleHeaderChange = (index, value) => {
    setHeaders((prevState) => {
      prevState[index] = value || { value: "", title: "" };
      return [...prevState];
    });
  };
  console.log(data, data[0]);
  return (
    <Stack
      sx={{ p: 3 }}
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h6">
        Please select the appropriate data for each column below.
      </Typography>
      <Typography variant="subtitle2" sx={{ color: "text.disabled", mb: 1 }}>
        This will let us know where to place each data.
      </Typography>
      <Typography variant="subtitle2" sx={{ color: "text.disabled", mb: 3 }}>
        Please note that the data for the file has been truncated to 10 columns
        or less for easy visibility and performance issues. All your data will
        be added once you submit.
      </Typography>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <ImportHead
            headers={headers}
            columnsOptions={typeData.columns}
            onChange={handleHeaderChange}
          />
          <TableBody>
            {data.map((row, index) => (
              <TableRow hover key={index} tabIndex={-1} log="checkbox">
                {row.map((col, i) => (
                  <TableCell key={i}>{col}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            color="primary"
            checked={isRemoveFirst === 1}
            onChange={(event) => {
              const removeFirst = event.target.checked ? 1 : 0;
              handleIsFirstChange(removeFirst);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Remove first row from data?"
      />
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          onClick={() => {
            onDone();
          }}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            goBack();
          }}
          color="warning"
        >
          Change Data Type
        </Button>
      </Stack>
    </Stack>
  );
}
