import { styled } from '@mui/styles';
// material
import { Box, Typography, Container } from '@mui/material';
// components
import Page from '../../components/Page';
import { SeverErrorIllustration } from '../../assets';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function DomainError() {
  return (
    <RootStyle title="Domain Error">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            We do not recognize the address you just entered!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Please confirm the school's address with your administrator before proceeding.</Typography>
          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </Box>
      </Container>
    </RootStyle>
  );
}
