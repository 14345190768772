import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return `₦${numeral(number).format(
    Number.isInteger(number) ? "0,0" : "0,0.00"
  )}`;
}
export const fRange = (start, stop, step) => {
  if (stop < start) {
    return Array.from(
      { length: (start - stop) / step + 1 },
      (_, i) => start - i * step
    );
  }
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
};
export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function isNumeric(str) {
  if (typeof str !== "string") return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}
