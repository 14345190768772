import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_PATH } from '../../utils/apis';

const initialState = {
    isLoading: false,
    error: false,
    tests: [],
    totalTests: 0,
    sortBy: null,
    filters: {
        department: '',
        status: '',
        search: ''
    },
};

const slice = createSlice({
    name: 'tests',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET USERS
        getSuccess(state, action) {
            state.isLoading = false;
            state.tests = action.payload.data;
            state.totalTests = action.payload.total;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

export function getTestExams(search, department, stat, sortBy, page, perpage) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_PATH.tests, { search, department, status: stat, page, perpage, sortBy });
            const { status, data, total } = response.data;
            if (status === "success") {
                dispatch(slice.actions.getSuccess({ data, total }));
            } else {
                throw (new Error("Something went wrong while fetching tests! Please try again later."));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}