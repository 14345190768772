import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Card, Stack, Link, Container, Typography, Divider } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
import { ASSETS_PATH } from '../../utils/apis';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Page from '../../components/Page';
import AuthCarousel from '../../components/AuthCarousel';
import { MHidden } from '../../components/@material-extend';
import Image from '../../components/Image';
import Footer from '../../components/FooterAuth';
import { EllipseIcon } from '../../assets';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    overflow: 'hidden',
    height: '100vh'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  boxShadow: 'none',
  flexDirection: 'column',
  background: `url("${EllipseIcon()}")`,
  justifyContent: 'center',
  borderRadius: '0px',
  margin: theme.spacing(0, 0, 0, 0)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------
export default function Welcome() {
  const { user } = useAuth();
  const logo = user.school.logo ? `${ASSETS_PATH.logos}${ user.school.logo}` : "/logo/school-placeholder.svg";
  return (
    <RootStyle title="Welcome">
      <MHidden width="mdDown">
        <SectionStyle>
          <AuthCarousel />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="column" alignItems="center" sx={{ mb: 5, justifyContent: 'center' }}>
            <Box sx={{ flexGrow: 1, textAlign: 'center', mb: 5 }}>
              <Image className="rounded" alt="school logo" src={logo} placeholder="/logo/school-placeholder.svg" width='100' height='100' isCenter />
              <Typography variant="h4" gutterBottom>
                {user.school.name}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Access your preffered portal</Typography>
            </Box>

            <Link component={RouterLink} to={PATH_AUTH.studentLogin} sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', backgroundColor: 'background.default', justifyContent: 'center', alignItems: 'center', width: '100%', mb: 2 }}>
                <Typography variant="h6" align='center' sx={{ backgroundColor: 'primary.main', textAlign: 'center', color: 'common.white', flexGrow: 1, padding: 1 }}>
                  Student Portal
                </Typography>
                <Box sx={{ backgroundColor: 'primary.main', ml: 1, padding: '8px' }}>
                  <ArrowCircleRightIcon sx={{ color: 'common.white', fontSize: '1.8rem', verticalAlign: 'middle' }} />
                </Box>
              </Box>
            </Link>
            <Link component={RouterLink} to={PATH_AUTH.facultyLogin} sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', backgroundColor: 'background.default', justifyContent: 'center', alignItems: 'center', width: '100%', mb: 2 }}>
                <Typography variant="h6" align='center' sx={{ backgroundColor: 'primary.main', textAlign: 'center', color: 'common.white', flexGrow: 1, padding: 1 }}>
                  Faculty Portal
                </Typography>
                <Box sx={{ backgroundColor: 'primary.main', ml: 1, padding: '8px' }}>
                  <ArrowCircleRightIcon sx={{ color: 'common.white', fontSize: '1.8rem', verticalAlign: 'middle' }} />
                </Box>
              </Box>
            </Link>
            <Link component={RouterLink} to={PATH_AUTH.adminLogin} sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', backgroundColor: 'background.default', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" align='center' sx={{ backgroundColor: 'primary.main', textAlign: 'center', color: 'common.white', flexGrow: 1, padding: 1 }}>
                  Administration Portal
                </Typography>
                <Box sx={{ backgroundColor: 'primary.main', ml: 1, padding: '8px' }}>
                  <ArrowCircleRightIcon sx={{ color: 'common.white', fontSize: '1.8rem', verticalAlign: 'middle' }} />
                </Box>
              </Box>
            </Link>
            <Box sx={{ display: 'flex', backgroundColor: 'background.default', justifyContent: 'center', alignItems: 'center', width: '100%', mb: 5 }}>
              <Typography variant="h6" align='center' sx={{ backgroundColor: 'primary.main', textAlign: 'center', color: 'common.white', flexGrow: 1, padding: 1 }}>
                Learn About School
              </Typography>
              <Box sx={{ backgroundColor: 'primary.main', ml: 1, padding: '8px' }}>
                <ArrowCircleRightIcon sx={{ color: 'common.white', fontSize: '1.8rem', verticalAlign: 'middle' }} />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', backgroundColor: 'background.default', justifyContent: 'center', alignItems: 'center', width: '100%', mb: 2 }}>
              <Divider sx={{ flexGrow: 1 }} />
              <Typography variant="h6" align='center' sx={{ textAlign: 'center', color: '#637381', mx: 1 }}>
                Need Help?
              </Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Box>
            <Box sx={{ display: 'flex', backgroundColor: 'background.default', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
              <Link to="#" underline='none' component="button" variant="h6" sx={{ backgroundColor: 'primary.light', color: 'common.white', fontSize: '1.2rem', padding: '0.5rem', mr: 1, boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)' }}>FAQ</Link>
              <Link to="#" underline='none' component="button" variant="h6" sx={{ backgroundColor: 'primary.light', color: 'common.white', fontSize: '1.2rem', padding: '0.5rem', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)' }}>CONTACT US</Link>
            </Box>
            <Typography variant="body2" align='center' sx={{ textAlign: 'center', color: 'common.black', fontStyle: 'italic' }}>
              All systems are operational at all times
            </Typography>
            <Footer />
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle >
  );
}
