import PropTypes from 'prop-types';
// material
import { Grid, Stack } from '@mui/material';

import StudentParentAbout from './StudentParentAbout';
import StudentParentUpdate from './StudentParentUpdate';
import StudentAddressUpdate from './StudentAddressUpdate';
import StudentNOKUpdate from './StudentNOKUpdate';
import StudentReferreesUpdate from './StudentReferreesUpdate';

// ----------------------------------------------------------------------

StudentParentAddress.propTypes = {
    parent: PropTypes.object,
    address: PropTypes.object,
    nok: PropTypes.object,
    referrees: PropTypes.object,
    uid: PropTypes.string,
    fetch: PropTypes.func,
};

export default function StudentParentAddress({ parent, address, nok, referrees, uid, fetch }) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Stack spacing={3}>
                    <StudentParentAbout profile={parent} location={address} nok={nok} uid={uid} fetch={fetch} />
                </Stack>
            </Grid>

            <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                    <StudentParentUpdate parent={parent} uid={uid} fetch={fetch} />
                    <StudentAddressUpdate address={address} uid={uid} fetch={fetch} />
                    <StudentNOKUpdate nok={nok} uid={uid} fetch={fetch} />
                    <StudentReferreesUpdate referrees={referrees} uid={uid} fetch={fetch} />
                </Stack>
            </Grid>
        </Grid>
    );
}
