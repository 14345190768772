import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { paramCase, sentenceCase } from 'change-case';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Card, Typography, CardHeader, Stack, DialogActions, Dialog, DialogContent, TextField, DialogContentText, DialogTitle, MenuItem, Button, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WcIcon from '@mui/icons-material/Wc';
import BadgeIcon from '@mui/icons-material/Badge';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Label from '../../Label';
import { fCurrency } from '../../../utils/formatNumber';
import { ADMIN_STATUS, ACCESS_LEVELS } from '../../../utils/apis';
import { fDate } from '../../../utils/formatTime';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';

AdminAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
  isProfile: PropTypes.bool,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  status: PropTypes.string,
  onClose: PropTypes.func,
};
function ChangeStatus({ open, loading, handleStatusUpdate, handleStatus, status, onClose }) {
  return (
    <Dialog open={open} sx={{ textAlign: 'center' }}>
      <DialogTitle>Change Administrator's Account Status</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: '100%', md: 500 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          <MenuItem value='0'>
            Suspend
          </MenuItem>
          <MenuItem value='1'>
            Activate
          </MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleStatusUpdate}>
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

ChangeRole.propTypes = {
  handleRoleUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleRoleChange: PropTypes.func,
  level: PropTypes.string,
  dataName: PropTypes.string,
  DATA: PropTypes.array,
  levels: PropTypes.array,
  role: PropTypes.object,
  ROLES: PropTypes.array,
  onClose: PropTypes.func,
};
function ChangeRole({ open, loading, handleRoleUpdate, handleRoleChange, level, role, onClose, ROLES, dataName, DATA, levels }) {
  return (
    <Dialog open={open} sx={{ textAlign: 'center' }}>
      <DialogTitle>Change Administrator's Role and Access Level</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please select the new role and access level below
        </DialogContentText>
        <Stack direction='column' spacing={2}>
          <Autocomplete
            fullWidth
            options={ROLES}
            name="role"
            value={role}
            isOptionEqualToValue={(option, value) => option.rid === value.rid}
            onChange={(event, newValue) => {
              handleRoleChange("role", newValue);
            }}
            getOptionLabel={(option) => option.title || ''}
            renderInput={(params) => <TextField {...params} label="Administrative Role" margin="none" />}
          />
          <TextField
            select
            sx={{ width: { xs: '100%', md: 500 } }}
            label="Access Level"
            value={level}
            onChange={(event) => { handleRoleChange('level', event.target.value) }}
          >
            {ACCESS_LEVELS.map((level) => (
              <MenuItem value={level.value} key={level.value}>
                {level.label}
              </MenuItem>
            ))}
          </TextField>
          {(level === "1" || level === "2") && (<Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <Autocomplete
              fullWidth
              multiple
              filterSelectedOptions
              options={DATA}
              name="levels"
              value={levels}
              isOptionEqualToValue={(option, value) => option.uid === value.uid}
              onChange={(event, newValue) => {
                handleRoleChange("levels", newValue);
              }}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => <TextField {...params} label={`Select ${dataName}:`} margin="none"
                helperText={`You can leave this field blank to give access to all ${dataName}`} />}
            />
          </Stack>)}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleRoleUpdate}>
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default function AdminAbout({ profile, uid, fetch, isProfile }) {
  const { salary, gender, email, roleName, role, accessLevel, employDate, phone, dateAdded, dateUpdated, status, levels, roleData } = profile;

  const { user, updateAdminRoleLevel, updateAdminStatus, getRoles, getFaculties, getDepartments } = useAuth();
  const permissions = user.user && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
  const hasPermission = permissions.includes("update_administrators");
  const statusText = ADMIN_STATUS[parseInt(status, 10)];
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [newLevel, setNewLevel] = useState('');
  const [newRole, setNewRole] = useState({});
  const [newLevels, setNewLevels] = useState([]);
  const [ROLES, setROLES] = useState([]);
  const [DATA, setDATA] = useState([]);
  const [dataName, setDataName] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = useCallback(
    () => {
      const fetch = async () => {
        try {
          const level = newLevel ? parseInt(newLevel, 10) : 0;
          setDataName("");
          setDATA([]);
          if (level !== 0) {
            if (level === 1) {
              setDataName("Faculties");
              // fetch faculties
              setDATA(await getFaculties());
            }
            if (level === 2) {
              setDataName("Departments");
              // fetch departments
              setDATA(await getDepartments());
            }
          }
        } catch (err) {
          enqueueSnackbar('An error occured while trying to fetch data! Please try again later.', { variant: 'error' });
        }
      }
      fetch();
    },
    [newLevel, getDepartments, getFaculties, enqueueSnackbar]
  );

  useEffect(() => {
    setROLES([]);
    async function get() {
      setROLES(await getRoles());
    }
    get();
    fetchData();
    if (!roleOpen) {
      setNewLevel(ACCESS_LEVELS.filter((obj) => obj.label.toLowerCase() === accessLevel)[0].value);
      setNewRole(roleData);
      setNewLevels(levels);
    }
  }, [getRoles, fetchData, accessLevel, roleData, levels, roleOpen]);

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  }
  const handleRoleChange = (name, value) => {
    if (name === 'level') {
      setNewLevel(value);
      setNewLevels([]);
    }
    if (name === 'role') {
      setNewRole(value);
    }
    if (name === 'levels') {
      setNewLevels(value);
    }
  }
  const handleRoleUpdate = async () => {
    if (newLevel && newRole.rid) {
      try {
        setRoleLoading(true);
        await updateAdminRoleLevel(newLevels, newLevel, newRole, uid);
        setOpen(false);
        setNewStatus('');
        enqueueSnackbar('Admin\'s role and access level has been updated!', { variant: 'success' });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
      setRoleLoading(false);
    } else {
      enqueueSnackbar(`Please select a new role and access level to proceed!`, { variant: 'error' });
    }
  }
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateAdminStatus(newStatus, uid);
        setOpen(false);
        setNewStatus('');
        enqueueSnackbar('Admin\'s status has been updated!', { variant: 'success' });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, { variant: 'error' });
    }
  }


  return (
    <Card>
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <BadgeIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            <Label color={statusText.value} >
              {statusText.label}
            </Label>
            {hasPermission && !isProfile && (<span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;
              <Link variant="subtitle2" color="error.main" component='button' onClick={() => { setOpen(true); }}>
                Change
              </Link></span>)}
          </Typography>
        </Stack>
        <Stack direction="row">
          <PhoneAndroidIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            <Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.role}/${role}/${paramCase(roleName)}`}>
              {phone}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <EmailIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            <Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.general.mailbox}/send/${email}`}>
              {email}
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row">
          <AdminPanelSettingsIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            <Link variant="subtitle2" color="text.primary" component={RouterLink} to={`${PATH_DASHBOARD.subpages.role}/${role}/${paramCase(roleName)}`}>
              {roleName}
            </Link>
            {hasPermission && !isProfile && (<span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;
              <Link variant="subtitle2" color="error.main" component='button' onClick={() => { setRoleOpen(true); }}>
                Change
              </Link></span>)}
          </Typography>
        </Stack>
        <Stack direction="row">
          <AdminPanelSettingsIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            Access Level: &nbsp;<strong>{sentenceCase(accessLevel) || ''}</strong>
            {hasPermission && !isProfile && (<span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;
              <Link variant="subtitle2" color="error.main" component='button' onClick={() => { setRoleOpen(true); }}>
                Change
              </Link></span>)}
            <br />
            <Typography variant="span">{accessLevel !== 'school' ? ((levels.length > 0 && (levels.map((obj) => obj.name)).join(", ")) || `All ${sentenceCase((accessLevel === 'department' && 'departments') || 'faculties')}`) : '(All faculties and departments)'}</Typography>
          </Typography>
        </Stack>
        <Stack direction="row">
          <WcIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            {gender ? (gender === "M" && "Male" || "Female") : 'Not Specified'}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CreditCardIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            {parseFloat(salary) > 0 ? fCurrency(salary) : 'Not Specified'}
          </Typography>
        </Stack>
        {employDate !== '0000-00-00' &&
          (<Stack direction="row">
            <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
            <Typography variant="body2">
              Employed on {fDate(employDate)}
            </Typography>
          </Stack>)
        }
        <Stack direction="row">
          <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            Added on {dateAdded !== '0000-00-00' ? fDate(dateAdded) : 'Not Specified'}
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
          <Typography variant="body2">
            Last updated on {dateUpdated !== '0000-00-00' ? fDate(dateUpdated) : 'Not Specified'}
          </Typography>
        </Stack>
      </Stack>
      {hasPermission &&
        <ChangeStatus
          open={open}
          loading={loading}
          handleStatus={handleStatus}
          status={newStatus}
          onClose={() => { setOpen(false) }}
          handleStatusUpdate={handleStatusUpdate}
        />}
      {hasPermission &&
        <ChangeRole
          open={roleOpen}
          loading={roleLoading}
          handleRoleChange={handleRoleChange}
          level={newLevel}
          ROLES={ROLES}
          role={newRole}
          dataName={dataName}
          DATA={DATA}
          levels={newLevels}
          onClose={() => { setRoleOpen(false) }}
          handleRoleUpdate={handleRoleUpdate}
        />}
    </Card>
  );
}
