import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_PATH } from '../../utils/apis';

const initialState = {
  isLoading: false,
  error: false,
  admins: [],
  totalAdmins: 0,
  sortBy: null,
  filters: {
    roles: '',
    status: '',
    gender: '',
    search: ''
  },
};

const slice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET USERS
    getAdminsSuccess(state, action) {
      state.isLoading = false;
      state.admins = action.payload.data;
      state.totalAdmins = action.payload.total;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

export function getAdmins(search, gender, role, stat, sortBy, page, perpage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.admins, { search, role, status: stat, page, gender, perpage, sortBy });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getAdminsSuccess({ data, total }));
      } else {
        throw (new Error("Something went wrong while fetching administrators! Please try again later."));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}