import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// hooks
import { ASSETS_PATH } from '../../../utils/apis';
import useAuth from '../../../hooks/useAuth';
import { fNumber } from '../../../utils/formatNumber';
import { UploadAvatar } from '../../upload';

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    top: 0,
    zIndex: 9,
    width: '100%',
    content: "''",
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  }
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3)
  }
}));

const CoverImgStyle = styled('img')({
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

Cover.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  type: PropTypes.string
};
const COVER_ASSETS = {
  'admin': ASSETS_PATH.admins,
  'student': ASSETS_PATH.students,
  'lecturer': ASSETS_PATH.faculty,
};
const COVER_PERMS = {
  'admin': 'update_administrators',
  'student': 'update_student',
  'lecturer': 'update_lecturer',
};
export default function Cover({ myProfile, uid, type }) {
  const { updateStudentPhoto, updateAdminPhoto, updateLecturerPhoto, user, usertype } = useAuth();
  const permissions = user.user && parseInt(user.user.usertype,10) === 2 && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
  const hasPermission = permissions.includes(COVER_PERMS[type]);
  const { title, fname, lname, photo, departmentName, matric, roleName, empid, numCourses, name, usertypeText } = myProfile;
  const cover = photo ? `${COVER_ASSETS[type]}${photo}` : '/static/overlay.svg';
  const [newPhoto, setPhoto] = useState(cover);
  const { enqueueSnackbar } = useSnackbar();

  const saveNewPhoto = useCallback(
    (image) => {
      async function save(image) {
        try {
          if (type === "admin") {
            await updateAdminPhoto(image, uid);
            enqueueSnackbar('Administrator\'s photo has been updated!', { variant: 'success' });
          }
          if (type === "student") {
            await updateStudentPhoto(image, uid);
            enqueueSnackbar('Student\'s photo has been updated!', { variant: 'success' });
          }
          if (type === "lecturer") {
            await updateLecturerPhoto(image, uid);
            enqueueSnackbar('Lecturer\'s photo has been updated!', { variant: 'success' });
          }
        } catch (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      }
      save(image);
    }, [enqueueSnackbar, uid, updateStudentPhoto, updateAdminPhoto, updateLecturerPhoto, type]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        if (hasPermission || (usertype === 0 && type === "student" )) {
          setPhoto({
            ...file,
            preview: URL.createObjectURL(file)
          });
          const reader = new FileReader();
          reader.readAsDataURL(file);
          // eslint-disable-next-line
          reader.onload = function () {
            saveNewPhoto(reader.result);
          };
          // eslint-disable-next-line
          reader.onerror = function (error) {
            enqueueSnackbar('An error occured while trying to upload the photo! Please check image and try again.', { variant: 'error' });
          };
        } else {
          enqueueSnackbar('You do not have permission to perform this action!', { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, saveNewPhoto, hasPermission, usertype, type]
  );

  return (
    <RootStyle>
      <InfoStyle>
        {type !== "payment" && (<UploadAvatar
          accept="image/jpeg,image/png"
          file={newPhoto}
          maxSize={1000000}
          onDrop={handleDrop}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 }
          }}
          name={`${fname} ${lname}`}
          photo={cover}
        />)}
        {type === 'payment' && (<Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            mb: { xs: 1, md: 5 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography variant="h4">{name}</Typography>
          <Typography sx={{ opacity: 0.72 }}>{usertypeText}</Typography>
        </Box>)}
        {type === 'admin' && (<Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography variant="h4">{fname} {lname} {empid && `(${empid})`} </Typography>
          <Typography sx={{ opacity: 0.72 }}>{roleName}</Typography>
        </Box>)}
        {type === 'student' && (<Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography variant="h4">{fname} {lname} {matric && `(${matric})`} </Typography>
          <Typography sx={{ opacity: 0.72 }}>{departmentName}</Typography>
        </Box>)}

        {type === 'lecturer' && (<Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography variant="h4">{title} {fname} {lname} {empid && `(${empid})`} </Typography>
          <Typography sx={{ opacity: 0.72 }}>Assigned {fNumber(numCourses)} course(s)</Typography>
        </Box>)}
      </InfoStyle>
      <CoverImgStyle alt={`${fname}'s profile`} src={cover} />
    </RootStyle>
  );
}
