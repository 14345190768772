import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Grid, Card, Button, Typography } from '@mui/material';
import { MAvatar } from '../../@material-extend';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { ASSETS_PATH } from '../../../utils/apis';
import createAvatar from '../../../utils/createAvatar';
import { iff, CircularIndeterminate, ShowError } from '../../../utils/loader';

// ----------------------------------------------------------------------

SingleCard.propTypes = {
    datum: PropTypes.object
};

function SingleCard({ datum }) {
    const { fname, lname, empid, uid, email, photo } = datum;
    const src = createAvatar(`${fname} ${lname}`);
    const photoUrl = photo ? `${ASSETS_PATH.admins}${photo}` : '';
    return (
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
            <MAvatar
                src={photoUrl}
                sx={{ width: 48, height: 48 }}
                alt={fname}
                color={src.color}
            >
                {src.name}
            </MAvatar>
            <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${fname} ${lname}`}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {empid || email}
                    </Typography>
                </Box>
            </Box>
            <Button
                size="small"
                variant='outlined'
                color='primary'
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.admin}/${uid}/${paramCase(`${fname} ${lname}`)}`}
            >
                View
            </Button>
        </Card>
    );
}

FacultyAdmins.propTypes = {
    get: PropTypes.func.isRequired
};

export default function FacultyAdmins({ get }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                setData(await get());
            } catch (err) {
                setError(err.message);
            }
            setLoading(false);
        }
        fetch();
    }, [get]);
    return (
        <Box sx={{ mt: 5 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                Administrators
            </Typography>
            {data && data.length > 0 && !loading && !error ?
                (<Grid container spacing={3}>
                    {data.map((datum) => (
                        <Grid key={datum.uid} item xs={12} md={4}>
                            <SingleCard datum={datum} />
                        </Grid>
                    ))}
                </Grid>)
                : iff(loading, <CircularIndeterminate />, <ShowError isNotShowImage message={new Error(error)} />)
            }
        </Box>
    );
}
