import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link, Card, Typography, CardHeader, Stack, DialogActions, Dialog, DialogContent, TextField, DialogContentText, DialogTitle, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BadgeIcon from '@mui/icons-material/Badge';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Label from '../../Label';
import { fNumber, isNumeric } from '../../../utils/formatNumber';
import { ADMIN_STATUS } from '../../../utils/apis';
import { fDate } from '../../../utils/formatTime';
import useAuth from '../../../hooks/useAuth';

RoleAbout.propTypes = {
    profile: PropTypes.object,
    rid: PropTypes.string,
    fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
    handleStatusUpdate: PropTypes.func,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    handleStatus: PropTypes.func,
    onClose: PropTypes.func,
    status: PropTypes.string,
};
function ChangeStatus({ open, loading, handleStatusUpdate, handleStatus, status, onClose }) {
    return (
        <Dialog open={open} sx={{ textAlign: 'center' }}>
            <DialogTitle>Change Role's Status</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    Please set the new status below
                </DialogContentText>
                <TextField
                    select
                    sx={{ width: { xs: '100%', md: 500 } }}
                    label="New Status"
                    value={status}
                    onChange={handleStatus}
                >
                    <MenuItem value='0'>
                        Suspend
                    </MenuItem>
                    <MenuItem value='1'>
                        Activate
                    </MenuItem>
                </TextField>
                <Typography sx={{ display: 'block', color: 'error.main', mt: 2 }} variant='caption'>* Please note that the status of this role will apply to all the administrators it is assigned to.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outline">
                    Cancel
                </Button>
                <LoadingButton loading={loading} variant="contained" onClick={handleStatusUpdate}>
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
export default function RoleAbout({ profile, rid, fetch }) {
    const { user } = useAuth();
    const permissions = user.user && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
    const hasPermission = permissions.includes("update_administrative_role");
    const { description, status, dateAdded, dateUpdated, totalAdmins, totalPermissions } = profile;
    const statusText = ADMIN_STATUS[parseInt(status, 10)];
    const { updateRoleStatus } = useAuth();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const handleStatus = (event) => {
        setNewStatus(event.target.value);
    }
    const handleStatusUpdate = async () => {
        if (newStatus) {
            try {
                setLoading(true);
                await updateRoleStatus(newStatus, rid);
                setOpen(false);
                setNewStatus('');
                enqueueSnackbar('Role\'s status has been updated!', { variant: 'success' });
                fetch();
            } catch (err) {
                enqueueSnackbar(err.message, { variant: 'error' });
            }
            setLoading(false);
        } else {
            enqueueSnackbar(`Please select a new status to proceed!`, { variant: 'error' });
        }
    }
    return (
        <Card>
            <CardHeader title="Details" />
            <Stack spacing={2} sx={{ p: 3 }}>
                <Typography variant="body2">{description}</Typography>
                <Stack direction="row">
                    <BadgeIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        <Label color={statusText.value} >
                            {statusText.label}
                        </Label>
                        {hasPermission && (<span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;
                            <Link variant="subtitle2" color="error.main" component='button' onClick={() => { setOpen(true); }}>
                                Change
                            </Link></span>)}
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Assigned to <strong>{isNumeric(totalAdmins) ? fNumber(totalAdmins) : '0'}</strong> administrator(s)
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Has <strong>{isNumeric(totalPermissions) ? fNumber(totalPermissions) : totalPermissions}</strong> permission(s)
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Added on {dateAdded !== '0000-00-00' ? fDate(dateAdded) : 'Not Specified'}
                    </Typography>
                </Stack>
                <Stack direction="row">
                    <CalendarTodayIcon sx={{ width: 20, height: 20, marginTop: '2px', flexShrink: 0, marginRight: 2 }} />
                    <Typography variant="body2">
                        Last updated on {dateUpdated !== '0000-00-00' ? fDate(dateUpdated) : 'Not Specified'}
                    </Typography>
                </Stack>
            </Stack>
            <ChangeStatus
                open={open}
                loading={loading}
                handleStatus={handleStatus}
                status={newStatus}
                handleStatusUpdate={handleStatusUpdate}
                onClose={() => { setOpen(false) }}
            />
        </Card>
    );
}
