import PropTypes from "prop-types";
import { Stack, Typography, MenuItem, TextField, Button } from "@mui/material";
import { IMPORT_EXTRA_TYPES } from "../../../utils/apis";

ImportType.propTypes = {
  type: PropTypes.string,
  onSelect: PropTypes.func,
  onDone: PropTypes.func,
  goBack: PropTypes.func,
};
export default function ImportType({ type, onSelect, onDone, goBack }) {
  return (
    <Stack
      spacing={2}
      sx={{ p: 3 }}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4">Choose Data Type</Typography>
      <Typography variant="subtitle2">
        Please select what type of data this file contains
      </Typography>
      <TextField
        select
        sx={{ width: { xs: "100%", md: 500 } }}
        label="Data Type"
        value={type}
        onChange={(event) => {
          onSelect(event.target.value);
        }}
      >
        {IMPORT_EXTRA_TYPES.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Button onClick={onDone} color="primary" variant="contained">
          Select
        </Button>
        <Button onClick={goBack} color="warning">
          Change file
        </Button>
      </Stack>
    </Stack>
  );
}
