import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Card, Alert, TextField, Stack, CardHeader, Box, Autocomplete, Typography, MenuItem, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { PROGRAMMES, LEVELS } from '../../../utils/apis';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------
StudentClassUpdate.propTypes = {
    myProfile: PropTypes.object,
    uid: PropTypes.string,
    fetch: PropTypes.func,
};
const QUALIFICATIONS = [
    { label: 'Ordinary National Diploma (OND)', value: 'ond' },
    { label: 'Higher National Diploma (OND)', value: 'hnd' },
    { label: 'Nigeria Certificate In Education (NCE', value: 'nce' },
    { label: 'Bachelor of Technology (BTech)', value: 'btech' },
    { label: 'Bachelor of Science (BSc)', value: 'bsc' },
];
export default function StudentClassUpdate({ myProfile, uid, fetch }) {
    const { updateStudentClass, user, getDepartments, usertype } = useAuth();
    const permissions = user.user && usertype === 2 && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
    const hasPermission = permissions.includes('update_student');
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [DEPARTMENTS, setDEPARTMENTS] = useState([]);
    const { programmes } = user.school;

    useEffect(() => {
        const programs = programmes.split(",");
        if (programs.includes('masters')) {
            PROGRAMMES.push({ label: 'Master', category: 'Postgraduate', value: 'master', hasLevel: false });
        }
        if (programs.includes('doctoral')) {
            PROGRAMMES.push({ label: 'Doctorate', category: 'Postgraduate', value: 'doctorate', hasLevel: false });
        }
        if (programs.includes('predegree')) {
            PROGRAMMES.push({ label: 'Pre-Degree', category: 'Undergraduate', value: 'predegree', hasLevel: true });
        }
        if (programs.includes('sandwich')) {
            PROGRAMMES.push({ label: 'Sandwich', category: 'Postgraduate', value: 'sandwich', hasLevel: false });
        }
    }, [programmes]);
    useEffect(() => {
        async function get() {
            setDEPARTMENTS(await getDepartments());
        }
        get();
    }, [getDepartments]);

    const StudentSchema = Yup.object().shape({
        department: Yup.object().required('Department is required')
    });

    const programmeInfo = PROGRAMMES.filter((obj) => obj.value === myProfile.programme);

    const formik = useFormik({
        initialValues: {
            jamb_no: myProfile.jamb,
            department: myProfile.departmentInfo,
            programme: programmeInfo.length > 0 ? programmeInfo[0] : {},
            level: myProfile.level,
            institutions: myProfile.institutions ? JSON.parse(myProfile.institutions) : [{ name: '', qualification: '', from: '', to: '' }],
        },
        validationSchema: StudentSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                await updateStudentClass(values, uid);
                enqueueSnackbar('Student\'s class has been updated!', { variant: 'success' });
                if (isMountedRef.current) {
                    setSubmitting(false);
                }
                fetch();
            } catch (error) {
                if (isMountedRef.current) {
                    setErrors({ afterSubmit: error.message });
                    setSubmitting(false);
                }
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

    const handleAddInstitution = () => {
        const { institutions } = values;
        institutions.push({ name: '', qualification: '', from: '', to: '' });
        setFieldValue('institutions', institutions);
    }

    const handleRemove = (index) => {
        const { institutions } = values;
        if (index > -1) {
            institutions.splice(index, 1);
            setFieldValue("institutions", institutions);
        }
    };

    return (
        <Card sx={{ px: 3, pb: 3 }}>
            <CardHeader title="Class / Department Information" />
            <Box sx={{ p: 3 }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                            <TextField
                                fullWidth
                                autoComplete="username"
                                disabled={!hasPermission}
                                label="JAMB registration Number"
                                {...getFieldProps('jamb_no')}
                                error={Boolean(touched.jamb_no && errors.jamb_no)}
                                helperText={touched.jamb_no && errors.jamb_no}
                            />
                            <Autocomplete
                                fullWidth
                                options={DEPARTMENTS}
                                {...getFieldProps('department')}
                                name="department"
                                value={values.department}
                                onChange={(event, newValue) => {
                                    setFieldValue("department", newValue);
                                }}
                                disabled={!hasPermission}
                                getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, value) => option.uid === value.uid}
                                renderInput={(params) => <TextField {...params} label="Department" margin="none" error={Boolean(touched.department && errors.department)}
                                    helperText={touched.department && errors.department} />}
                            />
                            <Autocomplete
                                fullWidth
                                options={PROGRAMMES}
                                {...getFieldProps('programme')}
                                name="programme"
                                disabled={!hasPermission}
                                value={values.programme}
                                onChange={(event, newValue) => {
                                    setFieldValue("programme", newValue);
                                    if (!newValue.hasLevel) { setFieldValue("level", ''); }
                                }}
                                groupBy={(option) => option.category}
                                getOptionLabel={(option) => option.label || ''}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <TextField {...params} label="Programme" margin="none" error={Boolean(touched.programme && errors.programme)}
                                    helperText={touched.programme && errors.programme} />}
                            />
                            <Autocomplete
                                fullWidth
                                options={LEVELS}
                                disabled={!hasPermission || (values.programme ? !values.programme.hasLevel : false)}
                                {...getFieldProps('level')}
                                name="level"
                                value={values.level}
                                onChange={(event, newValue) => {
                                    setFieldValue("level", newValue);
                                }}
                                getOptionLabel={(option) => option || ''}
                                renderInput={(params) => <TextField {...params} label="Class / Level" margin="none" error={Boolean(touched.level && errors.level)}
                                    helperText={touched.level && errors.level} />}
                            />
                        </Stack>
                        {usertype === 2 && (<Box sx={{ p: 2 }}>
                            <Typography variant='h6' sx={{ mb: 1 }}>Institution(s) Attended:</Typography>
                            {values.institutions.map((institution, index) => (
                                <Stack key={index} alignItems="flex-end" spacing={1.5}>
                                    <Stack direction='column' spacing={2} sx={{ width: 1 }}>
                                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                            <TextField
                                                disabled={!hasPermission}
                                                fullWidth label="Name"
                                                {...getFieldProps(`institutions.${index}.name`)} />
                                            <TextField
                                                select
                                                sx={{ width: { xs: '100%', md: 500 } }}
                                                label="Qualification Obtained"
                                                disabled={!hasPermission}
                                                value={values.institutions[index].qualification}
                                                onChange={(event) => {
                                                    setFieldValue(`institutions.${index}.qualification`, event.target.value);
                                                }}
                                            >
                                                {QUALIFICATIONS.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Stack>
                                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!hasPermission}
                                                type='date' fullWidth label="Studied From"
                                                {...getFieldProps(`institutions.${index}.from`)} />
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={!hasPermission}
                                                type='date' fullWidth label="Studied To"
                                                {...getFieldProps(`institutions.${index}.to`)} />
                                        </Stack>
                                    </Stack>
                                    <Button
                                        size="small"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => handleRemove(index)}
                                    >
                                        Remove
                                    </Button>
                                </Stack>
                            ))}

                            <Button sx={{ m: 2 }} variant="contained" onClick={handleAddInstitution}>Add Institution</Button>
                        </Box>)}
                        {hasPermission && (<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                            Update
                        </LoadingButton>)}
                    </Form>
                </FormikProvider>
            </Box>
        </Card>
    );
}
