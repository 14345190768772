import { useState } from "react";
import { Switch } from "@mui/material";
import useSettings from "../../hooks/useSettings";

export default function ToggleDark() {
  const { themeMode, changeMode } = useSettings();
  const [checked, setChecked] = useState(themeMode === "dark");

  const handleChange = (event) => {
    let mode = "light";
    if (event.target.checked) {
      // set dark
      mode = "dark";
    }
    setChecked(event.target.checked);
    changeMode(mode);
  };

  return (
    <Switch
      color="primary"
      size="small"
      checked={checked}
      onChange={handleChange}
    />
  );
}
