import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  LinearProgress,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TablePagination,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
// components
import { useDispatch, useSelector } from "../../../redux/store";
import { getAnswers } from "../../../redux/slices/assignment";
import { iff, CircularIndeterminate, ShowError } from "../../../utils/loader";
import Scrollbar from "../../Scrollbar";
import { fCurrency, fNumber, fPercent } from "../../../utils/formatNumber";
import Label from "../../Label";
import { ListHead, ListToolbar } from "../tables";
import { GRADE_STATUS } from "../../../utils/apis";
import { fDateTime } from "../../../utils/formatTime";
import SearchNotFound from "../../SearchNotFound";
import GradeAssignment from "./GradeAssignment";

// ----------------------------------------------------------------------
const TYPE = "grade";
ProgressItem.propTypes = {
  progress: PropTypes.shape({
    label: PropTypes.string,
    amount: PropTypes.number,
    value: PropTypes.number,
  }),
};

function ProgressItem({ progress }) {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {progress.label}
        </Typography>
        <Typography variant="subtitle2">
          {fCurrency(progress.amount)}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          &nbsp;({fPercent(progress.value)})
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={progress.value}
        color={
          (progress.label === "Total Income" && "info") ||
          (progress.label === "Total Expenses" && "warning") ||
          "primary"
        }
      />
    </Stack>
  );
}
let TABLE_HEAD = [
  { id: "student", label: "Student", alignRight: false },
  { id: "answer", label: "No. of Answers", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "total_score", label: "Total Score", alignRight: false },
  { id: "grade", label: "Grade", alignRight: false },
  { id: "date_added", label: "Date Added", alignRight: false },
  { id: "date_graded", label: "Date Graded", alignRight: false },
  { id: "id" },
];
AssignmentAnswers.propTypes = {
  fetch: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
};
export default function AssignmentAnswers({ fetch, uid }) {
  const { getAssignment, usertype } = useAuth();
  const [assignment, setassignment] = useState({});
  const [loading, setIsLoading] = useState(false);
  const [errorGet, setError] = useState(false);

  const dispatch = useDispatch();
  const { answers, isLoading, error } = useSelector(
    (state) => state.assignment
  );
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("date");
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("desc");
  const [status, setStatus] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState({});
  const [openGrade, setOpenGrade] = useState(false);
  const [questions, setQuestions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const get = useCallback(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        const assignment = await getAssignment(uid);
        setassignment(assignment);
        setQuestions(JSON.parse(assignment.questions.replace(/\\/g, "")));
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    }
    fetch();
  }, [uid, getAssignment]);

  const filter = useCallback(() => {
    dispatch(getAnswers(uid, filterName, status, orderBy, page, rowsPerPage));
  }, [status, filterName, page, rowsPerPage, orderBy, uid, dispatch]);

  useEffect(() => {
    get();
    filter();
  }, [get, filter]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onGradeSelected = (pid) => {
    try {
      let toPays = [];
      if (pid) {
        toPays.push(pid);
      }
      if (!pid && selected.length > 0) {
        toPays = toPays.concat(selected);
      }
    } catch (err) {
      enqueueSnackbar(
        `An error occured with the amount specified! Please try again later.`,
        { variant: "error" }
      );
    }
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - answers.length) : 0;
  const isNotFound = answers.length === 0;
  if (usertype === 0) {
    TABLE_HEAD = [
      { id: "answer", label: "No. of Answers", alignRight: false },
      { id: "status", label: "Status", alignRight: false },
      { id: "total_score", label: "Total Score", alignRight: false },
      { id: "grade", label: "Grade", alignRight: false },
      { id: "date_added", label: "Date Added", alignRight: false },
      { id: "date_graded", label: "Date Graded", alignRight: false },
      { id: "id" },
    ];
  }

  return (
    <Box>
      {assignment.title && !isLoading && !error ? (
        <>
          {(isLoading || error) && answers.length === 0 ? (
            iff(
              isLoading,
              <Grid item xs={12}>
                <CircularIndeterminate />
              </Grid>,
              <Grid item xs={12}>
                <ShowError message={error} tryAgain />
              </Grid>
            )
          ) : (
            <Card>
              <ListToolbar
                type={TYPE}
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                status={status}
                setStatus={setStatus}
                onGradeSelected={onGradeSelected}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={-1}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {answers.map((row, index) => {
                        const {
                          possibleScore,
                          totalScore,
                          uid,
                          studentName,
                          answers,
                          status,
                          dateAdded,
                          dateGraded,
                          grade,
                        } = row;
                        const stat = GRADE_STATUS[parseInt(status, 10)];
                        const numAnswers = Object.keys(
                          JSON.parse(answers)
                        ).length;
                        return (
                          <TableRow hover key={index} tabIndex={-1}>
                            {usertype !== 0 && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Box
                                  sx={{
                                    py: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none",
                                  }}
                                  component={RouterLink}
                                  to={`${PATH_DASHBOARD.subpages.student}/${uid}/${studentName}`}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {studentName}
                                  </Typography>
                                </Box>
                              </TableCell>
                            )}

                            <TableCell style={{ minWidth: 160 }}>
                              {fNumber(numAnswers)}
                            </TableCell>
                            <TableCell style={{ minWidth: 160 }}>
                              <Label variant="ghost" color={stat.value}>
                                {stat.label}
                              </Label>
                            </TableCell>
                            <TableCell style={{ minWidth: 160 }}>
                              {possibleScore
                                ? `${totalScore} / ${possibleScore}`
                                : ""}
                            </TableCell>
                            <TableCell style={{ minWidth: 160 }}>
                              <Typography variant="subtitle1">
                                {grade}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ minWidth: 160 }}>
                              {fDateTime(dateAdded)}
                            </TableCell>
                            <TableCell style={{ minWidth: 160 }}>
                              {dateGraded ? fDateTime(dateGraded) : ""}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                onClick={() => {
                                  setSelected(row);
                                  setOpenGrade(true);
                                }}
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={TABLE_HEAD.length} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={TABLE_HEAD.length}>
                            <Box sx={{ py: 3 }}>
                              <SearchNotFound
                                searchQuery={filterName}
                                searchWord="assignment Result"
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                component="div"
                count={answers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
          <GradeAssignment
            open={openGrade}
            questions={questions || []}
            title={assignment.title || ""}
            answers={selected}
            handleClose={() => {
              setSelected({});
              setOpenGrade(false);
              dispatch(
                getAnswers(uid, filterName, status, orderBy, page, rowsPerPage)
              );
            }}
          />
        </>
      ) : (
        iff(
          isLoading,
          <CircularIndeterminate />,
          <ShowError message={error} tryAgain />
        )
      )}
    </Box>
  );
}
