import PropTypes from "prop-types";
// material
import {
  Autocomplete,
  TableRow,
  TableCell,
  TableHead,
  TextField,
} from "@mui/material";

// ----------------------------------------------------------------------

ImportHead.propTypes = {
  headers: PropTypes.array,
  columnsOptions: PropTypes.array,
  onChange: PropTypes.func,
};

export default function ImportHead({ headers, columnsOptions, onChange }) {
  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell, index) => (
          <TableCell key={index} align="left">
            <Autocomplete
              fullWidth
              options={columnsOptions}
              name={`header-${index}`}
              value={headers[index]}
              onChange={(event, newValue) => {
                onChange(index, newValue);
              }}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select column data"
                  margin="none"
                />
              )}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
