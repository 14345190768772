import PropTypes from 'prop-types';
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string
};
export default function MyAvatar({ name, photo, ...other }) {
  return (
    <MAvatar
      src={photo}
      alt={name}
      color='default'
      {...other}
    >
      {createAvatar(name).name}
    </MAvatar>
  );
}
