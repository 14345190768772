import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
  searchWord: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '',searchWord = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1" sx={{color: 'error.main'}}>
        { !searchQuery ? `No ${searchWord} yet!` : 'Not found!' }
      </Typography>
      {!searchQuery ? (
        <Typography variant="body2" align="center">
        Not to worry. They'll show up when they are available.
      </Typography>
      ) : ( 
        <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.
      </Typography>
      )
    }
    </Paper>
  );
}
