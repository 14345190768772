import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { PATH_DASHBOARD } from "../routes/paths";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_API = "https://api.onechanzo.com/app/";
const ROOTS_ASSETS = "https://assets.onechanzo.com/";

export const API_PATH = {
  root: ROOTS_API,
  dashboard: path(ROOTS_API, "dashboard"),
  admins: path(ROOTS_API, "admins"),
  courses: path(ROOTS_API, "courses"),
  grades: path(ROOTS_API, "grades"),
  assignments: path(ROOTS_API, "assignments"),
  departments: path(ROOTS_API, "departments"),
  faculty: path(ROOTS_API, "faculty"),
  lecturers: path(ROOTS_API, "lecturers"),
  login: path(ROOTS_API, "login"),
  forgot: path(ROOTS_API, "forgot"),
  profile: path(ROOTS_API, "profile"),
  roles: path(ROOTS_API, "roles"),
  settings: path(ROOTS_API, "settings"),
  signup: path(ROOTS_API, "signup"),
  students: path(ROOTS_API, "students"),
  payments: path(ROOTS_API, "payments"),
  paymentAnalytics: path(ROOTS_API, "payment-analytics"),
  mailbox: path(ROOTS_API, "mailbox"),
  lectures: path(ROOTS_API, "lectures"),
  calendar: path(ROOTS_API, "calendar"),
  tests: path(ROOTS_API, "tests"),
  misc: path(ROOTS_API, "misc"),
  export: path(ROOTS_API, "export"),
  noticeBoard: path(ROOTS_API, "notice-board"),
  import: path(ROOTS_API, "import"),
};

export const ASSETS_PATH = {
  logos: path(ROOTS_ASSETS, "logos/"),
  admins: path(ROOTS_ASSETS, "admins/"),
  faculty: path(ROOTS_ASSETS, "faculty/"),
  students: path(ROOTS_ASSETS, "students/"),
  materials: path(ROOTS_ASSETS, "materials/"),
  notes: path(ROOTS_ASSETS, "notes/"),
  files: path(ROOTS_ASSETS, "files/"),
};

export const ADMIN_STATUS = [
  { label: "Suspended", value: "error" },
  { label: "Active", value: "success" },
];

export const GRADE_STATUS = [
  { label: "Pending", value: "warning" },
  { label: "Graded", value: "success" },
];

export const LECTURE_STATUS = [
  { label: "Draft", value: "warning", input: "0" },
  { label: "Active", value: "success", input: "1" },
  { label: "Cancelled", value: "error", input: "2" },
];

export const STUDENT_STATUS = [
  { label: "Suspended", value: "error" },
  { label: "Active", value: "success" },
  { label: "Rusticated", value: "error" },
  { label: "Withdrawal for Academic Failure(WAF)", value: "error" },
  { label: "Graduated", value: "success" },
];

export const PAYMENT_TYPE_STATUSES = [
  { label: "Draft", value: "draft", color: "warning", numValue: "0" },
  { label: "Published", value: "published", color: "success", numValue: "1" },
  { label: "Cancelled", value: "cancelled", color: "error", numValue: "2" },
];
export const PAYMENT_TYPES = [
  { label: "Payments", value: "0", color: "info" },
  { label: "Withdrawals", value: "1", color: "warning" },
  { label: "Salary", value: "2", color: "info" },
  { label: "Deposit", value: "3", color: "success" },
];
export const PAYMENT_METHODS = [
  { label: "Cash", value: "0" },
  { label: "Card", value: "1" },
  { label: "Bank Transfer", value: "2" },
  { label: "Wallet", value: "3" },
];

export const MATERIAL_TYPES = [
  {
    label: "Document",
    value: "doc",
    color: "warning",
    accept:
      "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf",
  },
  { label: "Video", value: "video", color: "success", accept: "video/*" },
  { label: "Audio", value: "audio", color: "error", accept: "audio/*" },
  { label: "Link", value: "link", color: "error", accept: "" },
];

export const QUESTION_TYPES = [
  { label: "Single Choice", value: "single", color: "warning" },
  { label: "Multiple Choice", value: "multiple", color: "success" },
  { label: "Essay", value: "essay", color: "info" },
];

export const EXPORT_STATUS = [
  { label: "Pending", color: "warning" },
  { label: "Exported", color: "success" },
];

export const IMPORT_TYPES = [
  {
    label: "Courses",
    value: "1",
    link: PATH_DASHBOARD.general.courses,
  },
  {
    label: "Departments",
    value: "2",
    color: "info",
    link: PATH_DASHBOARD.general.departments,
  },
  {
    label: "Faculties",
    value: "3",
    color: "info",
    link: PATH_DASHBOARD.general.faculties,
  },
  {
    label: "Lecturers",
    value: "4",
    color: "info",
    link: PATH_DASHBOARD.general.lecturers,
  },
  {
    label: "Payments",
    value: "5",
    color: "info",
    link: PATH_DASHBOARD.general.payments,
  },
  {
    label: "Payment History",
    value: "6",
    color: "info",
    link: PATH_DASHBOARD.general.paymentHistory,
  },
  {
    label: "Students",
    value: "7",
    color: "info",
    link: PATH_DASHBOARD.general.students,
  },
];
export const IMPORT_EXTRA_TYPES = [
  {
    label: "Courses",
    value: "1",
    link: PATH_DASHBOARD.general.courses,
    columns: [
      { title: "Name", value: "name" },
      { title: "Course Code", value: "code" },
      { title: "Semester", value: "semester" },
      { title: "Department", value: "department" },
      { title: "Level", value: "level" },
      { title: "Course Unit", value: "unit" },
      { title: "Course Lecturer", value: "lecturer" },
      { title: "Email Address", value: "email" },
      { title: "Location", value: "location" },
      { title: "Description", value: "description" },
    ],
  },
  {
    label: "Departments",
    value: "2",
    color: "info",
    link: PATH_DASHBOARD.general.departments,
    columns: [
      { title: "Name", value: "name" },
      { title: "Email Address", value: "email" },
      { title: "Faculty", value: "faculty" },
      { title: "Head of Department", value: "dean" },
      { title: "Location", value: "location" },
    ],
  },
  {
    label: "Faculties",
    value: "3",
    color: "info",
    link: PATH_DASHBOARD.general.faculties,
    columns: [
      { title: "Name", value: "name" },
      { title: "Email Address", value: "email" },
      { title: "Dean of Faculty", value: "dean" },
      { title: "Location", value: "location" },
    ],
  },
  {
    label: "Lecturers",
    value: "4",
    color: "info",
    link: PATH_DASHBOARD.general.lecturers,
    columns: [
      { title: "Name", value: "name" },
      { title: "Employee ID", value: "empid" },
      { title: "Title", value: "title" },
      { title: "First Name", value: "fname" },
      { title: "Last Name", value: "lname" },
      { title: "Email Address", value: "email" },
      { title: "Phone Number", value: "phone" },
      { title: "Faculty", value: "faculty" },
      { title: "Department", value: "department" },
      { title: "Gender", value: "gender" },
      { title: "Date Employed", value: "date_employed" },
      { title: "Salary", value: "salary" },
    ],
  },
  {
    label: "Lectures",
    value: "5",
    color: "info",
    link: PATH_DASHBOARD.general.lectures,
    columns: [
      { title: "Title", value: "title" },
      { title: "Course", value: "course" },
      { title: "Duration", value: "duration" },
      { title: "Date", value: "date" },
      { title: "Time", value: "time" },
      { title: "Description", value: "description" },
    ],
  },
  {
    label: "Grades",
    value: "6",
    color: "info",
    link: PATH_DASHBOARD.general.grades,
    columns: [
      { title: "Text / Exam Title", value: "title" },
      { title: "Course", value: "course" },
      { title: "Student", value: "student" },
      { title: "Total Score", value: "total_score" },
      { title: "Possible Score", value: "possible_score" },
      { title: "Grade", value: "grade" },
      { title: "Submission Date", value: "date" },
      { title: "Submission Time", value: "time" },
      { title: "Comment", value: "comment" },
    ],
  },
  {
    label: "Students",
    value: "7",
    color: "info",
    link: PATH_DASHBOARD.general.students,
    columns: [
      { title: "Name", value: "name" },
      { title: "Admission Number", value: "admission_no" },
      { title: "Matriculation Number", value: "matric" },
      { title: "First Name", value: "fname" },
      { title: "Last Name", value: "lname" },
      { title: "Email Address", value: "email" },
      { title: "Phone Number", value: "phone" },
      { title: "Programme", value: "programme" },
      { title: "Department", value: "department" },
      { title: "Gender", value: "gender" },
      { title: "Date Admitted", value: "date_registered" },
      { title: "Class / Level", value: "level" },
      { title: "Is Student Indigene?", value: "is_indigene" },
    ],
  },
];

export const TEST_STATUS = [
  { label: "Draft", value: "0", color: "warning" },
  { label: "Published", value: "1", color: "success" },
  { label: "Cancelled", value: "2", color: "error" },
];

export const ACCESS_LEVELS = [
  { label: "School", value: "0" },
  { label: "Faculty", value: "1" },
  { label: "Department", value: "2" },
];

export const PROGRAMMES = [
  {
    label: "Full-time",
    category: "Undergraduate",
    value: "fulltime",
    hasLevel: true,
  },
  {
    label: "Part-time",
    category: "Undergraduate",
    value: "parttime",
    hasLevel: true,
  },
  {
    label: "Diploma",
    category: "Postgraduate",
    value: "diploma",
    hasLevel: false,
  },
];
export const GRADES_COLOR = [
  { value: "A+", color: "success" },
  { value: "A-", color: "success" },
  { value: "A", color: "success" },
  { value: "B+", color: "success" },
  { value: "B-", color: "success" },
  { value: "B", color: "success" },
  { value: "C+", color: "warning" },
  { value: "C-", color: "warning" },
  { value: "C", color: "warning" },
  { value: "D", color: "warning" },
  { value: "E", color: "error" },
  { value: "F", color: "error" },
  { value: "PASS", color: "success" },
  { value: "FAIL", color: "error" },
];
export const MONTHS = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];
export const ANALYTICS_FILTER_TYPES = [
  { label: "Today", value: "today" },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "current_month" },
  { label: "This Year", value: "current_year" },
  { label: "All Time", value: "all_time" },
  { label: "Date", value: "date" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "Date Range", value: "range" },
];
export const LEVELS = ["100", "200", "300", "400", "500", "600"];

export const STATES = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

export const LGAS = [
  {
    state: "Abia",
    lgas: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikawuno",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Umu Nneochi",
      "Obi Ngwa",
      "Obioma Ngwa",
      "Ohafia",
      "Ohaozara",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa West",
      "Ukwa East",
      "Umuahia North",
      "Umuahia South",
    ],
  },
  {
    state: "Adamawa",
    lgas: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi-North",
      "Mubi-South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Akwa Ibom",
    lgas: [
      "Abak",
      "Eastern-Obolo",
      "Eket",
      "Esit-Eket",
      "Essien-Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot-Abasi",
      "Ikot-Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung-Uko",
      "Ukanafun",
      "Urue-Offong/Oruko",
      "Uruan",
      "Uyo",
    ],
  },
  {
    state: "Anambra",
    lgas: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili-North",
      "Idemili-South",
      "Ihiala",
      "Njikoka",
      "Nnewi-North",
      "Nnewi-South",
      "Ogbaru",
      "Onitsha-North",
      "Onitsha-South",
      "Orumba-North",
      "Orumba-South",
    ],
  },
  {
    state: "Bauchi",
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'Are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    state: "Benue",
    lgas: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer-East",
      "Gwer-West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    lgas: [
      "Abadam",
      "Askira-Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    state: "Bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern-Ijaw",
      "Yenagoa",
    ],
  },
  {
    state: "Cross River",
    lgas: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar-Municipal",
      "Calabar-South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
  },
  {
    state: "Delta",
    lgas: [
      "Aniocha North",
      "Aniocha-North",
      "Aniocha-South",
      "Bomadi",
      "Burutu",
      "Ethiope-East",
      "Ethiope-West",
      "Ika-North-East",
      "Ika-South",
      "Isoko-North",
      "Isoko-South",
      "Ndokwa-East",
      "Ndokwa-West",
      "Okpe",
      "Oshimili-North",
      "Oshimili-South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli-North",
      "Ughelli-South",
      "Ukwuani",
      "Uvwie",
      "Warri South-West",
      "Warri North",
      "Warri South",
    ],
  },
  {
    state: "Ebonyi",
    lgas: [
      "Abakaliki",
      "Afikpo-North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza-North",
      "Ezza-South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    state: "Edo",
    lgas: [
      "Akoko Edo",
      "Egor",
      "Esan-Central",
      "Esan-North-East",
      "Esan-South-East",
      "Esan-West",
      "Etsako-Central",
      "Etsako-East",
      "Etsako-West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia-North-East",
      "Ovia-South-West",
      "Owan East",
      "Owan-West",
      "Uhunmwonde",
    ],
  },
  {
    state: "Ekiti",
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],
  },
  {
    state: "Enugu",
    lgas: [
      "Aninri",
      "Awgu",
      "Enugu-East",
      "Enugu-North",
      "Enugu-South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze-North",
      "Igbo-Eze-South",
      "Isi-Uzo",
      "Nkanu-East",
      "Nkanu-West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
  },
  {
    state: "FCT - Abuja",
    lgas: ["Abuja", "Kwali", "Kuje", "Gwagwalada", "Bwari", "Abaji"],
  },
  {
    state: "Gombe",
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    state: "Imo",
    lgas: [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato-North",
      "Ideato-South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji-Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru-East",
      "Oru-West",
      "Owerri-Municipal",
      "Owerri-North",
      "Owerri-West",
    ],
  },
  {
    state: "Jigawa",
    lgas: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin-Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin-Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri kasama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    state: "Kebbi",
    lgas: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin-Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko-Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    state: "Kaduna",
    lgas: [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'A",
      "Kachia",
      "Kaduna-North",
      "Kaduna-South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kano",
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin-Kudu",
      "Dawakin-Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun-Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano-Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin-Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun-Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "Kogi",
    lgas: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Oyi",
      "Yagba-East",
      "Yagba-West",
    ],
  },
  {
    state: "Katsina",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai-Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    state: "Kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti (Araromi/Opin)",
      "Ilorin-East",
      "Ilorin-South",
      "Ilorin-West",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Lagos",
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos-Island",
      "Lagos-Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
      "Yewa-South",
    ],
  },
  {
    state: "Nasarawa",
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Wamba",
      "Toto",
    ],
  },
  {
    state: "Niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    state: "Ogun",
    lgas: [
      "Abeokuta-North",
      "Abeokuta-South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu-East",
      "Ijebu-North",
      "Ijebu-North-East",
      "Ijebu-Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun-Waterside",
      "Remo-North",
      "Shagamu",
      "Yewa North",
    ],
  },
  {
    state: "Ondo",
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure-North",
      "Akure-South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ondo-East",
      "Ose",
      "Owo",
    ],
  },
  {
    state: "Osun",
    lgas: [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Boluwaduro",
      "Boripe",
      "Ede South",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife South",
      "Ife-Central",
      "Ife-East",
      "Ifelodun",
      "Ila",
      "Ilesa-East",
      "Ilesa-West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    state: "Oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibadan South-West",
      "Ibarapa-Central",
      "Ibarapa-East",
      "Ibarapa-North",
      "Ido",
      "Ifedayo",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogo-Oluwa",
      "Ogbomosho-North",
      "Ogbomosho-South",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori-Ire",
      "Oyo-West",
      "Oyo-East",
      "Saki-East",
      "Saki-West",
      "Surulere",
    ],
  },
  {
    state: "Plateau",
    lgas: [
      "Barkin-Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang-North",
      "Langtang-South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    state: "Rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada-East",
      "Ahoada-West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
  },
  {
    state: "Sokoto",
    lgas: [
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto-North",
      "Sokoto-South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    state: "Taraba",
    lgas: [
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    state: "Zamfara",
    lgas: [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Isa",
      "Kaura-Namoda",
      "Kiyawa",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata-Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];
export const logPusher = (userId, status) => {
  try {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: "6004474f-32de-41c6-a45f-8e7767d57b45",
    });
    beamsClient
      .getUserId()
      .then((uid) => {
        if (uid !== userId) {
          return beamsClient.stop();
        }
      })
      .catch(console.error);
    if (status === 1) {
      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: API_PATH.login,
        queryParams: {
          genToken: "1",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      beamsClient
        .start()
        .then(() => beamsClient.addDeviceInterest("general"))
        .then(() => console.log("Successfully registered and subscribed!"))
        .then(() => beamsClient.setUserId(userId, beamsTokenProvider))
        .catch(console.error);
    } else {
      beamsClient.stop();
    }
  } catch (err) {
    console.log(err);
  }
};
export const firebaseConfig = {
  apiKey: "AIzaSyDPU869eqUuty2CTXkoTOgx_RZJPhSBJYg",
  authDomain: "onechanzo-6d56b.firebaseapp.com",
  projectId: "onechanzo-6d56b",
  storageBucket: "onechanzo-6d56b.appspot.com",
  messagingSenderId: "907214282617",
  appId: "1:907214282617:web:1aa0273b7888f6936659a8",
  measurementId: "G-Q55G4QSPEG",
  databaseURL: "https://onechanzo-6d56b-default-rtdb.firebaseio.com",
};
export const vapidKey =
  "BKA2ss2uuxnlrYwo4EKxA1la5_d2ZvKrEnM2Ip9QOYo0qJsYOpUrlarB46F_Lt4Oq5hF-qqA9qDjUCQkdvlVB4Y";
export const zegoAppID = 1034371573;
export const zegoServer = "5dfc7cdb82f8af90eee34e5f8636466d";
