import PropTypes from "prop-types";
import * as Yup from "yup";
import { forwardRef, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import Draggable from "react-draggable";
// material
import {
  AppBar,
  Toolbar,
  Card,
  IconButton,
  Slide,
  InputAdornment,
  Typography,
  Stack,
  Dialog,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Label from "../../Label";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { QUESTION_TYPES } from "../../../utils/apis";

AssignmentQuestions.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  fetch: PropTypes.func,
  sections: PropTypes.array,
  index: PropTypes.number,
  uid: PropTypes.string,
};
const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  title: PropTypes.string,
};
function DeleteModal({ open, title, handleClose, handleDelete }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "error.main" }}>
        Please confirm you want to remove this question, {title}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please note that all the answers attached to this question will be
          removed as well.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AssignmentQuestions({
  open,
  sections,
  index,
  handleClose,
  fetch,
  uid,
}) {
  const section = open
    ? sections[index]
    : {
        questions: [
          {
            question: "",
            score: 1,
            type: "single",
            answers: [{ answer: "", correct: 0 }],
          },
        ],
      };
  const { updateAssignmentSection } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const nodeRef = useRef(null);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const initialValues = {
    questions:
      section.questions.length > 0
        ? section.questions
        : [
            {
              question: "",
              score: 1,
              type: "single",
              answers: [{ answer: "", correct: 0 }],
            },
          ],
  };
  const Schema = Yup.object().shape({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const noQuestions = values.questions.filter(
          (question) => question.question === ""
        );
        if (noQuestions.length === 0) {
          sections[index].questions = values.questions;
          await updateAssignmentSection(JSON.stringify(sections), uid);
          enqueueSnackbar("Questions has been updated!", {
            variant: "success",
          });
          setSubmitting(false);
          resetForm();
          handleClose();
          fetch();
        } else {
          enqueueSnackbar(
            "There is question with no text written! Please confirm and try again.",
            { variant: "error" }
          );
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });
  const { handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } =
    formik;
  const handleAddQuestion = () => {
    const { questions } = values;
    questions.push({
      question: "",
      score: 1,
      type: "single",
      answers: [{ answer: "", correct: 0 }],
    });
    setFieldValue(`questions`, questions);
  };
  const handleAddAnswer = (index) => {
    const { questions } = values;
    questions[index].answers.push({ answer: "", correct: 0 });
    setFieldValue(`questions`, questions);
  };
  const handleRemoveAnswer = (index, i) => {
    const { questions } = values;
    questions[index].answers.splice(i, 1);
    setFieldValue(`questions`, questions);
  };
  const proceedQuestionDelete = (index) => {
    if (deleteIndex > -1 || index > -1) {
      const { questions } = values;
      questions.splice(deleteIndex > -1 ? deleteIndex : index, 1);
      setFieldValue(`questions`, questions);
      setDeleteTitle("");
      setDeleteIndex(-1);
      setOpenDelete(false);
    } else {
      enqueueSnackbar(`Please select a valid question to remove!`, {
        variant: "error",
      });
    }
  };
  const handleQuestionDelete = (index, title) => {
    if (title === "") {
      proceedQuestionDelete(index);
    } else {
      setDeleteTitle(title);
      setDeleteIndex(index);
      setOpenDelete(true);
    }
  };
  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <AppBar position="relative" sx={{ bgcolor: "primary.main" }}>
            <Toolbar>
              <IconButton color="inherit" edge="start" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
                {section.title}
              </Typography>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                color="secondary"
              >
                Save
              </LoadingButton>
            </Toolbar>
          </AppBar>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Stack direction="column" spacing={3}>
              {values.questions.map((question, index) => (
                <Card sx={{ p: 3 }} key={index}>
                  <Stack direction="column" spacing={4}>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="space-between"
                      spacing={2}
                      alignItems="center"
                    >
                      <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
                        Question {index + 1}
                      </Typography>
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        spacing={2}
                        alignItems="center"
                      >
                        <TextField
                          select
                          sx={{ width: { xs: "100%", md: 200 } }}
                          label="Type"
                          className="fix-mui-padding"
                          value={question.type}
                          onChange={(event) => {
                            const type = event.target.value;
                            setFieldValue(`questions[${index}].type`, type);
                            if (type === "essay") {
                              setFieldValue(`questions[${index}].answers`, []);
                            }
                            if (
                              type !== "essay" &&
                              question.answers.length === 0
                            ) {
                              handleAddAnswer(index);
                            }
                          }}
                        >
                          {QUESTION_TYPES.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          type="number"
                          label="Score"
                          {...getFieldProps(`questions[${index}].score`)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LanguageIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      alignItems="center"
                    >
                      <TextField
                        fullWidth
                        label="Question"
                        {...getFieldProps(`questions[${index}].question`)}
                      />
                      <IconButton
                        color="error"
                        edge="start"
                        onClick={() =>
                          handleQuestionDelete(index, question.question)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>

                    {question.answers.length > 0 && (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center"
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ flex: 1, color: "text.disabled" }}
                        >
                          Possible answers
                        </Typography>
                        <Button
                          startIcon={<AddIcon />}
                          color="primary"
                          onClick={() => {
                            handleAddAnswer(index);
                          }}
                        >
                          Add Answer
                        </Button>
                      </Stack>
                    )}

                    {values.questions[index].answers.map((answer, i) => (
                      <Stack direction="row" spacing={2} key={i}>
                        <TextField
                          sx={{ width: { xs: "100%", md: 500 } }}
                          label={`Answer ${i + 1}`}
                          {...getFieldProps(
                            `questions[${index}].answers[${i}].answer`
                          )}
                        />
                        <IconButton
                          color="error"
                          edge="start"
                          onClick={() => handleRemoveAnswer(index, i)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              color="primary"
                              checked={
                                values.questions[index].answers[i].correct === 1
                              }
                              onChange={(event) => {
                                const correct = event.target.checked ? 1 : 0;
                                setFieldValue(
                                  `questions[${index}].answers[${i}].correct`,
                                  correct
                                );
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Mark as correct"
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Card>
              ))}
            </Stack>
            {values.questions.filter((question) => question.question !== "")
              .length > 0 && (
              <Card sx={{ p: 2, maxWidth: { md: 500, xs: "100%" } }}>
                <Typography
                  variant="h6"
                  sx={{ flex: 1, mb: 2, textAlign: "center" }}
                >
                  Summary
                </Typography>
                <Draggable
                  axis="y"
                  defaultPosition={{ x: 0, y: 0 }}
                  grid={[25, 25]}
                  position={null}
                  scale={1}
                  nodeRef={nodeRef}
                >
                  <Stack direction="column" spacing={2}>
                    {values.questions
                      .filter((question) => question.question !== "")
                      .map((question, index) => (
                        <Stack
                          direction="row"
                          spacing={2}
                          key={index}
                          alignItems="center"
                          justifyContent="start"
                        >
                          <IconButton
                            edge="start"
                            onClick={handleClose}
                            sx={{ cursor: "move" }}
                          >
                            <DragIndicatorIcon />
                          </IconButton>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                              backgroundColor: "background.neutral",
                              py: 1,
                              px: 2,
                              borderRadius: 1,
                              flex: 1,
                            }}
                            alignItems="center"
                          >
                            <Typography variant="subtitle2" noWrap>
                              {question.question}
                            </Typography>
                            <Label color="primary">{question.score}</Label>
                          </Stack>
                          <IconButton
                            color="error"
                            edge="start"
                            onClick={() =>
                              handleQuestionDelete(index, question.question)
                            }
                            sx={{ flexShrink: 0 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                  </Stack>
                </Draggable>
              </Card>
            )}
          </Stack>
        </Form>
      </FormikProvider>
      <DeleteModal
        open={openDelete}
        handleClose={() => {
          setOpenDelete(false);
        }}
        handleDelete={proceedQuestionDelete}
        title={deleteTitle}
      />
      <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddQuestion}
          variant="contained"
          color="primary"
        >
          Add Question
        </Button>
      </DialogActions>
    </Dialog>
  );
}
