// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  adminLogin: path(ROOTS_AUTH, "admin-login"),
  studentLogin: path(ROOTS_AUTH, "student-login"),
  facultyLogin: path(ROOTS_AUTH, "faculty-login"),
  signup: path(ROOTS_AUTH, "signup"),
  welcome: path(ROOTS_AUTH, "welcome"),
  forgotPassword: path(ROOTS_AUTH, "forgot-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  verify: path(ROOTS_AUTH, "verify"),
  setPassword: path(ROOTS_AUTH, "set-password"),
};

export const PATH_PAGE = {
  about: "about",
  faqs: "faqs",
  page404: "404",
  errorOccured: "500",
  domainError: "domain-error",
  support: "https://support.onechanzo.com",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    profile: path(ROOTS_DASHBOARD, "profile"),
    settings: path(ROOTS_DASHBOARD, "settings"),
    paymentAnalytics: path(ROOTS_DASHBOARD, "payment-analytics"),
    admins: path(ROOTS_DASHBOARD, "administrators"),
    admin: path(ROOTS_DASHBOARD, "administrator"),
    students: path(ROOTS_DASHBOARD, "students"),
    lecturers: path(ROOTS_DASHBOARD, "lecturers"),
    courses: path(ROOTS_DASHBOARD, "courses"),
    faculties: path(ROOTS_DASHBOARD, "faculties"),
    departments: path(ROOTS_DASHBOARD, "departments"),
    calendar: path(ROOTS_DASHBOARD, "calendar"),
    roles: path(ROOTS_DASHBOARD, "roles"),
    addAdmin: path(ROOTS_DASHBOARD, "administrators/add"),
    addAssignment: path(ROOTS_DASHBOARD, "assignments/add"),
    addGrade: path(ROOTS_DASHBOARD, "grades/add"),
    addStudent: path(ROOTS_DASHBOARD, "students/add"),
    addLecturer: path(ROOTS_DASHBOARD, "lecturers/add"),
    addFaculty: path(ROOTS_DASHBOARD, "faculties/add"),
    addDepartment: path(ROOTS_DASHBOARD, "departments/add"),
    addCourse: path(ROOTS_DASHBOARD, "courses/add"),
    courseReg: path(ROOTS_DASHBOARD, "courses/register"),
    inbox: path(ROOTS_DASHBOARD, "inbox"),
    mailbox: path(ROOTS_DASHBOARD, "mailbox"),
    payments: path(ROOTS_DASHBOARD, "payments"),
    grades: path(ROOTS_DASHBOARD, "grades"),
    assignments: path(ROOTS_DASHBOARD, "assignments"),
    paymentHistory: path(ROOTS_DASHBOARD, "payment-history"),
    testExams: path(ROOTS_DASHBOARD, "test-exams"),
    lectures: path(ROOTS_DASHBOARD, "lectures"),
    addPayment: path(ROOTS_DASHBOARD, "payments/add"),
    addLecture: path(ROOTS_DASHBOARD, "lectures/add"),
    addEvent: path(ROOTS_DASHBOARD, "calendar/add"),
    addTestExams: path(ROOTS_DASHBOARD, "test-exams/add"),
    export: path(ROOTS_DASHBOARD, "export"),
    import: path(ROOTS_DASHBOARD, "import"),
    noticeBoard: path(ROOTS_DASHBOARD, "notice-board"),
    addNoticeBoard: path(ROOTS_DASHBOARD, "notice-board/add"),
    takeTest: path(ROOTS_DASHBOARD, "take-test/"),
    takeAssignment: path(ROOTS_DASHBOARD, "take-assignment/"),
    takeLecture: path(ROOTS_DASHBOARD, "live/"),
  },
  subpages: {
    admin: path(ROOTS_DASHBOARD, "administrator"),
    grade: path(ROOTS_DASHBOARD, "grade"),
    role: path(ROOTS_DASHBOARD, "role"),
    student: path(ROOTS_DASHBOARD, "student"),
    lecturer: path(ROOTS_DASHBOARD, "lecturer"),
    faculty: path(ROOTS_DASHBOARD, "faculty"),
    department: path(ROOTS_DASHBOARD, "department"),
    course: path(ROOTS_DASHBOARD, "course"),
    payment: path(ROOTS_DASHBOARD, "payment"),
    lecture: path(ROOTS_DASHBOARD, "lecture"),
    test: path(ROOTS_DASHBOARD, "test"),
    assignment: path(ROOTS_DASHBOARD, "assignment"),
  },
};

export const frontendPages = [
  "/admin-login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/404",
  "/support",
  "/faqs",
  "/error-occured",
  "/welcome",
  "/student-login",
  "/faculty-login",
  "/domain-error-occured",
];
export const adminPages = [
  "/dashboard",
  "/settings",
  "/administrators",
  "/administrator",
  "/add-administrator",
  "/roles",
  "/role",
  "/add-role",
  "/profile",
  "/add-student",
  "/view-student",
  "/students",
  "/mailbox",
  "/students",
  "/lecturers",
  "/faculties",
  "/departments",
  "/add-faculty",
  "/add-department",
  "/add-course",
  "/calendar",
  "/add-lecturer",
  "/add-event",
  "/courses",
  "/lecture",
  "/lectures",
  "/test",
  "/test-exams",
];
export const facultyPages = [
  "/dashboard",
  "/set-password",
  "/add-event",
  "/courses",
  "/payments",
  "/exams",
  "/students",
  "/lecture",
  "/lectures",
  "/student",
  "/course",
  "/calendar",
  "/payment-history",
  "/test-exams",
  "/test",
  "/notice-board",
  "/notice-board/add",
  "/grades",
  "/grade",
  "/assignments",
  "/assignment",
  "/inbox",
];
export const facultyDemoPages = [
  "/dashboard",
  "/set-password",
  "/courses",
  "/payments",
  "/exams",
  "/students",
  "/lecture",
  "/lectures",
  "/student",
  "/course",
  "/calendar",
  "/payment-history",
  "/test-exams",
  "/test",
  "/notice-board",
  "/grades",
  "/grade",
  "/assignments",
  "/assignment",
  "/inbox",
  "/live",
];
export const studentPages = [
  "/dashboard",
  "/set-password",
  "/calendar",
  "/courses",
  "/payments",
  "/add-event",
  "/exams",
  "/quiz",
  "/lecture",
  "/lectures",
  "/test",
  "/course",
  "/department",
  "/lecturer",
  "/test-exams",
  "/test",
  "/payment-history",
  "/payments",
  "/notice-board",
  "/grades",
  "/grade",
  "/assignments",
  "/assignment",
  "/live",
];
