import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Card, Alert, TextField, Stack, CardHeader, Box, Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------
RoleUpdate.propTypes = {
    myProfile: PropTypes.object,
    rid: PropTypes.string,
    fetch: PropTypes.func,
};
export default function RoleUpdate({ myProfile, rid, fetch }) {
    const { updateRole, getPermissions, user } = useAuth();
    const permissions = user.user && user.user.role.permissions !== "" ? user.user.role.permissions.split(",") : [];
    const hasPermission = permissions.includes("update_administrative_role");
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [availablePermissions, setAvailablePermissions] = useState([]);

    useEffect(() => {
        async function fetchPermissions() {
            setAvailablePermissions(await getPermissions());
        }
        fetchPermissions();
    }, [getPermissions]);

    const Schema = Yup.object().shape({
        title: Yup.string().required('Role title is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: myProfile.title,
            description: myProfile.description,
            permissions: myProfile.permissions.split(",")
        },
        validationSchema: Schema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                await updateRole(values, rid);
                if (isMountedRef.current) {
                    enqueueSnackbar('Role\'s details has been updated!', { variant: 'success' });
                    setSubmitting(false);
                }
                fetch();
            } catch (error) {
                if (isMountedRef.current) {
                    setErrors({ afterSubmit: error.message });
                    setSubmitting(false);
                }
            }
        }
    });
    const handlePermSelected = (event) => {
        const perm = event.target.value;
        const perms = values.permissions;
        if (event.target.checked && !perms.includes(perm)) {
            perms.push(perm);
        }

        if (!event.target.checked && perms.includes(perm)) {
            const index = perms.indexOf(perm);
            if (index > -1) {
                perms.splice(index, 1);
            }
        }
        setFieldValue("permissions", perms);
    }

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

    return (
        <Card sx={{ px: 3, pb: 3 }}>
            <CardHeader title="Role Information" />
            <Box sx={{ p: 3 }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                            <TextField
                                fullWidth
                                autoComplete="username"
                                label="Title"
                                {...getFieldProps('title')}
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                                disabled={!hasPermission}
                            />
                            <TextField
                                fullWidth
                                autoComplete="username"
                                label="Description"
                                {...getFieldProps('description')}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                                disabled={!hasPermission}
                            />
                            <Typography variant="subtitle2" sx={{ mt: 2 }} noWrap>Permissions</Typography>
                            {availablePermissions.map((permission) => (
                                <FormGroup key={permission}>
                                    <FormControlLabel control={<Checkbox value={permission} onChange={handlePermSelected} checked={values.permissions.includes(permission)} disabled={!hasPermission} />} label={sentenceCase(permission)} />
                                </FormGroup>
                            ))}
                        </Stack>
                        {hasPermission && (<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                            Update
                        </LoadingButton>)}
                    </Form>
                </FormikProvider>
            </Box>
        </Card>
    );
}
