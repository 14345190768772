import { paramCase } from "change-case";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import WcIcon from "@mui/icons-material/Wc";
import BadgeIcon from "@mui/icons-material/Badge";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Label from "../../Label";
import { ADMIN_STATUS } from "../../../utils/apis";
import { isNumeric, fNumber, fCurrency } from "../../../utils/formatNumber";
import { fDate } from "../../../utils/formatTime";
import { PATH_DASHBOARD } from "../../../routes/paths";
import useAuth from "../../../hooks/useAuth";

LecturerAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog open={open} sx={{ textAlign: "center" }}>
      <DialogTitle>Change Lecturer's Account Status</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 500 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          <MenuItem value="0">Suspend</MenuItem>
          <MenuItem value="1">Activate</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleStatusUpdate}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default function LecturerAbout({ profile, uid, fetch }) {
  const { user, usertype } = useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_lecturer");
  const {
    numCourses,
    salary,
    gender,
    email,
    employDate,
    phone,
    dateAdded,
    dateUpdated,
    status,
    faculty,
    department,
    departmentName,
    facultyName,
  } = profile;
  const statusText = ADMIN_STATUS[parseInt(status, 10)];
  const { updateLecturerStatus } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateLecturerStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Lecturer's status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };

  return (
    <Card>
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <BadgeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Label color={statusText.value}>{statusText.label}</Label>
            {hasPermission && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        {phone && (
          <Stack direction="row">
            <PhoneAndroidIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.student}`}
              >
                {phone}
              </Link>
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <EmailIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            {(usertype === 0 && (
              <Link
                variant="subtitle2"
                color="text.primary"
                href={`mailto:${email}`}
              >
                {email}
              </Link>
            )) || (
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.general.mailbox}/send/${email}`}
              >
                {email}
              </Link>
            )}
          </Typography>
        </Stack>
        {facultyName !== "" && (
          <Stack direction="row">
            <LocalLibraryIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">Faculty:&nbsp;</Typography>
            <Typography variant="body2">
              {(usertype === 0 && <strong>{facultyName}</strong>) || (
                <Link
                  variant="subtitle2"
                  color="text.primary"
                  component={RouterLink}
                  to={`${
                    PATH_DASHBOARD.subpages.faculty
                  }/${faculty}/${paramCase(facultyName)}`}
                >
                  {facultyName}
                </Link>
              )}
            </Typography>
          </Stack>
        )}
        {departmentName !== "" && (
          <Stack direction="row">
            <LocalLibraryIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">Department:&nbsp;</Typography>
            <Typography variant="body2">
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${
                  PATH_DASHBOARD.subpages.department
                }/${department}/${paramCase(departmentName)}`}
              >
                {departmentName}
              </Link>
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <CorporateFareIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Assigned{" "}
            <strong>
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.department}`}
              >
                {fNumber(numCourses)}
              </Link>{" "}
              course(s)
            </strong>
          </Typography>
        </Stack>
        {usertype !== 0 && (
          <>
            <Stack direction="row">
              <WcIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                {gender
                  ? (gender === "M" && "Male") || "Female"
                  : "Not Specified"}
              </Typography>
            </Stack>
            <Stack direction="row">
              <CreditCardIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginTop: "2px",
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              <Typography variant="body2">
                <strong>
                  {(isNumeric(salary) && fCurrency(salary)) || "Not Specified"}
                </strong>
              </Typography>
            </Stack>
            {employDate && employDate !== "" && (
              <Stack direction="row">
                <CalendarTodayIcon
                  sx={{
                    width: 20,
                    height: 20,
                    marginTop: "2px",
                    flexShrink: 0,
                    marginRight: 2,
                  }}
                />
                <Typography variant="body2">
                  Employed on {fDate(employDate)}
                </Typography>
              </Stack>
            )}
            {dateAdded && (
              <Stack direction="row">
                <CalendarTodayIcon
                  sx={{
                    width: 20,
                    height: 20,
                    marginTop: "2px",
                    flexShrink: 0,
                    marginRight: 2,
                  }}
                />
                <Typography variant="body2">
                  Added on{" "}
                  {dateAdded !== "0000-00-00"
                    ? fDate(dateAdded)
                    : "Not Specified"}
                </Typography>
              </Stack>
            )}
            {dateUpdated && (
              <Stack direction="row">
                <CalendarTodayIcon
                  sx={{
                    width: 20,
                    height: 20,
                    marginTop: "2px",
                    flexShrink: 0,
                    marginRight: 2,
                  }}
                />
                <Typography variant="body2">
                  Last updated on{" "}
                  {dateUpdated !== "0000-00-00"
                    ? fDate(dateUpdated)
                    : "Not Specified"}
                </Typography>
              </Stack>
            )}
          </>
        )}
      </Stack>
      <ChangeStatus
        open={open}
        loading={loading}
        handleStatus={handleStatus}
        status={newStatus}
        handleStatusUpdate={handleStatusUpdate}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Card>
  );
}
