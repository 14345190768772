import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { paramCase } from "change-case";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import Countdown from "react-countdown";
import { differenceInCalendarDays, differenceInSeconds } from "date-fns";
import {
  Link,
  Card,
  Typography,
  CardHeader,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import BookIcon from "@mui/icons-material/Book";
import NoteIcon from "@mui/icons-material/Note";
import Label from "../../Label";
import { fNumber, isNumeric } from "../../../utils/formatNumber";
import { ASSETS_PATH, LECTURE_STATUS } from "../../../utils/apis";
import { fDateTime, fDate, fTimestamp } from "../../../utils/formatTime";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

LectureAbout.propTypes = {
  profile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};

ChangeStatus.propTypes = {
  handleStatusUpdate: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleStatus: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.string,
};
function ChangeStatus({
  open,
  loading,
  handleStatusUpdate,
  handleStatus,
  status,
  onClose,
}) {
  return (
    <Dialog open={open} sx={{ textAlign: "center" }}>
      <DialogTitle>Change Lecture's Status</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Please set the new status below
        </DialogContentText>
        <TextField
          select
          sx={{ width: { xs: "100%", md: 500 } }}
          label="New Status"
          value={status}
          onChange={handleStatus}
        >
          {LECTURE_STATUS.map((status, index) => (
            <MenuItem value={status.input} key={index}>
              {status.label}
            </MenuItem>
          ))}
        </TextField>
        <Typography
          sx={{ display: "block", color: "error.main", mt: 2 }}
          variant="caption"
        >
          * Please note that the status of this lecture will apply to all the
          students assigned to it.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleStatusUpdate}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default function LectureAbout({ profile, uid, fetch }) {
  const { user, usertype } = useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_lecture");
  const {
    date,
    time,
    title,
    lecturer,
    level,
    unit,
    numMaterials,
    status,
    dateAdded,
    dateUpdated,
    lecturerName,
    courseName,
    department,
    departmentName,
    description,
    type,
    course,
    note,
    duration,
    durationText,
  } = profile;
  const [elapsed, setElapsed] = useState(0);
  const [elapsedDate, setElapsedDate] = useState("");
  const statusText = LECTURE_STATUS[parseInt(status, 10)];
  const { updateLectureStatus } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleStatus = (event) => {
    setNewStatus(event.target.value);
  };
  const handleStatusUpdate = async () => {
    if (newStatus) {
      try {
        setLoading(true);
        await updateLectureStatus(newStatus, uid);
        setOpen(false);
        setNewStatus("");
        enqueueSnackbar("Lecture's status has been updated!", {
          variant: "success",
        });
        fetch();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar(`Please select a new status to proceed!`, {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    try {
      const differenceDate =
        (date &&
          time &&
          differenceInCalendarDays(
            fTimestamp(`${date} ${time}`),
            new Date().getTime()
          )) ||
        0;
      if (differenceDate > 0) {
        setElapsed(differenceDate);
        setElapsedDate(differenceDate > 1 ? "days" : "day");
      } else {
        const differenceTime =
          (date &&
            time &&
            differenceInSeconds(
              fTimestamp(`${date} ${time}`),
              new Date().getTime()
            )) ||
          0;
        const hours = parseInt(differenceTime / 3600, 10);
        const minutes = parseInt(differenceTime / 60, 10);
        const timeElapsed =
          fTimestamp(`${date} ${time}`) + parseInt(duration, 10) * 60 * 1000;
        if (timeElapsed > new Date().getTime()) {
          if (hours > 0) {
            setElapsed(hours);
            setElapsedDate(hours > 1 ? "hours" : "hour");
          } else if (minutes > 0) {
            setElapsed(minutes);
            setElapsedDate(minutes > 1 ? "minutes" : "minute");
          } else {
            setElapsed(0);
            setElapsedDate("soon");
          }
        } else {
          setElapsed(-1);
          setElapsedDate("");
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [date, time, duration]);
  console.log(elapsed, elapsedDate);
  return (
    <Card>
      <CardHeader title="Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body2">{description}</Typography>
        <Stack direction="row">
          <BadgeIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <Label color={statusText.value}>{statusText.label}</Label>
            {hasPermission && (
              <span>
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                <Link
                  variant="subtitle2"
                  color="error.main"
                  component="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Change
                </Link>
              </span>
            )}
          </Typography>
        </Stack>
        <Stack direction="row">
          <BookIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Level / Class: <strong>{level}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <BookIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Course Unit: <strong>{unit}</strong>
          </Typography>
        </Stack>
        {lecturerName !== "" && (
          <Stack direction="row">
            <SchoolIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Taught by{" "}
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${
                  PATH_DASHBOARD.subpages.lecturer
                }/${lecturer}/${paramCase(lecturerName)}`}
              >
                {lecturerName}
              </Link>
            </Typography>
          </Stack>
        )}
        {courseName !== "" && (
          <Stack direction="row">
            <LocalLibraryIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.course}/${course}/${paramCase(
                  courseName
                )}`}
              >
                {courseName}
              </Link>
            </Typography>
          </Stack>
        )}
        {departmentName !== "" && (
          <Stack direction="row">
            <LocalLibraryIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              <Link
                variant="subtitle2"
                color="text.primary"
                component={RouterLink}
                to={`${
                  PATH_DASHBOARD.subpages.department
                }/${department}/${paramCase(departmentName)}`}
              >
                {departmentName}
              </Link>
            </Typography>
          </Stack>
        )}
        <Stack direction="row">
          <BookIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            <strong>
              {parseInt(type, 10) === 1
                ? "LIVE"
                : `Has ${
                    isNumeric(numMaterials) ? fNumber(numMaterials) : "0"
                  } material(s)`}
            </strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Date/Time:{" "}
            {date !== "0000-00-00"
              ? fDateTime(`${date} ${time}`)
              : "Not Specified"}
          </Typography>
        </Stack>
        <Stack direction="row">
          <BookIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Duration: <strong>{durationText}</strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <NoteIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Lecture Note:&nbsp;&nbsp;&nbsp;
            <strong>
              {note ? (
                <Link
                  underline="none"
                  href={`${ASSETS_PATH.notes}${note}`}
                  target="_blank"
                >
                  Download
                </Link>
              ) : (
                "None"
              )}
            </strong>
          </Typography>
        </Stack>
        <Stack direction="row">
          <CalendarTodayIcon
            sx={{
              width: 20,
              height: 20,
              marginTop: "2px",
              flexShrink: 0,
              marginRight: 2,
            }}
          />
          <Typography variant="body2">
            Added on{" "}
            {dateAdded !== "0000-00-00" ? fDate(dateAdded) : "Not Specified"}
          </Typography>
        </Stack>
        {dateUpdated && (
          <Stack direction="row">
            <CalendarTodayIcon
              sx={{
                width: 20,
                height: 20,
                marginTop: "2px",
                flexShrink: 0,
                marginRight: 2,
              }}
            />
            <Typography variant="body2">
              Last updated on{" "}
              {dateUpdated !== "0000-00-00"
                ? fDate(dateUpdated)
                : "Not Specified"}
            </Typography>
          </Stack>
        )}
        {parseInt(type, 10) === 1 && (
          <Countdown
            date={`${date} ${time}`}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                // Render a completed state
                return (
                  <Box sx={{ pt: 5 }}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="info"
                      component={RouterLink}
                      to={`${
                        PATH_DASHBOARD.general.takeLecture
                      }${uid}/${paramCase(title)}`}
                    >
                      {usertype === 0 ? "Join" : "Start"} Lecture
                    </Button>
                  </Box>
                );
              }
              // Render a countdown
              return (
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  sx={{
                    backgroundColor: "background.neutral",
                    border: "1px solid",
                    borderColor: "text.secondary",
                    borderRadius: 1,
                    p: 2,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "text.disabled" }}
                  >
                    Time to Live:
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ py: 2, px: 4 }}
                    divider={
                      <Typography
                        variant="body1"
                        sx={{ color: "text.disabled" }}
                      >
                        &nbsp;&nbsp;:&nbsp;&nbsp;
                      </Typography>
                    }
                  >
                    <Typography variant="h4" sx={{ color: "text.secondary" }}>
                      {days}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {days > 1 ? "days" : "day"}
                      </Typography>
                    </Typography>
                    <Typography variant="h4" sx={{ color: "text.secondary" }}>
                      {hours}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {hours > 1 ? "hours" : "hour"}
                      </Typography>
                    </Typography>
                    <Typography variant="h4" sx={{ color: "text.secondary" }}>
                      {minutes}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {minutes > 1 ? "minutes" : "minute"}
                      </Typography>
                    </Typography>
                    <Typography variant="h4" sx={{ color: "text.disabled" }}>
                      {seconds}
                      <Typography
                        variant="span"
                        sx={{ color: "text.disabled", fontSize: 10 }}
                      >
                        {seconds > 1 ? "seconds" : "second"}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
              );
            }}
          />
        )}
      </Stack>
      <ChangeStatus
        open={open}
        loading={loading}
        handleStatus={handleStatus}
        status={newStatus}
        handleStatusUpdate={handleStatusUpdate}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Card>
  );
}
