import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  MenuItem,
  FormControlLabel,
  Switch,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { STATES } from "../../../utils/apis";

// ----------------------------------------------------------------------
const GENDER = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
const MARITAL = [
  { label: "Single", value: "S" },
  { label: "Married", value: "M" },
  { label: "Engaged", value: "E" },
];
StudentUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function StudentUpdate({ myProfile, uid, fetch }) {
  const { updateStudent, user, usertype } = useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_student");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const StudentSchema = Yup.object().shape({
    admission_no: Yup.string().required("Admission number is required"),
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    gender: Yup.string().required("Gender is required"),
  });

  const formik = useFormik({
    initialValues: {
      fname: myProfile.fname,
      lname: myProfile.lname,
      phone: myProfile.phone,
      admitted: myProfile.dateRegistered,
      gender: myProfile.gender,
      matric: myProfile.matric,
      admission_no: myProfile.admissionNo,
      dob: myProfile.dob,
      stateOfOrigin: myProfile.stateOfOrigin,
      birthplace: myProfile.birthplace,
      marital: myProfile.marital,
      maiden: myProfile.maiden,
      isIndigene: parseInt(myProfile.isIndigene, 10),
    },
    validationSchema: StudentSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateStudent(values, uid);
        enqueueSnackbar("Student's profile has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Account Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}

              <TextField
                fullWidth
                autoComplete="username"
                disabled={!hasPermission}
                label="Admission Number"
                {...getFieldProps("admission_no")}
                error={Boolean(touched.admission_no && errors.admission_no)}
                helperText={touched.admission_no && errors.admission_no}
              />
              <TextField
                fullWidth
                autoComplete="username"
                disabled={!hasPermission}
                label="Matric/Student ID"
                {...getFieldProps("matric")}
                error={Boolean(touched.matric && errors.matric)}
                helperText={touched.matric && errors.matric}
              />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  label="First Name"
                  disabled={!hasPermission}
                  {...getFieldProps("fname")}
                  error={Boolean(touched.fname && errors.fname)}
                  helperText={touched.fname && errors.fname}
                />
                <TextField
                  fullWidth
                  disabled={!hasPermission}
                  autoComplete="username"
                  label="Last Name"
                  {...getFieldProps("lname")}
                  error={Boolean(touched.lname && errors.lname)}
                  helperText={touched.lname && errors.lname}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="tel"
                  disabled={!hasPermission}
                  label="Phone number"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
                <TextField
                  select
                  sx={{ width: { xs: "100%", md: 500 } }}
                  label="Gender"
                  className="fix-mui-padding"
                  disabled={!hasPermission}
                  value={values.gender}
                  onChange={(event) => {
                    setFieldValue("gender", event.target.value);
                  }}
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                >
                  {GENDER.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Date Admitted"
                  {...getFieldProps("admitted")}
                  error={Boolean(touched.admitted && errors.admitted)}
                  helperText={touched.admitted && errors.admitted}
                />
                <TextField
                  select
                  sx={{ width: { xs: "100%", md: 500 } }}
                  label="Marital Status"
                  disabled={!hasPermission}
                  value={values.marital}
                  onChange={(event) => {
                    setFieldValue("marital", event.target.value);
                  }}
                  error={Boolean(touched.marital && errors.marital)}
                  helperText={touched.marital && errors.marital}
                >
                  {MARITAL.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Maiden name"
                  {...getFieldProps("maiden")}
                  error={Boolean(touched.maiden && errors.maiden)}
                  helperText={touched.maiden && errors.maiden}
                />
                <Autocomplete
                  fullWidth
                  options={STATES}
                  {...getFieldProps("stateofOrigin")}
                  name="stateofOrigin"
                  value={values.stateOfOrigin}
                  disabled={!hasPermission}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue("stateOfOrigin", newValue);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State of Origin"
                      margin="none"
                      error={Boolean(
                        touched.stateOfOrigin && errors.stateOfOrigin
                      )}
                      helperText={touched.stateOfOrigin && errors.stateOfOrigin}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Date of Birth"
                  {...getFieldProps("dob")}
                  error={Boolean(touched.dob && errors.dob)}
                  helperText={touched.dob && errors.dob}
                />
                <TextField
                  disabled={!hasPermission}
                  fullWidth
                  label="Place of Birth"
                  {...getFieldProps("birthplace")}
                  error={Boolean(touched.birthplace && errors.birthplace)}
                  helperText={touched.birthplace && errors.birthplace}
                />
              </Stack>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isIndigene === 1}
                    {...getFieldProps("isIndigene")}
                    onChange={(e, checked) => {
                      setFieldValue("isIndigene", checked ? 1 : 0);
                    }}
                  />
                }
                label="This student is an indigene."
              />
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
