import PropTypes from "prop-types";
// hooks
import { useLocation, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {
  PATH_DASHBOARD,
  facultyDemoPages,
  facultyPages,
  studentPages,
} from "../routes/paths";
import NotFound from "../pages/errors/NotFound";
import PermDenied from "../pages/errors/PermDenied";
import Profile from "../pages/dashboard/Profile";
// ----------------------------------------------------------------------

const PAGE_PERMS = [
  { path: PATH_DASHBOARD.root, permission: "view_dashboard_analytics" },
  {
    path: PATH_DASHBOARD.general.settings,
    permission: "view_and_update_system_settings",
  },
  { path: PATH_DASHBOARD.general.admins, permission: "view_administrators" },
  {
    path: PATH_DASHBOARD.general.roles,
    permission: "view_administrative_roles",
  },
  { path: PATH_DASHBOARD.general.inbox, permission: "view_and_send_messages" },
  {
    path: PATH_DASHBOARD.general.mailbox,
    permission: "view_and_send_messages",
  },
  {
    path: `${PATH_DASHBOARD.general.mailbox}/send`,
    permission: "view_and_send_messages",
  },
  { path: PATH_DASHBOARD.general.students, permission: "view_students" },
  { path: PATH_DASHBOARD.general.lecturers, permission: "view_lecturers" },
  { path: PATH_DASHBOARD.general.faculties, permission: "view_faculties" },
  { path: PATH_DASHBOARD.general.departments, permission: "view_departments" },
  { path: PATH_DASHBOARD.general.courses, permission: "view_courses" },
  { path: PATH_DASHBOARD.general.lectures, permission: "view_lectures" },
  {
    path: PATH_DASHBOARD.general.testExams,
    permission: "view_tests_and_exams",
  },
  { path: PATH_DASHBOARD.general.payments, permission: "view_payments" },
  { path: PATH_DASHBOARD.general.export, permission: "view_and_export_data" },
  { path: PATH_DASHBOARD.general.import, permission: "import_data" },
  {
    path: PATH_DASHBOARD.general.paymentHistory,
    permission: "view_payments_history",
  },
  { path: PATH_DASHBOARD.general.calendar, permission: "view_calendar" },
  { path: PATH_DASHBOARD.general.addAdmin, permission: "add_administrator" },
  { path: PATH_DASHBOARD.general.addStudent, permission: "add_student" },
  { path: PATH_DASHBOARD.general.addLecturer, permission: "add_lecturer" },
  { path: PATH_DASHBOARD.general.addFaculty, permission: "add_faculty" },
  { path: PATH_DASHBOARD.general.addDepartment, permission: "add_department" },
  { path: PATH_DASHBOARD.general.addCourse, permission: "add_course" },
  { path: PATH_DASHBOARD.general.addLecture, permission: "add_lecture" },
  { path: PATH_DASHBOARD.general.addEvent, permission: "add_calendar_events" },
  {
    path: PATH_DASHBOARD.general.addTestExams,
    permission: "add_tests_or_exams",
  },
  {
    path: PATH_DASHBOARD.general.addAssignment,
    permission: "add_assignment",
  },
  { path: PATH_DASHBOARD.general.addPayment, permission: "add_payment" },
  { path: PATH_DASHBOARD.subpages.admin, permission: "view_administrators" },
  {
    path: PATH_DASHBOARD.subpages.role,
    permission: "view_administrative_roles",
  },
  { path: PATH_DASHBOARD.subpages.student, permission: "view_students" },
  { path: PATH_DASHBOARD.subpages.lecturer, permission: "view_lecturers" },
  { path: PATH_DASHBOARD.subpages.faculty, permission: "view_faculties" },
  { path: PATH_DASHBOARD.subpages.department, permission: "view_departments" },
  { path: PATH_DASHBOARD.subpages.course, permission: "view_courses" },
  { path: PATH_DASHBOARD.subpages.lecture, permission: "view_lectures" },
  { path: PATH_DASHBOARD.subpages.payment, permission: "view_payments" },
  { path: PATH_DASHBOARD.subpages.test, permission: "view_tests_and_exams" },
  { path: PATH_DASHBOARD.subpages.assignment, permission: "view_assignments" },
  {
    path: PATH_DASHBOARD.general.paymentAnalytics,
    permission: "view_payment_analytics",
  },
  {
    path: PATH_DASHBOARD.general.addNoticeBoard,
    permission: "add_notice_board_message",
  },
  {
    path: PATH_DASHBOARD.general.noticeBoard,
    permission: "add_notice_board_message",
  },
  {
    path: PATH_DASHBOARD.general.grades,
    permission: "view_grades",
  },
  {
    path: PATH_DASHBOARD.subpages.grade,
    permission: "view_grades",
  },
  {
    path: `${PATH_DASHBOARD.subpages.grade}/grade`,
    permission: "view_grades",
  },
  {
    path: `${PATH_DASHBOARD.subpages.grade}/test`,
    permission: "view_grades",
  },
  {
    path: PATH_DASHBOARD.general.addGrade,
    permission: "add_grade",
  },
  {
    path: PATH_DASHBOARD.general.assignments,
    permission: "view_assignments",
  },
];

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
};
const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
};
const useCurrentRole = () => {
  const pathname = useBasePath();
  const perm = PAGE_PERMS.map((item) => {
    if (item.path === pathname) {
      return item.permission;
    }
    return "";
  }).filter((obj) => obj.length > 0);
  return perm && perm.length > 0 ? perm[0] : "no_permission";
};
export default function RoleBasedGuard({ children }) {
  const { user, usertype, isDemoDomain } = useAuth();
  const { pathname } = useLocation();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const currentPerm = useCurrentRole();
  if (currentPerm !== "" && !permissions.includes(currentPerm)) {
    if (usertype === 1) {
      if (isDemoDomain && !facultyDemoPages.includes(pathname)) {
        return <PermDenied />;
      }
      // check if is lecturer
      if (
        !isDemoDomain &&
        !facultyPages.includes(`/${pathname.split("/")[1]}`)
      ) {
        // show 404 if not lecturer page
        return <NotFound />;
      }
    } else if (usertype === 0) {
      // check if is student
      if (!studentPages.includes(`/${pathname.split("/")[1]}`)) {
        // show 404 if not student page
        return <NotFound />;
      }
    } else {
      return (
        (currentPerm === "view_dashboard_analytics" && <Profile />) || (
          <PermDenied />
        )
      );
    }
  }

  return children;
}
