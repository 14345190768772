import { Icon } from '@iconify/react';
import { capitalCase } from 'change-case';
import { useEffect, useState, useCallback } from 'react';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
// material
import { styled } from '@mui/material/styles';
import { Tab, Box, Card, Tabs, Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../Page';
import HeaderBreadcrumbs from '../../HeaderBreadcrumbs';
import { iff, CircularIndeterminate, ShowError } from '../../../utils/loader';
import {
    Profile,
    Cover
} from '../singles';

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled('div')(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
        justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(3)
    }
}));
const TYPE = 'lecturer';
export default function Lecturer() {
    const { user, getLecturer } = useAuth();
    // eslint-disable-next-line
    const uid = user.user.uid;
    const title = "My Profile";
    const { themeStretch } = useSettings();
    const [lecturer, setLecturer] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentTab, setCurrentTab] = useState('profile');

    const get = useCallback(() => {
        async function fetch() {
            try {
                setIsLoading(true);
                setLecturer(await getLecturer(uid));
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        }
        fetch();
    }, [uid, getLecturer]);

    useEffect(() => {
        get();
    }, [get]);

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const PROFILE_TABS = [
        {
            value: 'profile',
            icon: <Icon icon={roundAccountBox} width={20} height={20} />,
            component: <Profile myProfile={lecturer} uid={uid} fetch={get} type={TYPE} />
        }
    ];

    return (
        <Page title={title}>
            {lecturer.fname && !isLoading && !error ?
                (<Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading="My Profile"
                        links={[
                            { name: 'Dashboard', href: PATH_DASHBOARD.root },
                            { name: `My Profile` }
                        ]}
                    />
                    <Card
                        sx={{
                            mb: 3,
                            height: 280,
                            position: 'relative'
                        }}
                    >
                        <Cover myProfile={lecturer} uid={uid} type={TYPE} />

                        <TabsWrapperStyle>
                            <Tabs
                                value={currentTab}
                                scrollButtons="auto"
                                variant="scrollable"
                                allowScrollButtonsMobile
                                onChange={handleChangeTab}
                            >
                                {PROFILE_TABS.map((tab) => (
                                    <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
                                ))}
                            </Tabs>
                        </TabsWrapperStyle>
                    </Card>

                    {PROFILE_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}
                </Container>)

                : iff(isLoading, <CircularIndeterminate />, <ShowError message={error} tryAgain />)}
        </Page>
    );
}
