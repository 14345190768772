import PropTypes from "prop-types";
// hooks
import useAuth from "../hooks/useAuth";
import DomainError from "../pages/errors/DomainError";
import NotFound from "../pages/errors/NotFound";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};
export default function GuestGuard({ children }) {
  const { isAuthenticated, isDomainOkay, user } = useAuth();
  const subdomain = window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : false;
  if (subdomain && subdomain === "app") {
    if (isAuthenticated && !isDomainOkay) {
      return <DomainError />;
    }
    if (isAuthenticated && isDomainOkay && user.user.fname) {
      window.location.href = `http://${user.school.domain}.onechanzo.com`;
    }
  } else {
    return <NotFound />;
  }
  return children;
}
