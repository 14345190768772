import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Grid, Card, Button, Typography } from '@mui/material';
import { MAvatar } from '../../@material-extend';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { isNumeric, fNumber } from '../../../utils/formatNumber';
import { ShowError } from '../../../utils/loader';
import createAvatar from '../../../utils/createAvatar';

// ----------------------------------------------------------------------

SingleCard.propTypes = {
    department: PropTypes.object
};

function SingleCard({ department }) {
    const { name, courses, uid } = department;
    const src = createAvatar(name);
    return (
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
            <MAvatar
                src=''
                sx={{ width: 48, height: 48 }}
                alt={name}
                color={src.color}
            >
                {src.name}
            </MAvatar>
            <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
                <Typography variant="subtitle2" noWrap>
                    {name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {isNumeric(courses) ? fNumber(courses) : '0'} course(s)
                    </Typography>
                </Box>
            </Box>
            <Button
                size="small"
                variant='outlined'
                color='primary'
                component={RouterLink}
                to={`${PATH_DASHBOARD.subpages.department}/${uid}/${paramCase(name)}`}
            >
                View
            </Button>
        </Card>
    );
}

FacultyDepartments.propTypes = {
    departments: PropTypes.array.isRequired
};

export default function FacultyDepartments({ departments }) {
    return (
        <Box sx={{ mt: 5 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                Departments
            </Typography>
            {departments.length > 0 ?
                (<Grid container spacing={3}>
                    {departments.map((department) => (
                        <Grid key={department.uid} item xs={12} md={4}>
                            <SingleCard department={department} />
                        </Grid>
                    ))}
                </Grid>)
                : <ShowError isNotShowImage message={new Error('This faculty does not have any department at the moment!')} />
            }
        </Box>
    );
}
