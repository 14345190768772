import {
  format,
  getTime,
  formatDistanceToNow,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { isNumeric } from "./formatNumber";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd MMMM yyyy");
}
export function fWeekRange(date) {
  const weekStart = startOfWeek(new Date(date.replace(/-/g, "/")));
  const weekEnd = endOfWeek(new Date(date.replace(/-/g, "/")));
  return `${format(weekStart, "dd MMMM yyyy")} - ${format(
    weekEnd,
    "dd MMMM yyyy"
  )}`;
}
export function fDateMonth(date) {
  return format(new Date(date.replace(/-/g, "/")), "MMMM yyyy");
}

export function fTime(date) {
  return format(new Date(date.replace(/-/g, "/")), "hh:mm aa");
}

export function fDateTimeStamp(time) {
  return isNumeric(String(time))
    ? format(time, "hh:mm aa")
    : format(new Date(time.replace(/-/g, "/")), "hh:mm aa");
}

export function fDateTime(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd MMM yyyy hh:mm aa");
}

export function fTimestamp(date) {
  return getTime(new Date(date.replace(/-/g, "/")));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date.replace(/-/g, "/")), "dd/MM/yyyy hh:mm aa");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date.replace(/-/g, "/")), {
    addSuffix: true,
  });
}
export function fTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
