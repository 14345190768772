import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  messages: [],
  filters: {
    type: "",
    status: "",
    search: "",
  },
};

const slice = createSlice({
  name: "notice_board",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeDeleteError(state) {
      state.deleteError = false;
    },
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.messages = action.payload.data;
      state.total = action.payload.total;
    },
    deletedSuccess(state, action) {
      const deleted = action.payload.split(",");
      state.messages = state.messages.filter(
        (obj) => !deleted.includes(obj.nid)
      );
      state.total -= deleted.length;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

export function getMessages(search, level, type, stat, sortBy, page, perpage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.noticeBoard, {
        search,
        level,
        status: stat,
        type,
        sortBy,
        page,
        perpage,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your board messages at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteMessage(remove) {
  return async (dispatch) => {
    dispatch(slice.actions.removeDeleteError());
    try {
      const response = await axios.post(API_PATH.noticeBoard, { remove });
      if (response.data.status === "success") {
        dispatch(slice.actions.deletedSuccess(remove));
      } else if (response.data.status === "permission_denied") {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("You do not have permission to perform this action!")
          )
        );
      } else {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("An error has occured! Please try again later.")
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
