import PropTypes from "prop-types";
// material
import { Grid, Stack } from "@mui/material";
//
import RoleAbout from "./RoleAbout";
import RoleUpdate from "./RoleUpdate";

import FacultyAbout from "./FacultyAbout";
import FacultyUpdate from "./FacultyUpdate";

import DepartmentAbout from "./DepartmentAbout";
import DepartmentUpdate from "./DepartmentUpdate";

import CourseAbout from "./CourseAbout";
import CourseUpdate from "./CourseUpdate";
import CourseLectures from "./CourseLectures";

import LectureAbout from "./LectureAbout";
import LectureUpdate from "./LectureUpdate";
import LectureMaterialsUpload from "./LectureMaterialsUpload";
import LectureMaterials from "./LectureMaterials";
import LectureTakeProceed from "./LectureTakeProceed";

import TestAbout from "./TestAbout";
import TestUpdate from "./TestUpdate";
import TestTakeProceed from "./TestTakeProceed";

import AssignmentAbout from "./AssignmentAbout";
import AssignmentUpdate from "./AssignmentUpdate";
import AssignmentTakeProceed from "./AssignmentTakeProceed";

import DeleteData from "./DeleteData";

import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

SingleData.propTypes = {
  myProfile: PropTypes.object,
  rid: PropTypes.string,
  type: PropTypes.string,
  fetch: PropTypes.func,
};

export default function SingleData({ myProfile, rid, fetch, type }) {
  const { usertype } = useAuth();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {type === "role" && (
            <RoleAbout profile={myProfile} rid={rid} fetch={fetch} />
          )}
          {type === "faculty" && (
            <FacultyAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "department" && (
            <DepartmentAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "course" && (
            <CourseAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "lecture" && (
            <LectureAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "test" && (
            <TestAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "assignment" && (
            <AssignmentAbout profile={myProfile} uid={rid} fetch={fetch} />
          )}
          {usertype !== 0 && <DeleteData uid={rid} type={type} />}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {type === "role" && (
            <RoleUpdate myProfile={myProfile} rid={rid} fetch={fetch} />
          )}
          {type === "faculty" && (
            <FacultyUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "department" && (
            <DepartmentUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "course" && usertype !== 0 && (
            <CourseUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "course" && usertype === 0 && (
            <CourseLectures lectures={myProfile.lectures || []} />
          )}
          {type === "lecture" && usertype !== 0 && (
            <LectureUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "lecture" &&
            usertype === 0 &&
            parseInt(myProfile.type, 10) === 0 && (
              <LectureMaterials
                materials={
                  (myProfile.materials && JSON.parse(myProfile.materials)) || []
                }
              />
            )}
          {type === "lecture" &&
            usertype === 0 &&
            parseInt(myProfile.type, 10) === 1 && (
              <LectureTakeProceed
                myProfile={myProfile}
                uid={rid}
                fetch={fetch}
              />
            )}
          {type === "lecture" &&
            usertype !== 0 &&
            parseInt(myProfile.type, 10) === 0 && (
              <LectureMaterialsUpload
                myProfile={myProfile}
                uid={rid}
                fetch={fetch}
              />
            )}
          {type === "test" && usertype !== 0 && (
            <TestUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "assignment" && usertype !== 0 && (
            <AssignmentUpdate myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
          {type === "assignment" && usertype === 0 && (
            <AssignmentTakeProceed
              myProfile={myProfile}
              uid={rid}
              fetch={fetch}
            />
          )}
          {type === "test" && usertype === 0 && (
            <TestTakeProceed myProfile={myProfile} uid={rid} fetch={fetch} />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
