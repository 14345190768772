import { useState, useEffect, useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// hooks
import useAuth from "../hooks/useAuth";
import useSettings from "../hooks/useSettings";
// pages
import Welcome from "../pages/auth/Welcome";
import DomainError from "../pages/errors/DomainError";
import LoadingScreen from "../components/LoadingScreen";
import { frontendPages } from "../routes/paths";
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isDomainOkay, isInitialized, user, isVerifyEmail } =
    useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { changeColor, themeColor } = useSettings();

  const checkColor = useCallback(() => {
    if (isInitialized && isDomainOkay && user) {
      // set color if not set
      const schoolColor =
        user && user.school.primary_color
          ? user.school.primary_color
          : "default";
      if (schoolColor !== themeColor) {
        changeColor(schoolColor);
      }
    }
  }, [changeColor, isDomainOkay, isInitialized, themeColor, user]);

  useEffect(() => {
    checkColor();
  }, [checkColor]);

  if (isInitialized && user) {
    // verify school subdomain
    if (!isDomainOkay) {
      return <DomainError />;
    }
    if (
      !isAuthenticated &&
      (pathname === "/" || !frontendPages.includes(pathname))
    ) {
      return <Welcome />;
    }

    if (isVerifyEmail && user.user.fname && pathname !== "/set-password") {
      // go to set password
      return <Navigate to="/set-password" />;
    }

    if (pathname === "/set-password" && !user.user.fname) {
      return <Navigate to="/welcome" />;
    }

    if (
      isAuthenticated &&
      (frontendPages.includes(pathname) || pathname === "/set-password")
    ) {
      const userType = parseInt(window.localStorage.getItem("userType"), 10);
      if (userType === 0 || userType === 1 || userType === 2) {
        return <Navigate to="/" />;
      }
      return <DomainError />;
    }

    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
  } else {
    return <LoadingScreen />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return children;
}
