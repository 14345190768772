import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  mail: {},
  mails: [],
  labels: [],
  total: 0,
};

const slice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    mailMarkedSuccess(state, action) {
      state.isLoading = false;
      state.mails = state.mails.map((mail) => {
        mail.isUnread = action.payload.split(",").includes(mail.id)
          ? true
          : mail.isUnread;
        return mail;
      });
    },
    mailTrashSuccess(state, action) {
      state.isLoading = false;
      const trashed = action.payload.split(",");
      state.mails = state.mails.filter((mail) => !trashed.includes(mail.id));
    },
    // GET MAILS
    getMailsSuccess(state, action) {
      state.isLoading = false;
      state.mails = action.payload.data;
      state.total = action.payload.total;
      state.labels = action.payload.labels;
    },

    // GET MAIL
    getMailSuccess(state, action) {
      state.isLoading = false;
      state.mail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMails(search, current, page, perpage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.mailbox, {
        search,
        current,
        page,
        perpage,
      });
      const { status, data, total, labels } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getMailsSuccess({ data, total, labels }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your mails at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markRead(mark, current) {
  return async (dispatch) => {
    const response = await axios.post(API_PATH.mailbox, { mark, current });
    const { status } = response.data;
    if (status === "success") {
      dispatch(slice.actions.mailMarkedSuccess(mark));
    }
  };
}

export function markTrash(trash, current) {
  return async (dispatch) => {
    const response = await axios.post(API_PATH.mailbox, { trash, current });
    const { status } = response.data;
    if (status === "success") {
      dispatch(slice.actions.mailTrashSuccess(trash));
    }
  };
}
// ----------------------------------------------------------------------

export function getMail(single, current) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.mailbox, { single, current });
      const { status, data } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getMailSuccess(data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function downloadAttachments(
  download,
  current,
  encoding,
  filename,
  message
) {
  return async (dispatch) => {
    try {
      const response = await axios.post(API_PATH.mailbox, {
        download,
        current,
        encoding,
        filename,
        message,
      });
      const { status, link } = response.data;
      if (status === "success") {
        window.open(link);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
