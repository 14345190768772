import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

// ----------------------------------------------------------------------
CourseUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
const UNITS = ["1.0", "2.0", "3.0", "4.0", "5.0"];
export default function CourseUpdate({ myProfile, uid, fetch }) {
  const { updateCourse, getLecturers, getDepartments, user, usertype } =
    useAuth();
  const permissions =
    user.user && usertype === 2 && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_course");
  const isMountedRef = useIsMountedRef();
  const [DEPARTMENTS, setDEPARTMENTS] = useState([]);
  const [LECTURERS, setLECTURERS] = useState([]);
  const { programmes } = user.school;
  const { enqueueSnackbar } = useSnackbar();

  const SEMESTERS = [
    { label: "1st", value: "1" },
    { label: "2nd", value: "2" },
  ];
  const LEVELS = ["100", "200", "300", "400", "500", "600"];
  if (programmes.split(",").includes("masters")) {
    LEVELS.push("Masters");
  }
  if (programmes.split(",").includes("doctoral")) {
    LEVELS.push("Doctoral");
  }

  useEffect(() => {
    async function get() {
      setDEPARTMENTS(await getDepartments());
      setLECTURERS(await getLecturers());
    }
    get();
  }, [getDepartments, getLecturers]);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    lecturer: Yup.object().required("Lecturer is required"),
    department: Yup.object().required("Department is required"),
    level: Yup.string().required("Class / level is required"),
    unit: Yup.string().required("Course unit is required!"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    code: Yup.string().required("Course code is required!"),
    semester: Yup.mixed().required("Semester is required!"),
  });

  const formik = useFormik({
    initialValues: {
      name: myProfile.name,
      lecturer: myProfile.lecturerData,
      email: myProfile.email,
      unit: myProfile.unit,
      department: myProfile.departmentData,
      level: myProfile.level,
      location: myProfile.location,
      description: myProfile.description,
      code: myProfile.code,
      semester: myProfile.semester
        ? SEMESTERS.filter(
            (semester) =>
              parseInt(semester.value, 10) === parseInt(myProfile.semester, 10)
          )[0]
        : {},
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateCourse(values, uid);
        enqueueSnackbar("Course's data has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Course Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}

              <TextField
                fullWidth
                autoComplete="username"
                label="Name"
                disabled={!hasPermission}
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  disabled={!hasPermission}
                  label="Course Code"
                  {...getFieldProps("code")}
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                />
                <Autocomplete
                  fullWidth
                  options={SEMESTERS}
                  disabled={!hasPermission}
                  {...getFieldProps("semester")}
                  name="semester"
                  value={values.semester}
                  onChange={(event, newValue) => {
                    setFieldValue("semester", newValue || "");
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Semester"
                      margin="none"
                      error={Boolean(touched.semester && errors.semester)}
                      helperText={touched.semester && errors.semester}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="email"
                  disabled={!hasPermission}
                  label="Contact Email Address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  label="Location"
                  disabled={!hasPermission}
                  {...getFieldProps("location")}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  fullWidth
                  options={DEPARTMENTS}
                  disabled={!hasPermission}
                  {...getFieldProps("department")}
                  name="department"
                  value={values.department}
                  onChange={(event, newValue) => {
                    setFieldValue("department", newValue);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      margin="none"
                      error={Boolean(touched.department && errors.department)}
                      helperText={touched.department && errors.department}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={LEVELS}
                  disabled={!hasPermission}
                  {...getFieldProps("level")}
                  name="level"
                  value={values.level}
                  onChange={(event, newValue) => {
                    setFieldValue("level", newValue);
                  }}
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Class / Level"
                      margin="none"
                      error={Boolean(touched.level && errors.level)}
                      helperText={touched.level && errors.level}
                    />
                  )}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  select
                  disabled={!hasPermission}
                  sx={{ width: { xs: "100%", md: 500 } }}
                  label="Course Unit"
                  value={values.unit}
                  onChange={(event) => {
                    setFieldValue("unit", event.target.value);
                  }}
                  error={Boolean(touched.unit && errors.unit)}
                  helperText={touched.unit && errors.unit}
                >
                  {UNITS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  fullWidth
                  options={LECTURERS}
                  disabled={!hasPermission}
                  {...getFieldProps("lecturer")}
                  name="lecturer"
                  value={values.lecturer}
                  onChange={(event, newValue) => {
                    setFieldValue("lecturer", newValue);
                  }}
                  getOptionLabel={(option) =>
                    (option &&
                      `${option.title} ${option.fname} ${option.lname}`) ||
                    ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Lecturer"
                      margin="none"
                      error={Boolean(touched.lecturer && errors.lecturer)}
                      helperText={touched.lecturer && errors.lecturer}
                    />
                  )}
                />
              </Stack>
              <TextField
                multiline
                rows={4}
                disabled={!hasPermission}
                fullWidth
                label="Course Description (optional)"
                {...getFieldProps("description")}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
