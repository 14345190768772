import PropTypes from "prop-types";
import * as Yup from "yup";
import { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  MenuItem,
  Typography,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import { ACCESS_LEVELS, PROGRAMMES, LEVELS } from "../../../utils/apis";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

// ----------------------------------------------------------------------
PaymentUpdate.propTypes = {
  myProfile: PropTypes.object,
  uid: PropTypes.string,
  fetch: PropTypes.func,
};
export default function PaymentUpdate({ myProfile, uid, fetch }) {
  const { updatePayment, user, getFaculties, getDepartments } = useAuth();
  const { programmes } = user.school;
  const permissions =
    user.user && user.user.role.permissions !== ""
      ? user.user.role.permissions.split(",")
      : [];
  const hasPermission = permissions.includes("update_payment");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const [DATA, setDATA] = useState([]);
  const [dataName, setDataName] = useState("");
  const [actualProgrammes, setActualProgrammes] = useState(PROGRAMMES);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Payment name is required"),
    usertype: Yup.string().required("Specify who the payment is for"),
    amount: Yup.number().required("Amount is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: myProfile.name,
      description: myProfile.description,
      usertype: myProfile.usertype,
      amount: myProfile.amount,
      data: [],
      valid_from: myProfile.validFrom,
      valid_to: myProfile.validTo,
      level: myProfile.level,
      programme: {},
      isIndigene: myProfile.isIndigene,
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updatePayment(values, uid);
        enqueueSnackbar("Payment's details has been updated!", {
          variant: "success",
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        fetch();
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;
  const fetchData = useCallback(() => {
    const fetch = async () => {
      try {
        const data = values.usertype ? parseInt(values.usertype, 10) : 0;
        setFieldValue("data", []);
        setDataName("");
        setDATA([]);
        if (data !== 0) {
          let datas;
          if (data === 1) {
            setDataName("Faculties");
            // fetch faculties
            datas = await getFaculties();
          }
          if (data === 2) {
            setDataName("Departments");
            // fetch departments
            datas = await getDepartments();
          }
          setDATA(datas);
          setFieldValue(
            "data",
            (myProfile.data &&
              datas.filter((dat) =>
                JSON.parse(myProfile.data).includes(dat.uid)
              )) ||
              []
          );
        }
      } catch (err) {
        enqueueSnackbar(
          "An error occured while trying to fetch data! Please try again later.",
          { variant: "error" }
        );
      }
    };
    fetch();
  }, [
    values.usertype,
    getDepartments,
    getFaculties,
    enqueueSnackbar,
    setFieldValue,
    myProfile,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (programmes.split(",").includes("masters")) {
      setActualProgrammes((prev) => [
        ...prev,
        {
          label: "Master",
          category: "Postgraduate",
          value: "master",
          hasLevel: false,
        },
      ]);
    }
    if (programmes.split(",").includes("doctoral")) {
      setActualProgrammes((prev) => [
        ...prev,
        {
          label: "Doctorate",
          category: "Postgraduate",
          value: "doctorate",
          hasLevel: false,
        },
      ]);
    }
    const programmeInfo = PROGRAMMES.filter(
      (obj) => obj.value === myProfile.programme
    );
    setFieldValue(
      "programme",
      programmeInfo.length > 0 ? programmeInfo[0] : {}
    );
    return () => {
      setActualProgrammes(PROGRAMMES);
    };
  }, [programmes, myProfile.programme, setFieldValue]);

  return (
    <Card sx={{ px: 3, pb: 3 }}>
      <CardHeader title="Payment Information" />
      <Box sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}

              <TextField
                fullWidth
                label="Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                disabled={!hasPermission}
              />
              <TextField
                type="number"
                fullWidth
                label="Amount"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
                disabled={!hasPermission}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>&#8358;</Typography>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                multiline
                label="Briefly describe this payment."
                {...getFieldProps("description")}
                disabled={!hasPermission}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
              <TextField
                select
                fullWidth
                label="Who is this payment for?"
                className="fix-mui-padding"
                disabled={!hasPermission}
                value={values.usertype}
                onChange={(event) => {
                  setFieldValue("usertype", event.target.value);
                }}
                error={Boolean(touched.usertype && errors.usertype)}
                helperText={touched.usertype && errors.usertype}
              >
                {ACCESS_LEVELS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {(values.usertype === "1" || values.usertype === "2") && (
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    multiple
                    filterSelectedOptions
                    options={DATA}
                    {...getFieldProps("data")}
                    name="data"
                    value={values.data}
                    disabled={!hasPermission}
                    isOptionEqualToValue={(option, value) =>
                      option.uid === value
                    }
                    onChange={(event, newValue) => {
                      setFieldValue("data", newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Select ${dataName}:`}
                        margin="none"
                        error={Boolean(touched.data && errors.data)}
                        helperText={`You can leave this field blank to select all ${dataName}`}
                      />
                    )}
                  />
                </Stack>
              )}
              <Autocomplete
                fullWidth
                disabled={!hasPermission}
                options={actualProgrammes}
                {...getFieldProps("programme")}
                name="programme"
                value={values.programme}
                onChange={(event, newValue) => {
                  setFieldValue("programme", newValue);
                  if (!newValue.hasLevel) {
                    setFieldValue("level", "");
                  }
                }}
                groupBy={(option) => option.category}
                getOptionLabel={(option) => option.label || ""}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Programme"
                    margin="none"
                    error={Boolean(touched.programme && errors.programme)}
                    helperText={touched.programme && errors.programme}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                options={LEVELS}
                {...getFieldProps("level")}
                name="level"
                disabled={values.programme ? !values.programme.hasLevel : false}
                value={values.level}
                onChange={(event, newValue) => {
                  setFieldValue("level", newValue);
                }}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Class / Level"
                    margin="none"
                    error={Boolean(touched.level && errors.level)}
                    helperText={touched.level && errors.level}
                  />
                )}
              />
              <TextField
                select
                fullWidth
                label="Student's Indigeneous Status"
                className="fix-mui-padding"
                value={values.isIndigene}
                disabled={!hasPermission}
                onChange={(event) => {
                  setFieldValue("isIndigene", event.target.value);
                }}
                error={Boolean(touched.isIndigene && errors.isIndigene)}
                helperText="Specify whether this payment is for indigenous or non-indigenous students."
              >
                {[
                  { label: "All", value: "" },
                  { label: "Indigenes", value: "1" },
                  { label: "Non-indigenes", value: "0" },
                ].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Payment Valid from"
                  {...getFieldProps("valid_from")}
                  error={Boolean(touched.valid_from && errors.valid_from)}
                  helperText={touched.valid_from && errors.valid_from}
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!hasPermission}
                  type="date"
                  fullWidth
                  label="Payment Valid To"
                  {...getFieldProps("valid_to")}
                  error={Boolean(touched.valid_to && errors.valid_to)}
                  helperText={touched.valid_to && errors.valid_to}
                />
              </Stack>
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Update
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
